import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import {
  useGetTutorExtendedQuery,
  useUpdateTutorResumeMutation,
} from '../../../../app/services/tutor.api';

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: '#FFFFFF',
    padding: '2rem',
    borderRadius: 5,
    marginTop: '3rem',
  },
  name: {
    padding: 0,
  },
  input1: {
    height: '0.3rem',
  },
  input2: {
    height: 30,
    fontSize: '3em',
  },
  headings: {
    color: '#6F757B',
  },
  headings1: {
    color: '#6F757B',
    marginTop: '2rem',
    marginBottom: '2rem',
  },
  formControl: {
    width: '100%',
    margin: theme.spacing(3),
  },
  button: {
    marginTop: theme.spacing(5),
    backgroundColor: '#FFB600',
    color: 'white',
  },
  progress: {
    color: '#FFFFFF',
  },
  root: {
    display: 'flex',
  },
  radio: {
    '&$checked': {
      color: '#ffcd02',
    },
  },
  checked: {},
  buttonBack: {
    marginTop: theme.spacing(5),
    marginRight: theme.spacing(5),
    backgroundColor: '#F8F8F8',
    color: '#FFB600',
    borderColor: '#FFB600',
    borderWidth: 1,
    borderStyle: 'solid',
  },
}));

export default function About(props) {
  const classes = useStyles();
  const [error, setError] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const [updateResume] = useUpdateTutorResumeMutation();

  const [experience, setExperience] = React.useState(null);
  const { t } = useTranslation([
    'common/ages',
    'common/languages_level',
    'page/sign_up',
  ]);
  const [education, setEducation] = React.useState({
    startDate: '',
    endDate: '',
    instituteName: '',
    degreeTitle: '',
  });
  const [certificate, setCertificate] = React.useState([
    {
      startDate: '',
      endDate: '',
      instituteName: '',
      certificationTitle: '',
    },
  ]);
  const tutorQuery = useGetTutorExtendedQuery(props.tutorId);

  useEffect(() => {
    const { certifications, education } = tutorQuery?.data?.data || {};

    if (certifications?.length) {
      const filteredCertifications = certifications.map(cert => ({
        startDate: cert.startDate || '',
        endDate: cert.endDate || '',
        instituteName: cert.instituteName || '',
        certificationTitle: cert.certificationTitle || '',
      }));

      setCertificate(filteredCertifications);
    }

    if (education) {
      setEducation({
        startDate: education.startDate || '',
        endDate: education.endDate || '',
        instituteName: education.instituteName || '',
        degreeTitle: education.degreeTitle || '',
      });
    }
  }, [tutorQuery.data]);

  const resumePost = async () => {
    setLoading(true);
    setError(false);
    const dataObj = {
      experience: experience,
      education: education,
      certifications: certificate,
      preferredStudentAge: [],
      preferredStudentLevel: [],
    };

    try {
      await updateResume(dataObj).unwrap();
      props.handleNext();
    } catch (e) {
      setError(e?.data?.error || true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ margin: '1rem' }}>
      <Container maxWidth="md" className={classes.container} boxShadow={3}>
        <Typography className={classes.headings} variant="h6" gutterBottom>
          {t('resume_component.title', { ns: 'page/sign_up' })}
        </Typography>
        {error ? (
          <Grid container alignItems="left">
            <p style={{ fontSize: 13, color: 'red' }}>
              {t('resume_component.failed_save', { ns: 'page/sign_up' })}{' '}
              {error}
            </p>
          </Grid>
        ) : null}
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography
              variant="subtitle2"
              align="left"
              className={classes.headings}
            >
              {t('resume_component.education', { ns: 'page/sign_up' })}
            </Typography>

            <Grid container spacing={3}>
              <Grid item md={3} lg={3} xl={3} sm={6} xs={6}>
                <TextField
                  style={{ width: '100%' }}
                  id="outlined-read-only-input"
                  placeholder={t('resume_component.from_placeholder', {
                    ns: 'page/sign_up',
                  })}
                  type="date"
                  onChange={(e) =>
                    setEducation({ ...education, startDate: e.target.value })
                  }
                  InputProps={{ classes: { input: classes.input1 } }}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={3} lg={3} xl={3} sm={3} xs={6}>
                <TextField
                  style={{ width: '100%' }}
                  id="outlined-read-only-input"
                  placeholder={t('resume_component.to_placeholder', {
                    ns: 'page/sign_up',
                  })}
                  type="date"
                  onChange={(e) =>
                    setEducation({ ...education, endDate: e.target.value })
                  }
                  InputProps={{ classes: { input: classes.input1 } }}
                  variant="outlined"
                />
              </Grid>

              <Grid item md={3} lg={3} xl={3} sm={6} xs={6}>
                <TextField
                  style={{ width: '100%' }}
                  id="outlined-read-only-input"
                  placeholder={t(
                    'resume_component.institute_name_placeholder',
                    { ns: 'page/sign_up' }
                  )}
                  onChange={(e) =>
                    setEducation({
                      ...education,
                      instituteName: e.target.value,
                    })
                  }
                  InputProps={{ classes: { input: classes.input1 } }}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={3} lg={3} xl={3} sm={3} xs={6}>
                <TextField
                  style={{ width: '100%' }}
                  id="outlined-read-only-input"
                  placeholder={t('resume_component.degree_title_placeholder', {
                    ns: 'page/sign_up',
                  })}
                  onChange={(e) =>
                    setEducation({ ...education, degreeTitle: e.target.value })
                  }
                  InputProps={{ classes: { input: classes.input1 } }}
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Typography
              variant="subtitle2"
              align="left"
              className={classes.headings}
            >
              {t('resume_component.certification', { ns: 'page/sign_up' })}
            </Typography>
            {certificate.map((data, index) => (
              <>
                <Grid container spacing={3}>
                  <Grid item md={3} lg={3} xl={3} sm={6} xs={6}>
                    <TextField
                      style={{ width: '100%' }}
                      id="outlined-read-only-input"
                      placeholder={t('resume_component.from_placeholder', {
                        ns: 'page/sign_up',
                      })}
                      type="date"
                      onChange={(e) => {
                        if (e.target.value != null) {
                          setCertificate(certs => {
                            const updatedCerts = [...certs];
                            updatedCerts[index] = {
                              ...updatedCerts[index],
                              startDate: e.target.value
                            };
                            return updatedCerts;
                          });
                        }
                      }}
                      InputProps={{ classes: { input: classes.input1 } }}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={3} lg={3} xl={3} sm={3} xs={6}>
                    <TextField
                      style={{ width: '100%' }}
                      id="outlined-read-only-input"
                      placeholder={t('resume_component.to_placeholder', {
                        ns: 'page/sign_up',
                      })}
                      type="date"
                      onChange={(e) => {
                        if (e.target.value != null) {
                          setCertificate(certs => {
                            const updatedCerts = [...certs];
                            updatedCerts[index] = {
                              ...updatedCerts[index],
                              endDate: e.target.value
                            };
                            return updatedCerts;
                          });
                        }
                      }}
                      InputProps={{ classes: { input: classes.input1 } }}
                      variant="outlined"
                    />
                  </Grid>

                  <Grid item md={3} lg={3} xl={3} sm={6} xs={6}>
                    <TextField
                      style={{ width: '100%' }}
                      id="outlined-read-only-input"
                      placeholder={t(
                        'resume_component.platform_name_placeholder',
                        { ns: 'page/sign_up' }
                      )}
                      onChange={(e) => {
                        const newValue = e.target.value;
                        if (newValue != null) {
                          setCertificate(certs => {
                            const updatedCerts = [...certs];
                            updatedCerts[index] = {
                              ...updatedCerts[index],
                              instituteName: newValue
                            };
                            return updatedCerts;
                          });
                        }
                      }}
                      InputProps={{ classes: { input: classes.input1 } }}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={3} lg={3} xl={3} sm={3} xs={6}>
                    <TextField
                      style={{ width: '100%' }}
                      id="outlined-read-only-input"
                      placeholder={t(
                        'resume_component.certificate_title_placeholder',
                        { ns: 'page/sign_up' }
                      )}
                      onChange={(e) => {
                        const newValue = e.target.value;
                        if (newValue != null) {
                          setCertificate(certs => {
                            const updatedCerts = [...certs];
                            updatedCerts[index] = {
                              ...updatedCerts[index],
                              certificationTitle: newValue
                            };
                            return updatedCerts;
                          });
                        }
                      }}
                      InputProps={{ classes: { input: classes.input1 } }}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
              </>
            ))}
          </Grid>
          <Grid item xs={12} style={{ margin: 0, padding: 0, display: 'flex' }}>
            <Typography
              align="left"
              variant="caption"
              style={{
                color: '#FFB600',
                marginLeft: '1rem',
                cursor: 'pointer',
              }}
              onClick={() => {
                setCertificate([
                  ...certificate,
                  {
                    startDate: '',
                    endDate: '',
                    instituteName: '',
                    certificationTitle: '',
                  },
                ]);
              }}
            >
              {t('resume_component.add_certificate', { ns: 'page/sign_up' })}
            </Typography>
          </Grid>

          <Grid item xs={6} sm={3}>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Grid item xs={10}>
                <Typography
                  variant="subtitle2"
                  align="left"
                  color="red"
                  className={classes.headings}
                >
                  {t('resume_component.experience_years', {
                    ns: 'page/sign_up',
                  })}
                </Typography>
                <TextField
                  align="left"
                  type="number"
                  id="outlined-read-only-input"
                  value={experience}
                  onChange={(e) => {
                    if (e.target.value >= 0) {
                      setExperience(e.target.value);
                    } else {
                      setExperience(0);
                    }
                  }}
                  placeholder={0}
                  InputProps={{
                    classes: { input: classes.input1 },
                  }}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={2} style={{ marginTop: '1rem' }}>
                <Typography
                  variant="subtitle2"
                  color="red"
                  className={classes.headings}
                ></Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Box display="flex" justifyContent="flex-start">
          <Button
            variant="contained"
            className={classes.buttonBack}
            onClick={() => props.handleBack()}
          >
            {t('back', { ns: 'page/sign_up' })}
          </Button>

          <Button
            variant="contained"
            className={classes.button}
            onClick={resumePost}
          >
            {loading ? (
              <CircularProgress size={25} className={classes.progress}/>
            ) : (
              t('next', {ns: 'page/sign_up'})
            )}
          </Button>
        </Box>
      </Container>
    </div>
  );
}
