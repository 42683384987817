import api from './base';

const authApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getMe: builder.query({
      query: () => ({
        url: 'users/profile/',
      }),
      keepUnusedDataFor: 0,
      providesTags: ['User'],
    }),

    logIn: builder.mutation({
      query: (body) => ({
        url: 'auth/login/',
        method: 'POST',
        body,
      }),
    }),

    createTutor: builder.mutation({
      query: (body) => ({
        url: 'auth/tutor-registration/',
        method: 'POST',
        body,
      }),
    }),

    createStudent: builder.mutation({
      query: (body) => ({
        url: 'auth/student-registration/',
        method: 'POST',
        body,
      }),
    }),

    confirmEmail: builder.mutation({
      query: (body) => ({
        url: 'auth/confirm-email/',
        method: 'POST',
        body,
      }),
    }),

    forgotPassword: builder.mutation({
      query: (body) => ({
        url: `auth/forgot-password/`,
        method: 'POST',
        body,
      }),
    }),

    resetPassword: builder.mutation({
      query: (body) => ({
        url: `auth/reset-password/`,
        method: 'POST',
        body,
      }),
    }),

    deleteUser: builder.mutation({
      query: (body) => ({
        url: 'auth/deleteaccount',
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const {
  useGetMeQuery,
  useLogInMutation,
  useCreateTutorMutation,
  useCreateStudentMutation,
  useConfirmEmailMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation,
  useDeleteUserMutation,
} = authApi;
