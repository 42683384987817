import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import io from 'socket.io-client';
import { Box, Grid, Typography } from '@material-ui/core';
import { useChatStyles } from './styles/chat.style';
import Footer from '../common/components/Footer';
import Nav from '../common/components/Headers/topNavStudent';
import SubHeaderTutor from '../common/components/Headers/SubHeaderStudent';
import SendInput from '../common/components/SendInput/SendInput';
import baseurl from '../../baseurl';
import { selectUser, selectAccessToken } from '../../app/slices/auth.slice';
import { useGetChatsQuery, useGetChatMessagesQuery } from '../../app/services/chat.api';
import Interaction from '../../assets/images/interaction.png';
import BlankProfile from '../../assets/images/blankProfile.png';
import YsLogo from '../../assets/images/logo/BusLogo.png';

export default function Chats() {
  const location = useLocation();
  const classes = useChatStyles();
  const accessToken = useSelector(selectAccessToken);
  const authUser = useSelector(selectUser);
  const socketRef = useRef(null);
  const scrollableDivRef = useRef(null);
  const isConnected = useRef(false);
  const { t } = useTranslation('page/student_my_messages');

  const [writeMessage, setWriteMessage] = useState({
    whoWritingMessage: '',
    isWritingMessage: false
  });
  const [chatInfo, setChatInfo] = useState({
    id: '',
    photo: '',
    name: '',
  });
  const [chats, setChats] = useState([]);
  const [messages, setMessages] = useState([]);

  const {
    data: chatsData,
    refetch: chatsRefetch
  } = useGetChatsQuery({ skip: 1, limit: 100 }, { refetchOnMountOrArgChange: true });

  const {
    data: messagesData, 
    refetch: messagesRefetch
  } = useGetChatMessagesQuery({chatId: chatInfo.id, params: { skip: 1, limit: 500 }}, { refetchOnMountOrArgChange: true });

  useEffect(() => {
    if (accessToken && !socketRef.current && !isConnected.current) {
      socketRef.current = io(baseurl, {
        extraHeaders: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      socketRef.current.on('connect', () => {
        isConnected.current = true;
      });

      socketRef.current.on('disconnect', () => {
        isConnected.current = false;
      });

      socketRef.current.on('messageToClient', (data) => {
        chatsRefetch();
        setMessages(prev => [...prev, data.message]);
      });

      socketRef.current.on('writeMessageToClient', (data) => {
        if(authUser.userId !== data._id) {
          setWriteMessage({whoWritingMessage: data.name, isWritingMessage: true});

          setTimeout(() => {
            setWriteMessage({whoWritingMessage: '', isWritingMessage: false});
          }, 2000);
        }
      });

      return () => {
        if (socketRef.current) {
          socketRef.current.disconnect();
          socketRef.current = null;
          isConnected.current = false;
        }
      };
    }
  }, [accessToken]);

  useEffect(() => {
    if (chatsData?.chats) {
      setChats(chatsData.chats);
    }
  }, [chatsData]);

  useEffect(() => {
    if (messagesData?.messages) {
      setMessages( messagesData.messages);
    }
  }, [messagesData]);

  useEffect(() => {
    const current = scrollableDivRef.current;
    if (current) {
      current.scrollTop = current.scrollHeight;
    }
  }, [messages]);

  useEffect(() => {
    if (location.state?.state) {
      const chat = location.state.state;
      const chatPhoto = determineChatPhoto(chat.participants, chat.isGroupChat, authUser.userId);
      const chatName = determineChatName(chat.participants, chat.isGroupChat, authUser.userId);

      joinChat(chat._id, chatPhoto, chatName);
    }
  }, [location]);

  const joinChat = (chatId, chatPhoto, chatName) => {
    leaveChat();

    setMessages([]);
    setChatInfo({id: chatId, photo: chatPhoto, name: chatName});

    if (socketRef.current && chatId) {
      socketRef.current.emit('joinChat', { chatId });
    }
  };

  const leaveChat = () => {
    if (socketRef.current && chatInfo?.id) {
      socketRef.current.emit('leaveChat', { chatId: chatInfo.id });
    }
  };

  const sendMessage = (content) => {
    if (socketRef.current && chatInfo?.id && content) {
      socketRef.current.emit('messageToServer', { chatId: chatInfo.id, content, type: 'private' });
    }
  };

  const handleInputChange = () => {
    if (socketRef.current && chatInfo?.id) {
      socketRef.current.emit('writeMessageToServer', {chatId: chatInfo.id});
    }
  };

  const findOtherParticipant = (chatParticipants, authUserId) => {
    return chatParticipants.find((participant) => participant._id !== authUserId);
  };

  const determineChatPhoto = (chatParticipants, isGroupChat, authUserId) => {
    if (isGroupChat) {
      return YsLogo;
    }

    const otherParticipant = findOtherParticipant(chatParticipants, authUserId);

    return otherParticipant && otherParticipant.imageUrl ? otherParticipant.imageUrl : BlankProfile;
  };

  const determineChatName = (chatParticipants, isGroupChat, authUserId) => {
    if (isGroupChat) {
      return 'Yellow School';
    }

    const otherParticipant = findOtherParticipant(chatParticipants, authUserId);

    return otherParticipant && otherParticipant.name ? otherParticipant.name : 'No Name';
  };

  const isMyMessage = (senderId, authUserId) => {
    return senderId === authUserId;
  };

  return (
    <div>
      <Nav />
      <SubHeaderTutor title="Messages" />
      <Grid container style={{ height: 'calc(100vh - 114px)' }}>
        <Grid item xs={3} className={classes.sidePanel}>
          <Box className={classes.header}>
            <p className={classes.activeHeader}>
              {t('all')}
            </p>
          </Box>
          <Box className={classes.chatList}>
            {chats.length > 0 ? (
              chats.map((chat) => {
                const chatPhoto = determineChatPhoto(chat.participants, chat.isGroupChat, authUser.userId);
                const chatName = determineChatName(chat.participants, chat.isGroupChat, authUser.userId);

                return (
                  <Box key={chat._id} className={classes.chatItem} onClick={() => joinChat(chat._id, chatPhoto, chatName)}>
                    <img
                      className={classes.participantImage}
                      src={chatPhoto}
                      alt="participant"
                    />
                    <Box className={classes.participantDetails}>
                      <Box className={classes.nameAndDate}>
                        <p className={classes.participantName}>
                          {chatName}
                        </p>
                        {chat.lastMessage && (
                          <span className={classes.lastMessageTime}>
                            {moment(chat.lastMessage.createdAt).format('HH:mm DD/MM/YYYY')}
                          </span>
                        )}
                      </Box>
                      {chat.lastMessage && (
                        <Typography className={classes.lastMessageContent}>
                          {chat.lastMessage.content}
                        </Typography>
                      )}
                    </Box>
                  </Box>);
              })
            ) : (
              <p>{t('no_messages')}</p>
            )}
          </Box>
        </Grid>

        {chatInfo?.id ? (
          <Grid item xs={9} className={classes.messagePanel}>
            <Box className={classes.chatHeader}>
              <img src={chatInfo.photo} alt="tutor" className={classes.participantImage} />
              <p className={classes.highlightText}>{chatInfo.name}</p>
            </Box>
            <div ref={scrollableDivRef} className={classes.messageBox}>
              {messages.length > 0 ? (
                messages.map((message) => (
                  <Box
                    key={message._id}
                    className={`${classes.messageRow} ${isMyMessage(message.sender._id, authUser.userId) ? classes.myMessageRow : ''}`}
                  >
                    <Box
                      className={isMyMessage(message.sender._id, authUser.userId) ? classes.messageContainer : classes.myMessageContainer}>
                      {message.content}
                    </Box>
                  </Box>
                ))
              ) : (
                <p>{t('no_messages')}</p>
              )}
            </div>
            {writeMessage.isWritingMessage && (
              <Typography className={classes.typingIndicator}>
                {`${writeMessage.whoWritingMessage} is typing...`}
              </Typography>
            )}
            <SendInput onSend={sendMessage} onChange={handleInputChange} />
          </Grid>
        ) : (
          <Grid item xs={9} className={classes.welcomeMessage}>
            <img src={Interaction} alt="interaction" />
            <p className={classes.highlightText}>{t('once_you_select')}</p>
          </Grid>
        )}
      </Grid>
      <Footer />
    </div>
  );
}