import { callAPI } from '../services/axios';

export const getAvailabilityApi = async (data) => {
  const res = await callAPI({
    url: `${process.env.REACT_APP_GET_AVAILABILITY}`,
    method: 'get',
    data,
  });
  return res;
};
