import React, { useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import NativeSelect from '@material-ui/core/NativeSelect';
import InputBase from '@material-ui/core/InputBase';
import { Button, FormHelperText } from '@material-ui/core';
import { FormProvider, useForm } from 'react-hook-form';
import DayAvailabilityStack from './components/DayAvailabilityStack';
import { DayKeys, defaultValues } from './constants/days';
import { useTranslation } from 'react-i18next';
import { useAvailability } from '../../hooks/useAvailability';
import { useParams } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import { useGetTutorExtendedQuery } from '../../../../../app/services/tutor.api';
import { skipToken } from '@reduxjs/toolkit/query';
import { useGetScheduleQuery } from '../../../../../app/services/schedule';

const BootstrapInput = withStyles((theme) => ({
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
      color: '#6F757B',
    },
  },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  p1: {
    fontSize: 18,
    padding: 0,
    margin: 0,
    color: '#6F757B',
    textAlign: 'left',
  },
  p2: {
    fontSize: 25,
    padding: 0,
    margin: 0,
    color: '#6F757B',
    textAlign: 'left',
    marginTop: theme.spacing(6),
  },
  p3: {
    fontSize: 18,
    padding: 0,
    margin: 0,
    color: '#6F757B',
    textAlign: 'left',
    marginTop: theme.spacing(3),
  },
  p4: {
    fontSize: 18,
    padding: 0,
    margin: 0,
    textAlign: 'left',
    color: '#6F757B',
  },
  formControl: {
    width: '100%',
    color: '#6F757B',
  },
  formControl1: {
    width: '100%',
    color: '#384047',
  },
  buttonNext: {
    marginTop: theme.spacing(5),
    backgroundColor: '#FFB600',
    color: 'white',
  },
  progress: {
    color: '#FFFFFF',
  },
  buttonBack: {
    marginTop: theme.spacing(5),
    marginRight: theme.spacing(2),
    backgroundColor: '#F8F8F8',
    color: '#FFB600',
    borderColor: '#FFB600',
    borderWidth: 1,
    borderStyle: 'solid',
  },
  heading1: {
    color: '#6F757B',
    fontSize: 30,
  },
}));
let newDate = new Date();

function timeToSeconds(timeString) {
  let [hours, minutes] = timeString.split(':').map(Number);
  let seconds = hours * 3600 + minutes * 60;
  return seconds;
}

export default function SetAvaiability(props) {
  const { id: tutorId } = useParams();
  const classes = useStyles();
  const { t } = useTranslation(['page/sign_up']);

  const { data: tutor } = useGetTutorExtendedQuery(tutorId);

  const methods = useForm({ defaultValues });

  const [timeZone, setTimeZone] = React.useState(newDate.toString());

  const scheduleQuery = useGetScheduleQuery(
    tutor?.data?.schedule ? { scheduleId: tutor?.data?.schedule } : skipToken
  );

  const { handleSaveAvailability, isLoading, error } = useAvailability({
    schedule: tutor?.data?.schedule,
    tutorId,
    timeZone,
    days: Object.entries(methods.watch()),
  });

  useEffect(() => {
    if (scheduleQuery.data) {
      const { tutorId, _id, ...schedule } = scheduleQuery?.data || {};

      if (Object.entries(schedule).some(([, slots]) => !!slots.length)) {
        methods.reset(
          Object.fromEntries(
            Object.entries(schedule).map(([key, slots]) => [
              key,
              {
                isAvailable: slots.length > 0,
                slots: slots.map((slot) => ({
                  id: slot.id,
                  startTime: timeToSeconds(slot.from),
                  endTime: timeToSeconds(slot.to),
                })),
              },
            ])
          )
        );
      }
    }
  }, [scheduleQuery.data]);

  const handleNextClick = async () => {
    await handleSaveAvailability();
    props.handleNext();
  };

  return (
    <FormProvider {...methods}>
      <div>
        <p className={classes.p1}>
          {t('availability_component.correct_timezone_is_essential', {
            ns: 'page/sign_up',
          })}
        </p>

        <FormControl className={classes.formControl}>
          <NativeSelect
            value={timeZone}
            style={{ color: '#6F757B' }}
            defaultValue="saddas"
            onChange={(e) => {
              setTimeZone(e.target.value);
            }}
            input={<BootstrapInput />}
          >
            <option value={10}>{timeZone}</option>
          </NativeSelect>
        </FormControl>

        <Box mt={2} />

        <Typography
          className={classes.heading1}
          variant="subtitle1"
          align="left"
          gutterBottom
        >
          {t('availability_component.title')}
        </Typography>

        <p className={classes.p3} style={{ margin: 0 }}>
          {t('availability_component.shows_potential_working_hours', {
            ns: 'page/sign_up',
          })}
        </p>

        <p className={classes.p4}>
          {t('availability_component.students_book_lessons', {
            ns: 'page/sign_up',
          })}
        </p>

        {error && (
          <Box py={3}>
            <FormHelperText error>{JSON.stringify(error)}</FormHelperText>
          </Box>
        )}

        <Box
          mt={2}
          display="flex"
          flexDirection="column"
          style={{ gap: '22px' }}
        >
          {DayKeys.map((day) => (
            <DayAvailabilityStack key={day} dayKey={day} />
          ))}
        </Box>

        <Box display="flex" justifyContent="flex-start">
          <Button
            variant="contained"
            className={classes.buttonBack}
            onClick={() => props.handleBack()}
          >
            {t('back')}
          </Button>

          <Button
            variant="contained"
            className={classes.buttonNext}
            onClick={handleNextClick}
          >
            {isLoading ? <CircularProgress size={25} className={classes.progress}/> : t('next')}
          </Button>
        </Box>
      </div>
    </FormProvider>
  );
}
