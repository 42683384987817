import { Redirect, Route } from 'react-router-dom';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectAccessToken } from '../app/slices/auth.slice';
import { Routes } from './routes';
const PublicRoute = ({ children, ...rest }) => {
  const accessToken = useSelector(selectAccessToken);

  if (!accessToken) {
    return <Route {...rest} render={() => children} />;
  }

  return (
    <Redirect
      to={{
        pathname: Routes.Dashboard,
      }}
    />
  );
};

export default PublicRoute;
