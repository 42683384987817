import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Box from '@material-ui/core/Box';
import { Button } from '@material-ui/core';
// TODO: Remove the LiqPay logic
import { ReactComponent as LiqPay } from '../../../../../assets/icons/logo-liqpay-for-white.svg';
import { ReactComponent as Mastercard } from '../../../../../assets/icons/mastercard.svg';
import { ReactComponent as Visa } from '../../../../../assets/icons/visa.svg';

function getModalStyle() {
  return {
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  };
}

const useStyles = makeStyles((theme) => ({
  paper1: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export default function TransactionModal({ formData, closeModal, hourlyRate }) {
  const classes = useStyles();
  const { t } = useTranslation('component/transaction_modal');

  // TODO: Remove the LiqPay logic
  // const { data, signature, url, start, end } = formData || {};
  const { checkout_url, start, end } = formData || {};

  const handlePaymentLesson = () => {
    window.open(checkout_url, '_blank');
    window.location.reload();
  };

  return (
    <div>
      <Modal
        open={!!formData}
        onClose={closeModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={getModalStyle()} className={classes.paper1}>
          <h2 id="simple-modal-title">{t('book_your_lesson')}</h2>

          <h4 id="simple-modal-title">
            {t('total_price')}: ₴{hourlyRate}
          </h4>

          <p id="simple-modal-description">
            {start} - {end}
          </p>

          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'flex-start',
              margin: '3rem 0 0',
            }}
          >
            <Box flex={1}>
              <Button
                fullWidth
                variant="contained"
                style={{
                  color: 'white',
                  backgroundColor: 'rgba(245, 54, 76, 0.8)',
                }}
                onClick={closeModal}
              >
                {t('payment_form_component.cancel_btn')}
              </Button>
            </Box>

            <Box px={1} flex={1}>
              {/*TODO: Remove the LiqPay logic*/}
              {/*<form method="POST" action={url} acceptCharset="utf-8">*/}
              {/*  <input type="hidden" name="data" value={data} />*/}
              {/*  <input type="hidden" name="signature" value={signature} />*/}

              {/*  <Button*/}
              {/*    fullWidth*/}
              {/*    variant="contained"*/}
              {/*    type="submit"*/}
              {/*    style={{ color: 'white', backgroundColor: '#ffffff' }}*/}
              {/*  >*/}
              {/*    <LiqPay style={{ width: 100, height: 30 }} />*/}
              {/*  </Button>*/}
              {/*</form>*/}

              <Button
                fullWidth
                variant="contained"
                style={{ color: 'white', backgroundColor: 'rgba(64,196,29,0.8)' }}
                onClick={handlePaymentLesson}
              >
                {t('payment_form_component.pay_btn')}
              </Button>

              <Box
                display="flex"
                alignItems="center"
                justifyContent="flex-end"
                mr={-1}
                mt={3}
                height={40}
              >
                <Mastercard style={{ width: 40, marginRight: -5 }} />
                <Visa style={{ height: 40, width: 60 }} />
              </Box>
            </Box>
          </div>
        </div>
      </Modal>
    </div>
  );
}
