import api from './base';
import { getUserId } from '../utils/getters';

const studentLessonsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getStudentLessons: builder.query({
      query: (studentId) => `lessons/students/${studentId || getUserId()}/`,

      providesTags: ['StudentLessons'],
    }),

    getTutorLessons: builder.query({
      query: (tutorId) => `lessons/tutors/${tutorId || getUserId()}/`,
      providesTags: ['TutorLessons'],
    }),

    updateLessonStatus: builder.mutation({
      query: (lessonId) => ({
        url: `lessons/${lessonId}/complete/`,
        method: 'PATCH',
      }),

      invalidatesTags: ['StudentLessons', 'TutorLessons'],
    }),

    requestLesson: builder.mutation({
      query: (body) => ({
        url: 'lessons/',
        method: 'POST',
        body,
      }),

      invalidatesTags: ['StudentLessons', 'Schedule'],
    }),
  }),
});

export const {
  useGetStudentLessonsQuery,
  useGetTutorLessonsQuery,
  useUpdateLessonStatusMutation,
  useRequestLessonMutation,
} = studentLessonsApi;
