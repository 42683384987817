import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { makeStyles, Grid, Typography, Box } from '@material-ui/core';
import { ReactComponent as Mastercard } from '../../../../assets/icons/mastercard.svg';
import { ReactComponent as Visa } from '../../../../assets/icons/visa.svg';
import { HashLink } from 'react-router-hash-link';
import { Routes } from '../../../../router/routes';

const useStyles = makeStyles(() => ({
  footerContainer: {
    backgroundColor: '#040916',
    zIndex: 30,
    position: 'relative',
    padding: '48px 80px 130px',
  },

  footerBlock: {},

  footerList: {
    paddingBottom: '0.5rem',
    paddingRight: '0.9rem',
  },
  footerWrapTitle: {
    paddingBottom: '0.9rem',
  },
  footerItem: {
    display: 'flex',
    flexDirection: 'column',
  },
  footerTitle: {
    color: '#ffffff',
    fontSize: '24px',
    fontWeight: 700,
  },
  footerItemTitle: {
    color: '#d9d9de',
    fontSize: '0.9rem',
    textAlign: 'left',
    paddingBottom: '0.3rem',
  },
  footerItemTitleSmall: {
    fontSize: '0.75rem',
  },
  footerMobileLink: {
    textAlign: 'left',
    paddingBottom: '0.5rem',
  },
  footerMobileImg: {
    height: '2rem',
  },
}));

export default function Footer() {
  const classes = useStyles();
  const { t } = useTranslation('component/footer');

  return (
    <>
      <Grid
        container
        justifyContent="center"
        className={classes.footerContainer}
      >
        <Grid
          container
          item
          xs={11}
          md={10}
          justifyContent="space-between"
          className={classes.footerBlock}
        >
          <Grid container item justifyContent="space-between" xs={12} md={12}>
            <Grid item xs={12} sm={3} md={2} className={classes.footerList}>
              <Box className={classes.footerWrapTitle}>
                <Typography align="left" className={classes.footerTitle}>
                  {t('quick_links')}
                </Typography>
              </Box>

              <Box className={classes.footerItem}>
                <Link
                  to="/requestTutorHome"
                  className={classes.footerItemTitle}
                >
                  {t('find_tutor')}
                </Link>
              </Box>

              <Box className={classes.footerItem}>
                <Link
                  to={Routes.BecomeTutor}
                  className={classes.footerItemTitle}
                >
                  {t('become_tutor')}
                </Link>
              </Box>

              <Box className={classes.footerItem}>
                <HashLink
                  to="/#about-us"
                  className={classes.footerItemTitle}
                  scroll={(el) =>
                    el.scrollIntoView({ behavior: 'auto', block: 'end' })
                  }
                >
                  {t('about_us')}
                </HashLink>
              </Box>

              <Box className={classes.footerItem}>
                <HashLink
                  to="/#how-it-works"
                  elementId="how-it-works"
                  className={classes.footerItemTitle}
                >
                  {t('how_it_works')}
                </HashLink>
              </Box>
            </Grid>

            <Grid item xs={12} sm={3} md={2} className={classes.footerList}>
              <Box className={classes.footerWrapTitle}>
                <Typography align="left" className={classes.footerTitle}>
                  {t('my_account')}
                </Typography>
              </Box>

              <Box className={classes.footerItem}>
                <Link
                  to={{
                    pathname:
                      'https://drive.google.com/file/d/1C9UieMdrCRgVKswb4feecp0Ta6zKpbu3/view?usp=sharing',
                  }}
                  target="_blank"
                  aria-label="privacy policy"
                  className={classes.footerItemTitle}
                >
                  {t('privacy_policy')}
                </Link>

                <Link
                  to={{
                    pathname:
                      'https://drive.google.com/file/d/1kHCV6FgUXyB8lbKsTkX6HbLriI6ya8_-/view?usp=sharing',
                  }}
                  target="_blank"
                  aria-label="terms of services"
                  className={classes.footerItemTitle}
                >
                  {t('terms_of_services')}
                </Link>
              </Box>
            </Grid>

            <Grid item xs={12} sm={6} md={2} className={classes.footerList}>
              <Box className={classes.footerWrapTitle}>
                <Typography align="left" className={classes.footerTitle}>
                  {t('contacts')}
                </Typography>
              </Box>

              <Box className={classes.footerItem}>
                <Typography align="left" className={classes.footerItemTitle}>
                  {t('school_name')}
                </Typography>

                <Typography
                  align="left"
                  className={`${classes.footerItemTitle} ${classes.footerItemTitleSmall}`}
                >
                  {t('ys_address')}
                </Typography>
              </Box>

              <a
                style={{
                  color: 'white',
                  display: 'block',
                  width: '100%',
                  textAlign: 'left',
                }}
                href="tel:+380934271012"
              >
                +380934271012
              </a>
            </Grid>

            <Grid item xs={12} sm={3} md={2} className={classes.footerList}>
              <Box className={classes.footerWrapTitle}>
                <Typography align="left" className={classes.footerTitle}>
                  {t('payment_methods')}
                </Typography>
              </Box>

              <Box
                width="fit-content"
                display="flex"
                alignItems="center"
                justifyContent="flex-end"
                height={40}
              >
                <Mastercard style={{ width: 40, marginRight: -5 }} />
                <Visa style={{ height: 40, width: 60 }} />
              </Box>
            </Grid>

            <Grid item xs={12} sm={6} md={2} className={classes.footerList}>
              <Box className={classes.footerWrapTitle}>
                <Typography align="left" className={classes.footerTitle}>
                  {t('support')}
                </Typography>
              </Box>

              <Box className={classes.footerItem}>
                <Typography align="left" className={classes.footerItemTitle}>
                  {t('need_help')}
                </Typography>

                <a
                  href="mailto:support@yellowschool.world?subject=Subject&body=Body%20goes%20here"
                  className={`${classes.footerItemTitle} ${classes.footerItemTitleSmall}`}
                >
                  support@yellowschool.world
                </a>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
