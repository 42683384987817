import React from 'react';
import InputMask from 'react-input-mask';
import TextField from '@material-ui/core/TextField';

const MaskedInput = ({ mask, maskChar, fieldProps, ...props }) => {
  return (
    <InputMask mask={mask} maskChar={maskChar} {...props}>
      {(inputProps) => <TextField {...inputProps} {...fieldProps} />}
    </InputMask>
  );
};

export default MaskedInput;
