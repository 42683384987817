import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const FilterDropdown = ({
  currentValue,
  onChange,
  items,
  translatePath,
  includeAll = true,
}) => {
  const { t } = useTranslation([translatePath, 'component/navigation']);

  const value =
    currentValue === 'all'
      ? t('nav.all', { ns: 'component/navigation' })
      : t(currentValue);

  return (
    <Dropdown>
      <Dropdown.Toggle
        className="dropDownToggle"
        variant="success"
        style={{ fontSize: 12, paddingTop: 20 }}
        id="dropdown-basic"
      >
        {value}
      </Dropdown.Toggle>

      <Dropdown.Menu
        className="dropDownMenu"
        style={{ maxHeight: '420px', overflow: 'auto' }}
      >
        {includeAll && (
          <Dropdown.Item onClick={() => onChange('all')}>
            {t('nav.all', { ns: 'component/navigation' })}
          </Dropdown.Item>
        )}

        {Object.entries(items).map(([key]) => (
          <Dropdown.Item key={key} onClick={() => onChange(key)}>
            {t(key, { ns: translatePath })}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default FilterDropdown;
