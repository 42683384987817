import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid, Typography } from '@material-ui/core';
import Search from '../../../assets/images/search-icon.png';
import Home from '../../../assets/images/home.png';
import Dollar from '../../../assets/images/dollar.png';
import FeatureItem from './Features/components/FeatureItem';

const useStyles = makeStyles((theme) => ({
  boldText: {
    color: '#040916',
    fontFamily: 'Roboto',
    fontSize: 40,
    fontWeight: 700,

    marginTop: 14,

    [theme.breakpoints.down('sm')]: {
      fontSize: 20,
    },

    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
  },
}));

export default function TutorWithYellow() {
  const classes = useStyles();
  const { t } = useTranslation('page/landing');

  const items = [
    {
      title: t('tutor_with_yellow_component.find_new_students'),

      image: Search,
    },
    {
      title: t('tutor_with_yellow_component.grow_your_business'),
      image: Home,
    },
    {
      title: t('tutor_with_yellow_component.get_paid_securely'),
      image: Dollar,
    },
  ];

  const [studentFirst, studentSecond] = t(
    'tutor_with_yellow_component.tutor_with_ys'
  ).split('Yellow');

  return (
    <Grid container>
      <Grid xs={10} style={{ margin: 'auto' }}>
        <Box
          mt="80px"
          borderRadius="30px"
          style={{ background: 'white', padding: '0 120px 56px' }}
        >
          <Box pt="20px">
            <p className={classes.boldText}>
              {studentFirst} <span style={{ color: '#FFB600' }}>Yellow</span>{' '}
              {studentSecond}
            </p>
          </Box>

          <Box mt="14px">
            <Typography>
              {t(
                'tutor_with_yellow_component.earn_money_sharing_your_expert_knowledge_with_students'
              )}
            </Typography>
          </Box>

          <Box
            mt="48px"
            display="flex"
            justifyContent="space-between"
            style={{ gap: '53px' }}
          >
            {items.map((item) => (
              <FeatureItem inCircle max={167} {...item} />
            ))}
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}
