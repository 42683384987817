import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Modal from '@material-ui/core/Modal';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Grid, Box, TextField, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import baseurl from '../../../baseurl';
import { selectUser } from '../../../app/slices/auth.slice';
import { useChangePasswordMutation } from '../../../app/services/tutor.api';

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}
const useStyles = makeStyles((theme) => ({
  card: {
    background: 'white',
    margin: '1rem',
    marginTop: 0,
    borderRadius: 5,
  },
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  pass: {
    color: '#6F757B',
    fontSize: 26,
    textAlign: 'left',
    padding: '1rem',
    paddingLeft: '2rem',
    borderBottomStyle: 'solid',
    borderBottomWidth: 1,
    borderBottomColor: '#DADFE1',
  },
  input1: {
    height: '0.2rem',
  },
  name: {
    color: '#384047',
    textAlign: 'left',
    paddingLeft: '2rem',
    fontSize: 17,
  },
  button: {
    backgroundColor: '#FFB600',
    color: 'white',
  },
}));

export default function Password() {
  const classes = useStyles();
  const { email } = useSelector(selectUser) || {};
  const [showModel, setShowModel] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [oldPassword, setOldPassword] = useState('');
  const [modalStyle] = React.useState(getModalStyle);
  const [warning, setWarning] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation('page/settings');

  const [changePassword, passwordState] = useChangePasswordMutation();

  const changeModel = () => {
    setWarning(false);
    setLoading(false);
    setErrorMessage('');
    setNewPassword('');
    setOldPassword('');
    setShowModel(!showModel);
  };

  const resetPassword = async () => {
    setWarning(false);
    setLoading(true);
    setErrorMessage('');

    if (newPassword === '' || oldPassword === '') {
      setErrorMessage('Please fill all fields');
      setWarning(true);
      setLoading(false);
      return;
    }
    try {
      await changePassword({ oldPassword, password: newPassword }).unwrap();
      changeModel();
    } catch (err) {}
  };

  return (
    <div className={classes.card}>
      <div>
        <Modal
          open={showModel}
          onClose={changeModel}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div style={modalStyle} className={classes.paper}>
            <h2 id="simple-modal-title" style={{ color: 'green' }}>
              {t('password_component.success')}
            </h2>

            <p id="simple-modal-description">
              {t('password_component.password_updated_successfully')}
            </p>

            <Box display="flex">
              <Box flex={1}></Box>
              <Button
                variant="contained"
                className={classes.button}
                disableElevation
                onClick={() => changeModel()}
              >
                {t('password_component.ok')}
              </Button>
            </Box>
          </div>
        </Modal>
      </div>

      <div>
        <p className={classes.pass}>{t('password_component.password')}</p>
      </div>

      {warning || passwordState.error ? (
        <p style={{ color: 'red' }}>
          {errorMessage || passwordState.error.data.error}
        </p>
      ) : null}

      <Box display="flex" style={{ marginTop: '3rem' }}>
        <Box flex={1}>
          <p className={classes.name}>{t('password_component.old_password')}</p>
        </Box>

        <Box flex={3}>
          <TextField
            style={{ width: '90%' }}
            id="outlined-read-only-input"
            value={oldPassword}
            InputProps={{ classes: { input: classes.input1 } }}
            variant="outlined"
            onChange={(e) => setOldPassword(e.target.value)}
          />
        </Box>
      </Box>

      <Box display="flex" style={{ marginTop: '3rem' }}>
        <Box flex={1}>
          <p className={classes.name}>{t('password_component.new_password')}</p>
        </Box>

        <Box flex={3}>
          <TextField
            style={{ width: '90%' }}
            value={newPassword}
            id="outlined-read-only-input"
            InputProps={{ classes: { input: classes.input1 } }}
            variant="outlined"
            onChange={(e) => setNewPassword(e.target.value)}
          />
        </Box>
      </Box>

      <Box display="flex" style={{ marginTop: '3rem' }}>
        <Box flex={1}></Box>
        <Box flex={1}>
          <Button
            variant="contained"
            className={classes.button}
            disableElevation
            onClick={() => resetPassword()}
          >
            {loading ? (
              <CircularProgress size="1.5rem" color="white" />
            ) : (
              t('save_settings')
            )}
          </Button>
        </Box>

        <Box flex={1}></Box>
      </Box>
      <Box display="flex" style={{ marginTop: '3rem' }}></Box>
    </div>
  );
}
