import useQueryParams from '../../../hooks/useQueryParams';
import { useGetAllTutorsQuery } from '../../../app/services/tutor.api';
import useFilters from './useFilters';

const prepareFilter = (initialValues, filterKey = '') => {
  if (['all', 0, undefined].includes(filterKey)) return undefined;

  return (
    Object.entries(initialValues).find(([key]) => key === filterKey)?.[1] ||
    undefined
  );
};

const useTutors = ({ page, rowsPerPage }) => {
  const { subjects, nativeLanguages, countriesOrigin } = useFilters();

  const [
    {
      nativeLanguage,
      countryOrigin,
      subject = 'ukrainian_language',
      pricePerHourMin,
      pricePerHourMax,
    },
  ] = useQueryParams();

  const {
    data: tutors,
    isLoading,
    isFetching,
  } = useGetAllTutorsQuery(
    {
      skip: page + 1,
      limit: rowsPerPage,
      nativeLanguage: prepareFilter(nativeLanguages, nativeLanguage),
      countryOrigin: prepareFilter(countriesOrigin, countryOrigin),
      subject: prepareFilter(subjects, subject),

      pricePerHourMin,
      pricePerHourMax,
    },
    { refetchOnMountOrArgChange: true }
  );

  return {
    tutors: tutors?.data,
    pagination: tutors?.pagination,
    isLoading,
    isFetching,
  };
};

export default useTutors;
