import React, { useState, useEffect } from 'react';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import NavBar from '../../common/components/Headers/topNav';
import Footer from '../../common/components/Footer';

import { useTranslation } from 'react-i18next';
import {
  useConfirmEmailMutation,
  useConfirmTutorEmailMutation,
} from '../../../app/services/auth.api';
import { useHistory, useParams } from 'react-router-dom';
import { Routes } from '../../../router/routes';
import useQueryParams from '../../../hooks/useQueryParams';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const ConfirmTutorEmail = () => {
  const classes = useStyles();

  const [{ code }] = useQueryParams();

  const { id: email, tutorId } = useParams<{ id: string; tutorId: string }>();
  const history = useHistory();

  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);
  const [showError, setShowError] = useState(false);
  const [confirmTutorEmail, confirmState] = useConfirmEmailMutation();

  const { t } = useTranslation('page/confirm_tutor_email');

  const handleConfirmTutorEmail = async () => {
    try {
      setShowError(false);

      await confirmTutorEmail({ email, verifyCode: Number(code) }).unwrap();

      setOpen(true);
    } catch (e) {
      setShowError(true);
    }
  };

  useEffect(() => {
    handleConfirmTutorEmail();
  }, []);

  const handleGoToProfileCompletion = () => {
    history.push(`${Routes.SignUp}/${tutorId}`);
  };

  return (
    <div>
      <NavBar />
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: '3rem',
        }}
      >
        <h2>{t('please_waith_confirming_account')}</h2>
      </div>

      <div style={{ height: '100vh' }}>
        <div>
          {confirmState.isLoading && <CircularProgress />}

          {showError && (
            <>
              <p style={{ color: 'red' }}>{t('unable_verify_email')}</p>
              <Button
                onClick={() => history.push('/login')}
                style={{
                  height: '100%',
                  width: '100%',
                  padding: '10px',
                  background: 'none',
                  border: 'none',
                }}
              >
                {t('go_to_login')}
              </Button>
            </>
          )}
        </div>
      </div>
      <div style={{}}>
        <Footer />
      </div>

      <Modal
        open={open}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={modalStyle} className={classes.paper}>
          <h2 id="simple-modal-title">{t('email_verified')}</h2>

          <p id="simple-modal-description">
            {t('email_verified_successfully')}
          </p>

          <Button
            variant="contained"
            style={{
              color: 'white',
              backgroundColor: 'green',
              marginRight: '2rem',
            }}
            onClick={handleGoToProfileCompletion}
          >
            {t('go_to_profile_completion')}
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default ConfirmTutorEmail;
