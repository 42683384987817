import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Grid, Box, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import NavBar from '../../common/components/Headers/topNav';
import axios from 'axios';
import Footer from '../../common/components/Footer';
import baseUrl from '../../../baseurl';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  useForgotPasswordMutation,
  useLogInMutation,
} from '../../../app/services/auth.api';
import {
  setAccessToken,
  setRefreshToken,
  setUser,
} from '../../../app/slices/auth.slice';
import { UserRoles } from '../../common/constants/user-roles';
import { Routes } from '../../../router/routes';
import useQueryParams from '../../../hooks/useQueryParams';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const Login = () => {
  const history = useHistory();
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);
  const [email, setEmail] = React.useState('');
  const dispatch = useDispatch();
  const [values, setValues] = useState({ email: '', password: '' });
  const [error, setError] = useState(null);
  const [showError, setShowError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [errorKey, setErrorKey] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const [{ as }] = useQueryParams();

  const [logIn, { isLoading }] = useLogInMutation();
  const [forgotPassword, forgotState] = useForgotPasswordMutation();
  const { t } = useTranslation('page/login');

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleResetPassword = async () => {
    setShowError(false);

    try {
      await forgotPassword({ email }).unwrap();

      setShowSuccessMessage(true);
      setSuccessMessage('Password reset code send to your email.');
      setOpen(false);
    } catch (err) {
      err?.data?.translateKey !== 'bad_request' &&
        setErrorKey(err?.data?.translateKey);

      setErrorMessage('Password not updated');
      setShowError(true);
    }
  };

  const login = async (e) => {
    e.preventDefault();
    setShowError(false);
    const { email, password } = values;

    if (email === '' || password === '') {
      setErrorMessage(t('please_fill_all_fields'));
      setShowError(true);
      return;
    }

    try {
      const { accessToken, refreshToken, role, user } = await logIn(
        values
      ).unwrap();

      dispatch(setAccessToken(accessToken));
      dispatch(setRefreshToken(refreshToken));
      dispatch(setUser({ role, ...user }));

      if (role === UserRoles.Student) {
        history.push(Routes.RequestTutorHome);
      }

      if (role === UserRoles.Tutor) {
        history.push(Routes.Dashboard);
      }
    } catch (err) {
      setErrorMessage(err?.data?.error || 'Email/Password incorrect');

      err?.data?.translateKey !== 'bad_request' &&
        setErrorKey(err?.data?.translateKey);

      setShowError(true);
    }
  };

  const onChangeValues = (e) => {
    const { value, name } = e.target;
    const newValues = { ...values };
    newValues[name] = name === 'password' ? value : value.toLowerCase();
    setValues(newValues);
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={modalStyle} className={classes.paper}>
          <h2 id="simple-modal-title">{t('reset_password')}</h2>
          <p style={{ textAlign: 'left' }}>{t('email')}*</p>

          <input
            style={{ width: '100%', height: 45, padding: 10 }}
            value={email}
            placeholder={t('enter_email')}
            onChange={(e) => setEmail(e.target.value)}
            type="text"
          ></input>

          <Box display="flex">
            <Box flex={1}></Box>
            <Button
              onClick={() => handleResetPassword()}
              style={{
                color: 'white',
                backgroundColor: '#ffcd02',
                marginTop: '1rem',
              }}
            >
              {forgotState.isLoading ? (
                <CircularProgress size={25} />
              ) : (
                t('get_link')
              )}
            </Button>
          </Box>
        </div>
      </Modal>

      <NavBar />

      <div style={{ height: '100vh' }}>
        <Grid container style={{ marginTop: '20px' }}>
          <Grid item md={4} lg={4} xl={4} sm={2} xs={2}></Grid>
          <Grid item md={4} lg={4} xl={4} sm={8} xs={8}>
            {errorKey ? (
              <p style={{ color: 'red' }}>{t(`errors.${errorKey}`)}</p>
            ) : errorMessage ? (
              <p style={{ color: 'red' }}>{errorMessage}</p>
            ) : null}

            {showSuccessMessage ? (
              <p style={{ color: 'green' }}>{successMessage}</p>
            ) : null}

            <form onSubmit={login} style={{ marginBottom: '1rem' }}>
              <p style={{ textAlign: 'left' }}>{t('email_address')}*</p>
              <input
                style={{ width: '100%', height: 45, padding: 10 }}
                value={values['email']}
                name="email"
                onChange={onChangeValues}
                type="text"
              ></input>

              <p style={{ textAlign: 'left' }}>{t('password')}*</p>

              <input
                style={{ width: '100%', height: 45, padding: 10 }}
                name="password"
                value={values['password']}
                onChange={onChangeValues}
                type="password"
              ></input>

              <Grid
                container
                direction="row"
                alignItems="center"
                justify="center"
                style={{ background: '#FFB600', marginTop: 15 }}
              >
                <Button
                  type="submit"
                  style={{
                    height: '100%',
                    width: '100%',
                    padding: '10px',
                    background: 'none',
                    border: 'none',
                  }}
                >
                  {loading || isLoading ? (
                    <CircularProgress size={25} />
                  ) : (
                    t('login')
                  )}
                </Button>
              </Grid>

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  marginTop: '10px',
                }}
              >
                <Link to="#" onClick={() => setOpen(true)}>
                  {t('forgot_password')}
                </Link>
              </div>

              <Box mt={2}>
                <Typography style={{ display: 'inline' }}>
                  {t('doesnt_have_account')}
                </Typography>{' '}
                <Link
                  to={
                    as === 'student' ? Routes.BecomeStudent : Routes.BecomeTutor
                  }
                >
                  {t('press_here_to_signup')}
                </Link>
              </Box>
            </form>
          </Grid>

          <Grid item md={4} lg={4} xl={4} sm={2} xs={2}></Grid>
        </Grid>
      </div>

      <div style={{}}>
        <Footer />
      </div>
    </div>
  );
};

export default Login;
