import React from 'react';
import { useTranslation } from 'react-i18next';
import PaperBlock from '../../tutor-page/components/PaperBlock';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

const SingleTutorAbout = (props) => {
  const { t } = useTranslation('page/single_tutor');

  return (
    <PaperBlock>
      <Typography style={{ fontSize: '23px', color: '#384047' }}>
        {t('single_tutor_about_component.about_this_tutor')}
      </Typography>

      <Box mt={2}>
        <Typography style={{ fontSize: '16px', color: '#384047', wordBreak: 'break-word', whiteSpace: 'pre-line' }}>
          {props.tutorDetail.description}
        </Typography>
      </Box>
    </PaperBlock>
  );
};

export default SingleTutorAbout;
