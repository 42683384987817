import About from '../sections/about';
import Photo from '../sections/photo';
import Description from '../sections/profileDescription';
import VideoPage from '../sections/video';
import Availability from '../sections/availability';
import Resume from '../sections/resume';
import React from 'react';

export const getSections = ({
  tutorId,
  handleBack,
  handleNext,
  avatar,
  setAvtarImage,
}) => ({
  0: <About handleNext={handleNext} tutorId={tutorId} />,
  1: (
    <Photo
      handleNext={handleNext}
      handleBack={handleBack}
      setAvtarImage={setAvtarImage}
      tutorId={tutorId}
    />
  ),
  2: (
    <Description
      handleNext={handleNext}
      handleBack={handleBack}
      image={avatar}
      tutorId={tutorId}
    />
  ),
  3: (
    <VideoPage
      handleNext={handleNext}
      tutorId={tutorId}
      handleBack={handleBack}
    />
  ),

  4: (
    <Availability
      handleNext={handleNext}
      tutorId={tutorId}
      handleBack={handleBack}
    />
  ),
  5: (
    <Resume handleNext={handleNext} tutorId={tutorId} handleBack={handleBack} />
  ),
});
