import { call, takeLatest, put } from 'redux-saga/effects';
import {
  getting,
  availabilityError,
  availabilitySuccesss,
} from '../slices/availability.slice';
import { GET_AVAILABILITY } from '../sagaActions';
import { getAvailabilityApi } from '../../API/availabilityApi';

export function* availabilitySaga(action) {
  try {
    yield put(getting());
    const result = yield call(() => getAvailabilityApi({}));
    console.log('REsult', result);
    if (result.status === 200) {
      yield put(availabilitySuccesss(result.data));
    } else {
      yield put(availabilityError(result.data));
    }
  } catch (e) {
    yield put(availabilityError(e));
  }
}

export default function* watchAvailabilitySaga() {
  yield takeLatest(GET_AVAILABILITY, availabilitySaga);
}
