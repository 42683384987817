import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import TextField from '@material-ui/core/TextField';
import MaskedInput from './MaskedInput';

const TextController = ({ name, rules, maskProps, ...props }) => {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      rules={{
        required: 'This field is required',
        ...rules,
      }}
      render={({ field, fieldState: { error } }) =>
        maskProps ? (
          <MaskedInput
            {...field}
            {...maskProps}
            variant="outlined"
            fieldProps={{
              ...props,

              error: !!error,
              helperText: error?.message,
              onBlur: field.onBlur,
            }}
          />
        ) : (
          <TextField
            {...props}
            {...field}
            variant="outlined"
            error={!!error}
            helperText={error?.message}
          />
        )
      }
    />
  );
};

export default TextController;
