import React from 'react';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  container: {
    paddingLeft: '20px',
    paddingRight: '20px',

    '@media (min-width:600px)': {
      paddingLeft: '40px',
      paddingRight: '40px',
    },

    '@media (min-width:900px)': {
      paddingLeft: '80px',
      paddingRight: '80px',
    },

    '@media (min-width:1200px)': {
      paddingLeft: '100px',
      paddingRight: '100px',
    },

    '@media (min-width:1440px)': {
      paddingLeft: '120px',
      paddingRight: '120px',
    },
  },
}));

const SideContainer = ({ children, ...props }) => {
  const { container } = useStyles();

  return (
    <Container className={container} {...props}>
      {children}
    </Container>
  );
};

export default SideContainer;
