import { callAPI } from '../services/axios';

export const updateAboutApi = async (data) => {
  const res = await callAPI({
    url: process.env.REACT_APP_UPDATE_ABOUT,
    method: 'put',
    data,
  });
  return res;
};

export const updateImageApi = async (data) => {
  const res = await callAPI({
    url: process.env.REACT_APP_UPDATE_IMAGE,
    method: 'put',
    data,
  });
  return res;
};

export const getTutorProfileApi = async (data) => {
  const res = await callAPI({
    url: process.env.REACT_APP_TUTOR_PROFILE,
    method: 'get',
    data,
  });
  return res;
};
