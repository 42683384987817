import api from './base';

const invitesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    sendInvitation: builder.mutation({
      query: (body) => ({
        url: 'auth/reffer/',
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const { useSendInvitationMutation } = invitesApi;
