import api from './base';
import { getUserId } from '../utils/getters';

const commonApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAllTutors: builder.query({
      query: (params) => ({ url: 'tutors/', params }),
      keepUnusedDataFor: 0,
    }),

    getTutorExtended: builder.query({
      query: (tutorId) => `tutors/${tutorId || getUserId()}/`,
      providesTags: ['Tutor'],
    }),

    getTutorFilterDetails: builder.query({
      query: (params) => ({ url: 'tutors/filter-details/', params }),
    }),

    getTutorWithdrawalRequests: builder.query({
      query: (params) => ({
        url: 'withdrawal-request/',
        params,
      }),
    }),

    updateTutorRating: builder.mutation({
      query: ({ tutorId, ...body }) => ({
        url: `tutors/${tutorId || getUserId()}/students/review/`,
        method: 'PATCH',
        body,
      }),
    }),

    updateTutorAbout: builder.mutation({
      query: ({ tutorId, ...body }) => ({
        url: `tutors/about/`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['Tutor'],
    }),

    updateTutorImage: builder.mutation({
      query: (body) => ({
        url: `tutors/image/`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['Tutor'],
    }),

    updateTutorVideo: builder.mutation({
      query: (body) => ({
        url: `tutors/video/`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['Tutor'],
    }),

    updateTutorDescription: builder.mutation({
      query: ({ tutorId, ...body }) => ({
        url: `tutors/description/`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['Tutor'],
    }),

    updateTutorResume: builder.mutation({
      query: ({ tutorId, ...body }) => ({
        url: `tutors/resume/`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['Tutor', 'User'],
    }),

    updateAccountInformation: builder.mutation({
      query: (body) => ({
        url: `tutors/account-information/`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['Tutor', 'User'],
    }),

    changePassword: builder.mutation({
      query: (body) => ({
        url: `users/change-password/`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['Tutor', 'User'],
    }),

    updateProfileInfo: builder.mutation({
      query: (body) => ({
        url: `tutors/profile-information/`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['Tutor', 'User'],
    }),

    promoteProfile: builder.mutation({
      query: (body) => ({
        url: `tutors/promote-profile/`,
        method: 'PATCH',
        body
      }),
      invalidatesTags: ['Tutor', 'User'],
    }),

    getSubjectsWithTutorCount: builder.query({
      query: () => ({
        url: 'tutors/subjects-with-tutor-count/',
      }),
    }),
  }),
});

export const {
  useGetAllTutorsQuery,
  useGetTutorExtendedQuery,
  useGetTutorFilterDetailsQuery,
  useGetTutorWithdrawalRequestsQuery,
  useUpdateTutorRatingMutation,

  useUpdateTutorAboutMutation,
  useUpdateTutorImageMutation,
  useUpdateTutorDescriptionMutation,
  useUpdateTutorVideoMutation,
  useUpdateTutorResumeMutation,

  useUpdateAccountInformationMutation,
  useChangePasswordMutation,
  useUpdateProfileInfoMutation,
  usePromoteProfileMutation,

  useGetSubjectsWithTutorCountQuery,
} = commonApi;
