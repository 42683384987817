import React from 'react';
import { Box, Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';

const TopTableHeader = ({ totalEarnings, onWithdrawal }) => {
  const { t: tCommon } = useTranslation('common/common');
  const { t: tLessons } = useTranslation('page/tutor_lessons_list');

  return (
    <Box
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      style={{
        display: 'flex',
        borderRadius: 2,
        marginTop: 30,
        padding: 20,
        borderBottom: '1px solid #AAB5BC',
        flexWrap: 'wrap',
        gap: '20px',
      }}
    >
      <Box>
        <span style={{ fontSize: 18 }}>{tLessons('my_lessons')}</span>
      </Box>

      <Box display="flex" alignItems="center" justifyContent="end">
        <Typography>{tCommon('total_earnings')}:</Typography>

        <Box display="flex" alignItems="center" pl={1}>
          <Typography
            style={{ fontWeight: 'bold', fontSize: 22, color: 'gray' }}
          >
            ₴
          </Typography>

          <span style={{ fontWeight: 'bold', fontSize: 20 }}>
            {totalEarnings || 0}
          </span>
        </Box>

        <Box ml={3}>
          <Button
            variant="contained"
            disabled={!totalEarnings || parseFloat(totalEarnings) <= 0}
            onClick={onWithdrawal}
          >
            {tCommon('request_withdrawal')}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default TopTableHeader;
