import { useMemo, useRef, useState } from 'react';
import Nav from '../common/components/Headers/topNav';
import SubHeaderTutor from '../common/components/Headers/SubHeaderTutor';
import 'react-calendar/dist/Calendar.css';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { useTranslation } from 'react-i18next';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import enGbLocale from '@fullcalendar/core/locales/en-gb';

import Box from '@material-ui/core/Box';
import BaseLayout from '../common/layouts/BaseLayout';
import './calendar.css';
import PrimaryButton from '../common/components/Buttons/PrimaryButton';
import { useHistory } from 'react-router-dom';
import { Routes } from '../../router/routes';
import Typography from '@material-ui/core/Typography';

import { ReactComponent as BlueDot } from '../../assets/icons/blue-dot.svg';
import { ReactComponent as GreenDot } from '../../assets/icons/green-dot.svg';
import { ReactComponent as CheckDot } from '../../assets/icons/check-dot.svg';

import 'react-modern-calendar-datepicker/lib/DatePicker.css';
import { Calendar } from 'react-modern-calendar-datepicker';
import { useGetBookedScheduleQuery } from '../../app/services/schedule';
import { ReactComponent as Checkbox } from '../../assets/icons/checkbox.svg';
import ukLocale from '@fullcalendar/core/locales/uk';
import listPlugin from '@fullcalendar/list';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down('md')]: {
      flexWrap: 'wrap',
    },
  },
}));

const transformDateTime = (date, time) => {
  return moment(
    moment(date).format('YYYY-MM-DD') +
      ' ' +
      moment(time, 'HH:mm').format('HH:mm:ss')
  ).toDate();
};

export default function TutorCalendar() {
  const history = useHistory();
  const { root } = useStyles();

  const { t, i18n } = useTranslation('page/calendar');
  const calendarRef = useRef(null);
  const [params, setParams] = useState({});

  const {
    data: schedule,
    isLoading,
    isFetching,
  } = useGetBookedScheduleQuery(params, { refetchOnMountOrArgChange: true });

  const events = useMemo(() => {
    return (
      schedule?.map(
        ({
          studentId: { name },
          lessionDate,
          startTime,
          endTime,
          isCompleted,
        }) => {
          return {
            title: name,
            date: lessionDate,
            start: transformDateTime(lessionDate, startTime),
            end: transformDateTime(lessionDate, endTime),
            isCompleted,
          };
        }
      ) || []
    );
  }, [schedule]);
  const handleNewLesson = () => history.push(Routes.TutorAvailability);

  const handleMoveCalendar = (method) => () => {
    const api = calendarRef.current.getApi();
    api[method || 'next']();

    const { activeStart, activeEnd } = api.view;

    setParams({
      from: moment(activeStart).format('YYYY-MM-DD'),
      to: moment(activeEnd).format('YYYY-MM-DD'),
    });
  };

  const Tags = [
    { icon: <GreenDot />, text: t('first_lesson') },
    { icon: <BlueDot />, text: t('regular_lesson') },
    { icon: <CheckDot />, text: t('confirmed_by_student') },
  ];

  return (
    <BaseLayout
      header={<Nav />}
      subheader={<SubHeaderTutor for="Calendar" />}
      withContainer={false}
      isLoading={isLoading}
    >
      <Box display="flex" className={root}>
        <Box style={{ background: '#EDEFF0', flexBasis: 390 }} p="48px 50px">
          <Box>
            <PrimaryButton
              style={{ width: '100%', padding: '18px 0' }}
              onClick={handleNewLesson}
            >
              {t('schedule_new_lesson')}
            </PrimaryButton>
          </Box>

          <Box
            mt="25px"
            width="100%"
            height={5}
            borderRadius={5}
            style={{ backgroundColor: '#e2e6e7' }}
          />

          <Box mt="20px">
            <Calendar />
          </Box>

          <Box
            mt="21px"
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
          >
            <Typography variant="text-21" style={{ color: '#384047' }}>
              {t('tags')}
            </Typography>

            <Box mt="24px">
              {Tags.map(({ icon, text }) => (
                <Box mb="12px" key={text} display="flex" alignItems="center">
                  {icon}

                  <Box ml="10px">
                    <Typography>{text}</Typography>
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>

        <Box style={{ flexBasis: 1520 }}>
          <FullCalendar
            ref={calendarRef}
            customButtons={{
              prev: {
                text: '<',
                click: handleMoveCalendar('prev'),
              },
              next: {
                text: '>',
                click: handleMoveCalendar(),
              },
            }}
            loading={isFetching}
            locales={[enGbLocale, ukLocale]}
            locale={i18n.language === 'ua' ? 'uk' : 'en-gb'}
            timeZone="local"
            plugins={[timeGridPlugin, listPlugin]}
            initialView="timeGridWeek"
            events={events}
            headerToolbar={{
              left: 'prev,next title',
              center: '',
              right: 'today timeGridWeek,listWeek',
            }}
            dayHeaderFormat={{
              weekday: 'short',
              day: 'numeric',
              omitCommas: true,
            }}
            allDaySlot={false}
            nowIndicator
            eventContent={renderEventContent}
            eventColor="#ffeec4"
            slotLabelFormat={{
              hour: 'numeric',
              minute: '2-digit',
              omitZeroMinute: false,
              meridiem: 'short',
              separator: ':',
            }}
          />
        </Box>
      </Box>
    </BaseLayout>
  );
}

function renderEventContent({
  event: {
    title,
    startStr,
    extendedProps: { isCompleted },
  },
}) {
  const start = moment(startStr);
  const end = start.clone().add(1, 'hour');

  return (
    <Box
      m="auto"
      display="flex"
      height="100%"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      p="6px"
      style={{ cursor: 'pointer' }}
    >
      {isCompleted && (
        <Box pb="4px">
          <Checkbox />
        </Box>
      )}

      <Typography
        style={{
          color: '#ffb600',
          fontSize: '15px',
          fontWeight: 500,
        }}
      >
        {start.format('HH:ss')} - {end.format('HH:ss')}
      </Typography>

      <Typography
        style={{
          color: '#ffb600',
          fontSize: '15px',
          textAlign: 'center',
          fontWeight: 500,
        }}
      >
        {title}
      </Typography>
    </Box>
  );
}
