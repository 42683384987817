import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import TableCell from '@material-ui/core/TableCell';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import { useTranslation } from 'react-i18next';
import { useUpdateLessonStatusMutation } from '../../../app/services/lessons';
import ReviewModal from './modals/ReviewModal';
import CompleteModal from './modals/CompleteModal';
import HowWasLessonModal from './modals/HowWasLessonModal';
import BuyHoursModal from './modals/BuyHoursModal';
import { useUpdateTutorRatingMutation } from '../../../app/services/tutor.api';
import { selectUser } from '../../../app/slices/auth.slice';
import LessonRow from './LessonRow';

export default function LessonsTable({ lessons }) {
  const [showCompleteLesson, setShowCompleteLesson] = useState(false);
  const [showHowLesson, setShowHowLesson] = useState(false);
  const [showBuyHours, setShowBuyHours] = useState(false);
  const [showReviewLesson, setShowReviewLesson] = useState(false);

  const [tutorImage, setTutorImage] = useState('');
  const [tutorName, setTutorName] = useState('');
  const [lessonDate, setLessonDate] = useState('');
  const [lessonTime, setLessonTime] = useState('');
  const [lessonId, setLessonId] = useState('');
  const [tutorId, setTutorId] = useState('');
  const [rating, setRating] = useState(0);

  const { t } = useTranslation('page/student_lessons_list');

  const user = useSelector(selectUser);

  const [updateLessonStatus, updateStatusState] =
    useUpdateLessonStatusMutation();

  const [updateTutorRating, updateTutorRatingState] =
    useUpdateTutorRatingMutation();

  const handleSubmitReview = async (comment) => {
    try {
      await updateTutorRating({
        tutorId,
        stars: rating,
        comment,
        lessonId,
      }).unwrap();

      setShowBuyHours(true);
    } finally {
      setShowReviewLesson(false);
    }
  };

  const handleUpdateLesson = async () => {
    try {
      await updateLessonStatus(lessonId).unwrap();

      setShowHowLesson(true);
    } finally {
      setShowCompleteLesson(false);
    }
  };

  const handleCompletePopup = (tImage, tName, lDate, lTime, id, tutId) => {
    setTutorImage(tImage);
    setTutorName(tName);
    setLessonDate(lDate);
    setLessonTime(lTime);
    setLessonId(id);
    setTutorId(tutId);
    setShowCompleteLesson(true);
  };

  const TABLE_HEADER = [
    { label: t('tutor_lesson_table_component.tutor_name') },
    { label: t('tutor_lesson_table_component.subject') },
    { label: t('tutor_lesson_table_component.status') },
    { label: t('tutor_lesson_table_component.date') },
    { label: '' },
    { label: '' },
    { label: '' },
  ];

  const handleHowLessonContinue = () => {
    setShowHowLesson(false);
    setShowReviewLesson(true);
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableBody>
            <TableRow>
              <TableCell />
              <TableCell />
              {TABLE_HEADER.map(({ label }, i) => (
                <TableCell style={{ textAlign: 'left', fontSize: 12 }} key={i}>
                  <b>{label}</b>
                </TableCell>
              ))}
            </TableRow>

            {lessons.map((lesson) => (
              <LessonRow
                key={lesson._id}
                lesson={lesson}
                handleCompletePopup={handleCompletePopup}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <CompleteModal
        open={showCompleteLesson}
        onClose={() => setShowCompleteLesson(false)}
        tutorImage={tutorImage}
        tutorName={tutorName}
        isLoading={updateStatusState.isLoading}
        lessonDate={lessonDate}
        lessonTime={lessonTime}
        onConfirmLesson={handleUpdateLesson}
      />

      <HowWasLessonModal
        open={showHowLesson}
        onClose={() => setShowHowLesson(false)}
        onRatingChange={setRating}
        tutorImage={tutorImage}
        onContinue={handleHowLessonContinue}
      />

      <ReviewModal
        open={showReviewLesson}
        onClose={() => setShowReviewLesson(false)}
        tutorImage={tutorImage}
        tutorName={tutorName}
        isLoading={updateTutorRatingState.isLoading}
        onSubmitReview={handleSubmitReview}
      />

      <BuyHoursModal
        open={showBuyHours}
        onClose={() => setShowBuyHours(false)}
        tutorImage={tutorImage}
        tutorName={tutorName}
      />
    </>
  );
}
