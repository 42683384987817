import { nanoid } from '@reduxjs/toolkit';

export const DayKeys = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
];

export const getDefaultSlot = () => ({
  id: nanoid(15),
  startTime: 3600,
  endTime: 7200,
});

export const defaultValues = DayKeys.reduce((acc, key) => {
  acc[key] = {
    isAvailable: false,
    slots: [getDefaultSlot()],
  };

  return acc;
}, {});
