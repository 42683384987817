import { createSlice } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';

const initialState = {
  user: null,
  accessToken: null,
  refreshToken: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAccessToken: (state, action) => {
      state.accessToken = action.payload;
    },

    setRefreshToken: (state, action) => {
      state.refreshToken = action.payload;
    },

    setUser: (state, action) => {
      state.user = action.payload;
    },

    logout: () => initialState,
  },
});

const persistedAuthReducer = persistReducer(
  { key: 'auth', storage },
  authSlice.reducer
);

export const { setAccessToken, setRefreshToken, setUser, logout } =
  authSlice.actions;

export const selectAccessToken = (store) => store.auth.accessToken;
export const selectUser = (store) => store.auth.user;

export default persistedAuthReducer;
