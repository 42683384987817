import React, { useEffect } from 'react';
import Modal from '../../../common/components/Modal';
import { FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../../app/slices/auth.slice';
import {
  Box,
  FormHelperText,
  InputAdornment,
  Typography,
} from '@material-ui/core';
import TextController from '../../../common/components/FormInputs/TextController';
import {
  AccountBalanceOutlined,
  AccountCircleOutlined,
  CreditCardOutlined,
} from '@material-ui/icons';
import {
  getAmountRules,
  getBankNameRules,
  getCreditCardRules,
  getOwnerNameRules,
} from './utils/validation';
import { Alert } from '@material-ui/lab';
import { useRequestWithdrawalMoneyMutation } from '../../../../app/services/payments.api';
import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';

const WithdrawalModal = ({ totalEarnings, ...props }) => {
  const { t: tWithdrawal } = useTranslation('component/withdrawal_modal');
  const user = useSelector(selectUser);
  const alert = useAlert();

  const methods = useForm({
    mode: 'onChange',
    defaultValues: {
      name: user?.name || '',
      bankName: '',
      cardNumber: '',
      amount: '',
    },
  });

  const {
    getValues,
    formState: { isValid },
    reset,
  } = methods;

  useEffect(reset, [props.open]);

  const [requestWithdrawal, { isLoading, error }] =
    useRequestWithdrawalMoneyMutation();

  const errorMessage = error?.data?.error?.message || error?.data?.error;

  const handleRequestWithdrawal = async () => {
    const { cardNumber, amount, ...requestBody } = getValues();
    try {
      await requestWithdrawal({
        ...requestBody,
        amount: parseFloat(amount),
        cardNumber: cardNumber.replaceAll(' ', ''),
      }).unwrap();

      props.onClose();
      alert.show(`${tWithdrawal('successful_sent_alert')}`, {
        type: 'success',
      });
    } catch (error) {}
  };

  return (
    <FormProvider {...methods}>
      <Modal
        {...props}
        title={tWithdrawal('modal_title')}
        primaryButton={{
          name: tWithdrawal('send_request_btn'),
          onClick: handleRequestWithdrawal,
          disabled: totalEarnings <= 0 || !isValid,
          isLoading,
        }}
      >
        <Box
          mt={2}
          display="flex"
          flexDirection="column"
          width="100%"
          style={{ gap: '15px' }}
        >
          <TextController
            name="name"
            label={tWithdrawal('name_input.label')}
            placeholder={tWithdrawal('name_input.placeholder')}
            rules={getOwnerNameRules()}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AccountCircleOutlined style={{ color: '#919191FF' }} />
                </InputAdornment>
              ),
            }}
          />

          <TextController
            name="bankName"
            label={tWithdrawal('bank_name_input.label')}
            placeholder={tWithdrawal('bank_name_input.placeholder')}
            rules={getBankNameRules()}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AccountBalanceOutlined style={{ color: '#919191FF' }} />
                </InputAdornment>
              ),
            }}
          />

          <TextController
            name="cardNumber"
            label={tWithdrawal('card_number_input.label')}
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
            placeholder="1234 1234 1234 1234"
            maskProps={{ mask: '9999 9999 9999 9999', maskChar: '' }}
            rules={getCreditCardRules()}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <CreditCardOutlined style={{ color: '#919191' }} />
                </InputAdornment>
              ),
            }}
          />

          <TextController
            name="amount"
            label={tWithdrawal('amount_input.label')}
            placeholder={totalEarnings || 0}
            rules={getAmountRules({ totalEarnings })}
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">₴</InputAdornment>
              ),
            }}
          />

          <Typography variant="caption" style={{ fontWeight: 500 }}>
            {tWithdrawal('payment_date_text')}
          </Typography>

          {errorMessage && (
            <Box mt={1}>
              <Alert severity="error">
                <FormHelperText error style={{ display: 'inline' }}>
                  {tWithdrawal('error_message')}: {errorMessage}
                </FormHelperText>
              </Alert>
            </Box>
          )}
        </Box>
      </Modal>
    </FormProvider>
  );
};

export default WithdrawalModal;
