import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import heic2any from 'heic2any';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Avatar from '@material-ui/core/Avatar';
import TimezonePicker from 'react-bootstrap-timezone-picker';
import 'react-bootstrap-timezone-picker/dist/react-bootstrap-timezone-picker.min.css';
import 'react-phone-number-input/style.css';
import NavBar from '../common/components/Headers/topNav';
import SubNavBar from '../common/components/Headers/SubHeaderTutor';
import Footer from '../common/components/Footer';
import Password from './components/password';
import Promotion from './components/promotion';
import About from './components/aboutUpdate';
import AlertSnackBar from './components/alertSnackBar';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import baseurl from '../../baseurl';
import Video from '../auth/sign-up/sections/video';
import Box from '@material-ui/core/Box';
import { selectUser } from '../../app/slices/auth.slice';
import { Route, useHistory } from 'react-router-dom';
import {
  useGetTutorExtendedQuery,
  useUpdateAccountInformationMutation,
  useUpdateTutorAboutMutation,
  useUpdateTutorImageMutation,
} from '../../app/services/tutor.api';
import { useDeleteUserMutation } from '../../app/services/auth.api';
import { Routes } from '../../router/routes';
import useLogout from '../../hooks/useLogout';

const useStyles = makeStyles((theme) => ({
  TextLabelLarge: {
    fontSize: 35,
  },
  card: {
    background: 'white',
    marginBottom: 20,
    borderRadius: 5,
    padding: '2rem',
  },
  links: {
    marginBottom: 0,
    marginLeft: 10,
    color: 'white',
    cursor: 'pointer',
  },
  buttons: {
    border: 'none',
    borderRadius: 2,
    padding: 10,
    width: 130,
    textAlign: 'left',
  },
  buttonsFilled: {
    background: 'rgba(0,0,0,0.2)',
    border: 'none',
    borderRadius: 2,
    textAlign: 'left',
    padding: 10,
    width: 130,
  },
  saveButton: {
    background: '#FFB600',
    color: 'black',
    border: 'none',
    borderRadius: 2,
    width: 'min-content',
  },
  deletButton: {
    border: '1px solid #F8463D',
    borderRadius: 2,
    color: '#F8463D',
    cursor: 'pointer',
    width: 130,
  },

  textField: {
    width: '90%',
    borderRadius: 2,
    height: 40,
    border: '1px solid gray',
    marginRight: 20,
    zIndex: 2,
  },
  dataFieldContainer: {
    marginTop: 10,
  },
  textLeft: {
    textAlign: 'left',
    paddingLeft: 20,
  },
  buttonSelect: {
    color: '#384047',
    backgroundColor: '#DADFE1',
    width: 'fit-content',
    textAlign: 'left',
    fontSize: 17,
    padding: 8,
    paddingLeft: 15,
    whiteSpace: 'normal',
  },
  tabsWrapper: {
    ...theme.mixins.rowStandard,
    flexWrap: 'wrap',
    padding: '0 30px',

    [theme.breakpoints.up('lg')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      padding: 0,
      justifyContent: 'flex-start',
    },
  },

  buttonUnSelect: {
    color: '#6F757B',
    width: 'fit-content',

    textAlign: 'left',
    fontSize: 17,

    whiteSpace: 'normal',
    paddingLeft: 15,
  },
}));

export default function Settings() {
  const history = useHistory();

  const user = useSelector(selectUser);

  const classes = useStyles();
  const [avatar, setAvatar] = useState('');
  const [file, setFile] = useState(null);
  const [value, setValue] = useState();
  const [userEmail, setUserEmail] = useState('');
  const [userFirstName, setUserFirstName] = useState('');
  const [userLastName, setUserLastName] = useState('');
  const [userId, setUserId] = useState({});
  const [video, setVideo] = useState(null);
  const [skypeId, setSkypeId] = useState('');

  const [fieldValues, setFieldValues] = useState({
    email: user['email'],
    firstName: user['firstName'],
    lastName: user['lastName'],
    timeZone: user['timeZone'] || 'Europe/Moscow',
  });

  const [openAlert, setOpenAlert] = useState(false);
  const [showPage, setShowPage] = useState('account');
  const { t } = useTranslation(['page/settings', 'page/sign_up']);

  const logout = useLogout();

  const { data: profile, isLoading } = useGetTutorExtendedQuery();
  const [updateInfo, aboutState] = useUpdateAccountInformationMutation();
  const [deleteUser, deleteState] = useDeleteUserMutation();

  const loading = isLoading || aboutState.isLoading || deleteState.isLoading;

  useEffect(() => {
    if (profile) {
      const { data } = profile;

      setUserId(data._id);
      setUserFirstName(data.firstName);
      setUserLastName(data.lastName);
      setValue(data.phoneNumber);
      setAvatar(data.imageUrl);
      setUserEmail(data.email);
      setVideo(data.video);
      setSkypeId(data.skypeId);
      setFieldValues((prev) => ({ ...prev, timeZone: data.timeZone }));
    }
  }, [showPage, profile]);

  const onUpdateUserData = async () => {
    const bodyFormData = new FormData();
    bodyFormData.set('firstName', userFirstName);
    bodyFormData.set('lastName', userLastName);
    bodyFormData.set('phoneNumber', value);
    bodyFormData.set('skypeId', skypeId);
    bodyFormData.set('timeZone', fieldValues['timeZone']);

    if (file) {
      bodyFormData.set('image', file);
    }

    await updateInfo(bodyFormData);
  };

  const handleFile = async (e) => {
    let file = e.target.files[0];

    if (file.type === 'image/heic' || file.type === 'image/heif') {
      file = await heic2any({ blob: file, toType: 'image/jpeg', quality: 0.2 });
    }

    const tmppath = URL.createObjectURL(file);
    setFile(file);
    setAvatar(tmppath);
  };

  const fileSelector = document.createElement('input');
  fileSelector.setAttribute('type', 'file');

  const onChange = (e) => {
    console.log('e', e.target.value);
    const values = { ...fieldValues };
    values[e.target.name] = e.target.value;
    setFieldValues({ ...values });
  };

  const onTimeZoneChange = (value) => {
    const values = { ...fieldValues };
    values['timeZone'] = value;
    setFieldValues({ ...values });
  };

  const onClose = () => {
    setOpenAlert(false);
  };

  const onDeleteAccount = async () => {
    await deleteUser({ email: userEmail });
    history.push(Routes.Login);
    logout();
  };

  return (
    <div>
      <NavBar></NavBar>
      <SubNavBar for="Settings" />

      <div style={{ background: '#EDEFF0', paddingBottom: '4rem' }}>
        <Grid container>
          <Grid item md={2} lg={2} xl={2} sm={2} xs={2}></Grid>
          <Grid item md={8} lg={8} xl={8} sm={12} xs={12}>
            <Grid
              container
              style={{
                marginTop: 20,
                marginBottom: 20,
                justifyContent: 'center',
              }}
            >
              <Box className={classes.tabsWrapper}>
                <p
                    className={
                      showPage === 'account'
                          ? classes.buttonSelect
                          : classes.buttonUnSelect
                    }
                    onClick={() => setShowPage('account')}
                    style={{cursor: 'pointer'}}
                >
                  {t('account')}
                </p>
                <p
                    className={
                      showPage === 'password'
                          ? classes.buttonSelect
                          : classes.buttonUnSelect
                    }
                    onClick={() => setShowPage('password')}
                    style={{cursor: 'pointer'}}
                >
                  {t('password')}
                </p>

                <p
                    className={
                      showPage === 'video'
                          ? classes.buttonSelect
                          : classes.buttonUnSelect
                    }
                    onClick={() => setShowPage('video')}
                    style={{cursor: 'pointer'}}
                >
                  {t('video_component.title', {ns: 'page/sign_up'})}
                </p>

                <p
                    className={
                      showPage === 'about'
                          ? classes.buttonSelect
                          : classes.buttonUnSelect
                    }
                    style={{cursor: 'pointer'}}
                    onClick={() => setShowPage('about')}
                >
                  {t('update_about')}
                </p>

                <p
                  className={
                    showPage === 'promotion'
                        ? classes.buttonSelect
                        : classes.buttonUnSelect
                  }
                  onClick={() => setShowPage('promotion')}
                  style={{cursor: 'pointer'}}
                >
                  {t('promotion')}
                </p>
              </Box>

              {showPage === 'account' ? (
                  <Grid
                      item
                      md={12}
                      lg={9}
                      xl={9}
                      sm={11}
                      xs={11}
                      style={{marginTop: 15}}
                  >
                    <div className={classes.card}>
                      <p
                          style={{
                        textAlign: 'left',
                        padding: 10,
                        fontSize: 22,
                        borderBottom: '1px solid #F2F2F2',
                      }}
                    >
                      {' '}
                      {t('account_information')}
                    </p>
                    <form model={user}>
                      <Grid container>
                        <Grid item md={4} lg={4} xl={4} sm={4} xs={4}>
                          <p className={classes.textLeft}>
                            {t('profile_details')}
                          </p>
                        </Grid>
                        <Grid item md={8} lg={8} xl={8} sm={8} xs={8}>
                          <Grid container>
                            {' '}
                            <Grid item md={4} lg={4} xl={4} sm={12} xs={12}>
                              <Avatar
                                src={avatar}
                                variant="square"
                                style={{ background: '#54BAC3', marginTop: 0 }}
                              >
                                H
                              </Avatar>
                            </Grid>
                            <Grid item md={8} lg={8} xl={8} sm={12} xs={12}>
                              <div className="upload-btn-wrapper">
                                <button className="btn">
                                  {t('upload_file')}
                                </button>
                                <input
                                  type="file"
                                  onChange={handleFile}
                                  name="myfile"
                                />
                              </div>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid container className={classes.dataFieldContainer}>
                        <Grid item md={4} lg={4} xl={4} sm={4} xs={4}>
                          <p className={classes.textLeft}>{t('first_name')}</p>
                        </Grid>
                        <Grid item md={8} lg={8} xl={8} sm={8} xs={8}>
                          <Grid
                            container
                            direction="column"
                            alignItems="flex-start"
                          >
                            <input
                              className={classes.textField}
                              type="text"
                              name="firstName"
                              value={userFirstName}
                              onChange={(e) => setUserFirstName(e.target.value)}
                            ></input>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid container className={classes.dataFieldContainer}>
                        <Grid item md={4} lg={4} xl={4} sm={4} xs={4}>
                          <p className={classes.textLeft}>{t('last_name')}</p>
                        </Grid>
                        <Grid item md={8} lg={8} xl={8} sm={8} xs={8}>
                          <Grid
                            container
                            direction="column"
                            alignItems="flex-start"
                          >
                            <input
                              className={classes.textField}
                              type="text"
                              name="lastName"
                              value={userLastName}
                              onChange={(e) => setUserLastName(e.target.value)}
                            ></input>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid container className={classes.dataFieldContainer}>
                        <Grid item md={4} lg={4} xl={4} sm={4} xs={4}>
                          <p className={classes.textLeft}>{t('email')}</p>
                        </Grid>
                        <Grid item md={8} lg={8} xl={8} sm={8} xs={8}>
                          <Grid
                            container
                            direction="column"
                            alignItems="flex-start"
                          >
                            <input
                              className={classes.textField}
                              name="email"
                              type="email"
                              value={fieldValues['email']}
                              onChange={onChange}
                              disabled
                            ></input>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid container className={classes.dataFieldContainer}>
                        <Grid item md={4} lg={4} xl={4} sm={4} xs={4}>
                          <p className={classes.textLeft}>
                            {t('phone_number')}
                          </p>
                        </Grid>
                        <Grid item md={8} lg={8} xl={8} sm={8} xs={8}>
                          <Grid
                            container
                            direction="column"
                            alignItems="flex-start"
                          >
                            <input
                              className={classes.textField}
                              type="number"
                              placeholder={t('enter_phone_number')}
                              name="phone_number"
                              value={value}
                              onChange={(e) => setValue(e.target.value)}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid container className={classes.dataFieldContainer}>
                        <Grid item md={4} lg={4} xl={4} sm={4} xs={4}>
                          <p className={classes.textLeft}>{t('skype_id')}</p>
                        </Grid>
                        <Grid item md={8} lg={8} xl={8} sm={8} xs={8}>
                          <Grid
                            container
                            direction="column"
                            alignItems="flex-start"
                          >
                            <input
                              value={skypeId}
                              onChange={(e) => setSkypeId(e.target.value)}
                              className={classes.textField}
                              type="text"
                            ></input>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid container className={classes.dataFieldContainer}>
                        <Grid item md={4} lg={4} xl={4} sm={4} xs={4}>
                          <p className={classes.textLeft}>{t('timezone')}</p>
                        </Grid>
                        <Grid item md={8} lg={8} xl={8} sm={8} xs={8}>
                          <Grid
                            container
                            direction="column"
                            alignItems="flex-start"
                          >
                            <TimezonePicker
                              absolute={false}
                              name="timeZone"
                              className={classes.textField}
                              value={fieldValues['timeZone']}
                              placeholder={t('select_timezone_placeholder')}
                              onChange={onTimeZoneChange}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        className={classes.dataFieldContainer}
                        style={{
                          gap: 20,
                          justifyContent: 'flex-end',
                          marginTop: 15,
                          paddingRight: 25,
                        }}
                      >
                        <Button
                          className={classes.saveButton}
                          onClick={onUpdateUserData}
                        >
                          {loading ? (
                            <CircularProgress size={25} />
                          ) : (
                            t('save_settings')
                          )}
                        </Button>

                        <Button
                          className={classes.deletButton}
                          onClick={onDeleteAccount}
                        >
                          {t('delete_account')}
                        </Button>
                      </Grid>
                    </form>
                  </div>
                </Grid>
              ) : null}
              <Grid item md={9} lg={9} xl={9} sm={12} xs={12}>
                {showPage === 'password' ? <Password /> : null}
                {showPage === 'video' ? (
                  <Video
                    handleNext={() => {}}
                    tutorId={userId}
                    handleBack={() => {}}
                    saveButton
                    defaultVideo={video}
                  />
                ) : null}
                {showPage === 'about' ? <About /> : null}
                {showPage === 'promotion' ? <Promotion /> : null}
              </Grid>
            </Grid>
          </Grid>
          <AlertSnackBar
            open={openAlert}
            message="Setting Updated.."
            onClose={onClose}
          />
          <Grid item md={2} lg={2} xl={2} sm={1} xs={1}></Grid>
        </Grid>
      </div>
      <div>
        <Footer></Footer>
      </div>
    </div>
  );
}
