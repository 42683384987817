import React from 'react';
import { Box, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import LanguageIcon from '../../../assets/images/language.png';
import StudentIcon from '../../../assets/images/student.png';
import LessonIcon from '../../../assets/images/lesson.png';
import SpeakIcon from '../../../assets/images/speech.png';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  Card: {
    background: 'white',
    borderRadius: 5,
    marginBottom: 20,
  },
  pic: {
    width: 100,
    height: 100,
    marginLeft: -20,
    marginBottom: 30,
    [theme.breakpoints.up('lg')]: {
      width: 120,
    },
  },
  online: {
    height: 15,
    width: 15,
    borderRadius: 20,
    border: '1px solid white',
    background: 'green',
    position: 'relative',
    left: 15,
    top: 20,

    [theme.breakpoints.up('lg')]: {
      left: 20,
    },
    [theme.breakpoints.up('xl')]: {
      left: 45,
    },
  },
  heart: {
    width: 20,
    position: 'relative',
    left: 75,
    top: -50,
  },
}));

export default function TutorCard(props) {
  const classes = useStyles();

  const { t } = useTranslation(['page/single_tutor', 'common/subject_list']);

  return (
    <div style={{ background: '#ffffff' }}>
      <div className={classes.Card}>
        <Box display="flex" style={{ gap: 20 }}>
          <Box>
            <Avatar
              variant="square"
              style={{
                minWidth: 120,
                width: '100%',
                height: '100%',
                padding: '0 10px 20px 0',
                maxWidth: 170,
                maxHeight: 170,
              }}
              src={props.pic}
            />
          </Box>

          <Box mt={1}>
            <Grid container direction="column" alignItems="flex-start">
              <p style={{ marginTop: 15, fontSize: 17 }}>{props.tutorName}</p>

              <Grid container style={{ marginTop: -5 }}>
                <p style={{ fontSize: 11, fontWeight: 'lighter' }}>
                  <img style={{ marginRight: 5 }} src={LanguageIcon}></img>

                  {t(
                    props.tutorDetail?.subjectTaught
                      .toLowerCase()
                      .replace(' ', '_'),
                    {
                      ns: 'common/subject_list',
                    }
                  )}
                </p>
              </Grid>

              <Grid container style={{ marginTop: -5 }}>
                <p style={{ fontSize: 11, fontWeight: 'lighter' }}>
                  <img style={{ marginRight: 5 }} src={StudentIcon}></img>
                  {t('single_tutor_card_component.active_students')}{' '}
                  {' ' + props.studentsCount}
                </p>
              </Grid>

              <Grid container style={{ marginTop: -5 }}>
                <p style={{ fontSize: 11, fontWeight: 'lighter' }}>
                  <img style={{ marginRight: 5 }} src={LessonIcon}></img>
                  {t('single_tutor_card_component.lessons')}{' '}
                  {' ' + props.lessons}
                </p>
              </Grid>

              <Grid container style={{ marginTop: -5 }}>
                <p style={{ fontSize: 11, fontWeight: 'lighter' }}>
                  <img style={{ marginRight: 5 }} src={SpeakIcon}></img>
                  {t('single_tutor_card_component.speaks')}:{' '}
                  {t(
                    props.tutorDetail?.subjectTaught
                      .toLowerCase()
                      .replace(' ', '_'),
                    {
                      ns: 'common/subject_list',
                    }
                  )}
                </p>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </div>
    </div>
  );
}
