import React, { useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import NativeSelect from '@material-ui/core/NativeSelect';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import InputBase from '@material-ui/core/InputBase';
import axios from 'axios';
import baseUrl from '../../../baseurl';
import 'react-phone-input-2/lib/style.css';
import { useTranslation } from 'react-i18next';
import {
  useGetTutorFilterDetailsQuery,
  useGetTutorExtendedQuery,
  useUpdateTutorAboutMutation,
  useUpdateProfileInfoMutation,
} from '../../../app/services/tutor.api';
import moment from 'moment';
import { Box, FormHelperText } from '@material-ui/core';

const BootstrapInput = withStyles((theme) => ({
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase);

const BootstrapInput2 = withStyles((theme) => ({
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 14,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: '#FFFFFF',
    padding: '2rem',
    borderRadius: 5,
    marginTop: '3rem',
  },
  name: {
    padding: 0,
  },
  input1: {
    height: '0.3rem',
  },
  input2: {
    height: 30,
    fontSize: '3em',
  },
  headings: {
    color: '#6F757B',
  },
  formControl: {
    width: '100%',
  },
  button: {
    marginTop: theme.spacing(3),
    backgroundColor: '#FFB600',
    color: 'white',
  },
}));
export default function About(props) {
  const classes = useStyles();

  const [headline, setHeadline] = React.useState('');
  const [description, setDescription] = React.useState('');

  const [error, setError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [countryOrigin, setCountryOrigin] = React.useState('');
  const [hourlyRate, setHourlyRate] = React.useState(null);
  const [subjectTaught, setSubjectTaught] = React.useState('');
  const [phoneNumber, setPhoneNumber] = React.useState('');

  const [nativeLanguage, setNativeLanguage] = React.useState('');
  const [userId, setUserId] = React.useState('');
  const { t } = useTranslation([
    'common/countries',
    'common/languages_level',
    'common/language_list',
    'page/settings',
    'common/subject_list',
    'page/sign_up',
  ]);

  const [education, setEducation] = React.useState({
    startDate: '',
    endDate: '',
    instituteName: '',
    degreeTitle: '',
  });

  const [certificate, setCertificate] = React.useState([
    {
      startDate: '',
      endDate: '',
      instituteName: '',
      certificationTitle: '',
    },
  ]);

  const [languageSpoken, setLanguageSpoken] = React.useState([
    { language: '', level: '' },
  ]);

  const tutorProfileQuery = useGetTutorExtendedQuery();

  const { data } = useGetTutorFilterDetailsQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });

  const {
    subjects = {},
    nativeLanguages = {},
    countriesOrigin = {},
  } = data || {};

  useEffect(() => {
    if (tutorProfileQuery.data) {
      const {
        countryOrigin,
        hourlyRate,
        subjectTaught,
        phoneNumber,
        nativeLanguage,
        _id,

        languageSpoken,
        certifications,
        education,
        headline,
        description,
      } = tutorProfileQuery.data.data;

      setHeadline(headline);
      setDescription(description);

      setCountryOrigin(countryOrigin);
      setHourlyRate(hourlyRate);
      setSubjectTaught(subjectTaught);
      setPhoneNumber(phoneNumber);
      setNativeLanguage(nativeLanguage);
      setUserId(_id);

      if (certifications?.length) {
        setCertificate(JSON.parse(JSON.stringify(certifications)));
      }

      if (education) {
        setEducation(JSON.parse(JSON.stringify(education)));
      }

      if (languageSpoken.length) {
        setLanguageSpoken(languageSpoken);
      }
    }
  }, [tutorProfileQuery.data]);

  const changeLanguage = (value, index) => {
    let newLang = JSON.parse(JSON.stringify(languageSpoken));
    newLang[index].language = value;
    setLanguageSpoken([...newLang]);
  };

  const changeLevel = (value, index) => {
    let newLang = JSON.parse(JSON.stringify(languageSpoken));
    newLang[index].level = value;
    setLanguageSpoken([...newLang]);
  };
  const [updateAbout] = useUpdateProfileInfoMutation();

  const onSubmit = async () => {
    setError(false);
    setErrorMessage(null);
    setLoading(true);

    if (
      !countryOrigin ||
      hourlyRate === null ||
      !hourlyRate ||
      !subjectTaught ||
      !languageSpoken[0]?.language ||
      !languageSpoken[0]?.level
    ) {
      setError(true);
      setLoading(false);
    } else {
      let data = {
        headline,
        description,

        country: 'null',
        countryOrigin: countryOrigin,
        hourlyRate: hourlyRate,
        subjectTaught: subjectTaught,
        languageSpoken: languageSpoken.reduce((acc, { _id, ...lang }) => {
          return [...acc, lang];
        }, []),
        currency: 'UAH',
        nativeLanguage: nativeLanguage,
        education: education,
        certifications: certificate.reduce((acc, { _id, ...lang }) => {
          return [...acc, lang];
        }, []),
      };

      try {
        await updateAbout(data).unwrap();
      } catch (e) {
        setErrorMessage(
          e?.data?.error || 'Adjust your data, something is wrong'
        );
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div style={{ margin: '1rem' }}>
      <Container maxWidth="md" className={classes.container} boxShadow={3}>
        <Typography className={classes.headings} variant="h6" gutterBottom>
          {t('about_update_component.about', { ns: 'page/settings' })}
        </Typography>
        {error ? (
          <Grid container alignItems="left">
            <p style={{ fontSize: 13, color: 'red' }}>
              {t('about_update_component.please_fill_all_fields', {
                ns: 'page/settings',
              })}
            </p>
          </Grid>
        ) : null}
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography
              variant="subtitle2"
              align="left"
              className={classes.headings}
            >
              {t('description_component.tag_line', { ns: 'page/sign_up' })}
            </Typography>
            <TextField
              style={{ width: '100%' }}
              placeholder={t('description_component.headline_placeholder', {
                ns: 'page/sign_up',
              })}
              value={headline}
              onChange={(e) => setHeadline(e.target.value)}
              InputProps={{ classes: { input: classes.input1 } }}
              variant="outlined"
            />
          </Grid>

          <Grid item xs={12}>
            <Typography
              variant="subtitle1"
              align="left"
              className={classes.headings}
            >
              {t('description_component.about_the_tutor', {
                ns: 'page/sign_up',
              })}
            </Typography>
            <TextField
              style={{ width: '100%' }}
              placeholder={t('description_component.description_placeholder', {
                ns: 'page/sign_up',
              })}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              variant="outlined"
              multiline
              rows={6}
            />
          </Grid>
          {/*TODO: Duplicate code, take it out separately and generate it using a loop*/}
          <Grid item xs={12}>
            <Typography
              variant="subtitle2"
              align="left"
              className={classes.headings}
            >
              {t('about_update_component.country_of_origin', {
                ns: 'page/settings',
              })}
            </Typography>
            <FormControl className={classes.formControl}>
              <NativeSelect
                id="demo-customized-select-native"
                value={countryOrigin}
                onChange={(e) => setCountryOrigin(e.target.value)}
                input={<BootstrapInput />}
              >
                <option aria-label="None" value="" />

                {Object.entries(countriesOrigin).map(
                  ([countryKey, countryValue]) => (
                    <option key={countryKey} value={countryValue}>
                      {t(countryKey, { ns: 'common/countries' })}
                    </option>
                  )
                )}
              </NativeSelect>
            </FormControl>
          </Grid>
          {languageSpoken.map((data, index) => (
            <>
              <Grid item xs={6}>
                <Typography
                  variant="subtitle2"
                  align="left"
                  className={classes.headings}
                >
                  {t('about_update_component.languages_spoken', {
                    ns: 'page/settings',
                  })}
                </Typography>
                <FormControl className={classes.formControl}>
                  <NativeSelect
                    id="demo-customized-select-native"
                    value={data.language}
                    onChange={(e) => changeLanguage(e.target.value, index)}
                    input={<BootstrapInput />}
                  >
                    <option aria-label="None" value="" />
                    <option value="English">
                      {t('english', { ns: 'common/language_list' })}
                    </option>
                    <option value="Chinese">
                      {t('chinese', { ns: 'common/language_list' })}
                    </option>
                    <option value="Hindi">
                      {t('hindi', { ns: 'common/language_list' })}
                    </option>
                    <option value="Spanish">
                      {t('spanish', { ns: 'common/language_list' })}
                    </option>
                    <option value="French">
                      {t('french', { ns: 'common/language_list' })}
                    </option>
                    <option value="Bengali">
                      {t('bengali', { ns: 'common/language_list' })}
                    </option>
                    <option value="Russian">
                      {t('russian', { ns: 'common/language_list' })}
                    </option>
                    <option value="Portuguese">
                      {t('portuguese', { ns: 'common/language_list' })}
                    </option>
                    <option value="Standard Arabic">
                      {t('standard_arabic', { ns: 'common/language_list' })}
                    </option>
                    <option value="Ukrainian">
                      {t('ukrainian', { ns: 'common/language_list' })}
                    </option>
                    <option value="German">
                      {t('german', { ns: 'common/language_list' })}
                    </option>
                    <option value="Japanese">
                      {t('japanese', { ns: 'common/language_list' })}
                    </option>
                    <option value="Turkish">
                      {t('turkish', { ns: 'common/language_list' })}
                    </option>
                    <option value="Indonesian">
                      {t('indonesian', { ns: 'common/language_list' })}
                    </option>
                  </NativeSelect>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  variant="subtitle2"
                  align="left"
                  className={classes.headings}
                >
                  {t('about_update_component.level', { ns: 'page/settings' })}
                </Typography>
                <FormControl className={classes.formControl}>
                  <NativeSelect
                    id="demo-customized-select-native"
                    value={data.level}
                    onChange={(e) => changeLevel(e.target.value, index)}
                    input={<BootstrapInput />}
                  >
                    <option aria-label="None" value="" />
                    <option value="A1 – Beginner">
                      A1 – {t('beginner', { ns: 'common/languages_level' })}
                    </option>
                    <option value="A2 – Elementary">
                      A2 – {t('elementary', { ns: 'common/languages_level' })}
                    </option>
                    <option value="B1 – Pre-Intermediate">
                      B1 –{' '}
                      {t('pre_intermediate', { ns: 'common/languages_level' })}
                    </option>
                    <option value="B2 – Intermediate">
                      B2 – {t('intermediate', { ns: 'common/languages_level' })}
                    </option>
                    <option value="C1 – Advanced">
                      C1 – {t('advanced', { ns: 'common/languages_level' })}
                    </option>
                    <option value="C2 – Proficient">
                      C2 – {t('proficiency', { ns: 'common/languages_level' })}
                    </option>
                  </NativeSelect>
                </FormControl>
              </Grid>
            </>
          ))}
          <Grid item xs={12} style={{ margin: 0, padding: 0 }}>
            <Typography
              align="left"
              variant="caption"
              display="block"
              style={{
                color: '#FFB600',
                marginLeft: '1rem',
                cursor: 'pointer',
              }}
              onClick={() => {
                // let newData = languageSpoken.push({
                //   language: "",
                //   level: "",
                // });
                setLanguageSpoken([
                  ...languageSpoken,
                  {
                    language: '',
                    level: '',
                  },
                ]);
              }}
            >
              {t('about_update_component.add_another_language', {
                ns: 'page/settings',
              })}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography
              variant="subtitle2"
              align="left"
              className={classes.headings}
            >
              {t('about_update_component.subject_taught', {
                ns: 'page/settings',
              })}
            </Typography>
            <FormControl className={classes.formControl}>
              <NativeSelect
                value={subjectTaught}
                onChange={(e) => setSubjectTaught(e.target.value)}
                input={<BootstrapInput />}
              >
                <option aria-label="None" value="" />

                {Object.entries(subjects).map(([subjectKey, subjectValue]) => (
                  <option key={subjectKey} value={subjectValue}>
                    {t(subjectKey, { ns: 'common/subject_list' })}
                  </option>
                ))}
              </NativeSelect>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ marginRight: '1rem' }}>
                <Typography
                  variant="subtitle2"
                  align="left"
                  color="red"
                  className={classes.headings}
                >
                  {t('about_update_component.hourly_rate', {
                    ns: 'page/settings',
                  })}
                </Typography>
                <TextField
                  align="left"
                  type="number"
                  id="outlined-read-only-input"
                  placeholder={0}
                  value={hourlyRate}
                  onChange={(e) => {
                    if (!e.target.value) return setHourlyRate('');

                    if (
                      e.target.value >= 0 &&
                      !isNaN(parseInt(e.target.value))
                    ) {
                      setHourlyRate(parseInt(e.target.value));
                    } else {
                      setHourlyRate(0);
                    }
                  }}
                  InputProps={{
                    classes: { input: classes.input1 },
                  }}
                  variant="outlined"
                />
              </div>

              <div style={{ marginRight: '1rem', marginTop: '1rem' }}>
                {' '}
                <FormControl className={classes.formControl}>
                  <NativeSelect
                    IconComponent="none"
                    value="UAH"
                    input={<BootstrapInput2 />}
                    disabled
                  >
                    <option value="UAH">UAH</option>
                  </NativeSelect>
                </FormControl>
              </div>
              <div>
                <Typography
                  variant="subtitle2"
                  align="left"
                  className={classes.headings}
                >
                  {t('about_update_component.native_language', {
                    ns: 'page/settings',
                  })}
                </Typography>
                <FormControl className={classes.formControl}>
                  <NativeSelect
                    id="demo-customized-select-native"
                    value={nativeLanguage}
                    onChange={(e) => setNativeLanguage(e.target.value)}
                    input={<BootstrapInput />}
                  >
                    <option aria-label="None" value="" />
                    {Object.entries(nativeLanguages).map(
                      ([langKey, langValue]) => (
                        <option key={langKey} value={langValue}>
                          {t(langKey, { ns: 'common/language_list' })}
                        </option>
                      )
                    )}
                  </NativeSelect>
                </FormControl>
              </div>
            </div>
          </Grid>
        </Grid>

        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography
              variant="subtitle2"
              align="left"
              className={classes.headings}
            >
              {t('resume_component.education', { ns: 'page/sign_up' })}
            </Typography>

            <Grid container spacing={3}>
              <Grid item md={3} lg={3} xl={3} sm={6} xs={6}>
                <TextField
                  style={{ width: '100%' }}
                  id="outlined-read-only-input"
                  placeholder={t('resume_component.from_placeholder', {
                    ns: 'page/sign_up',
                  })}
                  type="date"
                  value={moment(education.startDate).format('YYYY-MM-DD')}
                  onChange={(e) =>
                    setEducation({ ...education, startDate: e.target.value })
                  }
                  InputProps={{ classes: { input: classes.input1 } }}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={3} lg={3} xl={3} sm={3} xs={6}>
                <TextField
                  style={{ width: '100%' }}
                  id="outlined-read-only-input"
                  placeholder={t('resume_component.to_placeholder', {
                    ns: 'page/sign_up',
                  })}
                  type="date"
                  value={moment(education.endDate).format('YYYY-MM-DD')}
                  onChange={(e) =>
                    setEducation({ ...education, endDate: e.target.value })
                  }
                  InputProps={{ classes: { input: classes.input1 } }}
                  variant="outlined"
                />
              </Grid>

              <Grid item md={3} lg={3} xl={3} sm={6} xs={6}>
                <TextField
                  style={{ width: '100%' }}
                  id="outlined-read-only-input"
                  placeholder={t(
                    'resume_component.institute_name_placeholder',
                    { ns: 'page/sign_up' }
                  )}
                  value={education.instituteName}
                  onChange={(e) =>
                    setEducation({
                      ...education,
                      instituteName: e.target.value,
                    })
                  }
                  InputProps={{ classes: { input: classes.input1 } }}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={3} lg={3} xl={3} sm={3} xs={6}>
                <TextField
                  style={{ width: '100%' }}
                  id="outlined-read-only-input"
                  placeholder={t('resume_component.degree_title_placeholder', {
                    ns: 'page/sign_up',
                  })}
                  value={education.degreeTitle}
                  onChange={(e) =>
                    setEducation({ ...education, degreeTitle: e.target.value })
                  }
                  InputProps={{ classes: { input: classes.input1 } }}
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Typography
              variant="subtitle2"
              align="left"
              className={classes.headings}
            >
              {t('resume_component.certification', { ns: 'page/sign_up' })}
            </Typography>
            {certificate.map((data, index) => (
              <>
                <Grid container spacing={3}>
                  <Grid item md={3} lg={3} xl={3} sm={6} xs={6}>
                    <TextField
                      style={{ width: '100%' }}
                      placeholder={t('resume_component.from_placeholder', {
                        ns: 'page/sign_up',
                      })}
                      type="date"
                      value={moment(data.startDate).format('YYYY-MM-DD')}
                      onChange={(e) => {
                        let allCers = JSON.parse(JSON.stringify(certificate));
                        allCers[index].startDate = e.target.value;
                        setCertificate(allCers);
                      }}
                      InputProps={{ classes: { input: classes.input1 } }}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={3} lg={3} xl={3} sm={3} xs={6}>
                    <TextField
                      style={{ width: '100%' }}
                      placeholder={t('resume_component.to_placeholder', {
                        ns: 'page/sign_up',
                      })}
                      type="date"
                      value={moment(data.endDate).format('YYYY-MM-DD')}
                      onChange={(e) => {
                        let allCers = JSON.parse(JSON.stringify(certificate));

                        allCers[index].endDate = e.target.value;
                        setCertificate(allCers);
                      }}
                      InputProps={{ classes: { input: classes.input1 } }}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={3} lg={3} xl={3} sm={6} xs={6}>
                    <TextField
                      style={{ width: '100%' }}
                      id="outlined-read-only-input"
                      placeholder={t(
                        'resume_component.platform_name_placeholder',
                        { ns: 'page/sign_up' }
                      )}
                      value={data.instituteName}
                      onChange={(e) => {
                        let allCers = JSON.parse(JSON.stringify(certificate));

                        allCers[index].instituteName = e.target.value;
                        setCertificate(allCers);
                      }}
                      InputProps={{ classes: { input: classes.input1 } }}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={3} lg={3} xl={3} sm={3} xs={6}>
                    <TextField
                      style={{ width: '100%' }}
                      id="outlined-read-only-input"
                      placeholder={t(
                        'resume_component.certificate_title_placeholder',
                        { ns: 'page/sign_up' }
                      )}
                      value={data.certificationTitle}
                      onChange={(e) => {
                        let allCers = JSON.parse(JSON.stringify(certificate));

                        allCers[index].certificationTitle = e.target.value;
                        setCertificate(allCers);
                      }}
                      InputProps={{ classes: { input: classes.input1 } }}
                      variant="outlined"
                    />
                  </Grid>{' '}
                </Grid>
              </>
            ))}

            <Grid
              item
              xs={12}
              style={{ margin: 0, padding: 0, display: 'flex' }}
            >
              <Typography
                align="left"
                variant="caption"
                // display='block'
                style={{
                  color: '#FFB600',
                  marginLeft: '1rem',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  setCertificate([
                    ...certificate,
                    {
                      startDate: '',
                      endDate: '',
                      instituteName: '',
                      certificationTitle: '',
                    },
                  ]);
                }}
              >
                {t('resume_component.add_certificate', {
                  ns: 'page/sign_up',
                })}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        {errorMessage && (
          <Box py={2}>
            <FormHelperText error>{errorMessage}</FormHelperText>
          </Box>
        )}

        <Button
          variant="contained"
          disabled={loading}
          className={classes.button}
          onClick={() => onSubmit()}
        >
          {loading === false ? (
            t('update_about', { ns: 'page/settings' })
          ) : (
            <CircularProgress size={25} />
          )}
        </Button>
      </Container>
    </div>
  );
}
