function generateDateString(day, from) {
  const now = new Date();
  const currentDay = now.getDay();
  const targetDay = [
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
    'sunday',
  ].indexOf(day.toLowerCase());

  const targetDate = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate() - currentDay + 1 + targetDay
  );

  const [fromHours, fromMinutes] = from.split(':').map(Number);
  targetDate.setHours(fromHours, fromMinutes);

  return targetDate;
}

export default generateDateString;
