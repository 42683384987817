import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import useModalStyles from '../../../common/components/Modal/styles/modal';
import { useTranslation } from 'react-i18next';
import { Routes } from '../../../../router/routes';
import { useHistory } from 'react-router-dom';

const BuyHoursModal = ({ open, onClose, tutorImage, tutorName }) => {
  const classes = useModalStyles();
  const history = useHistory();

  const { t } = useTranslation('page/student_lessons_list');

  const handleBuyHours = () => {
    onClose();
    history.push(Routes.RequestTutorHome);
  };

  return (
    <Modal open={open}>
      <div className={classes.modalLessonWrap}>
        <div className={classes.modalLessonHeaderWrap}>
          <CloseIcon
            className={classes.modalLessonCloseIcon}
            onClick={onClose}
          />
        </div>

        <div className={classes.modalLessonBodyWrap}>
          <img src={tutorImage} className={classes.modalLessonTutorImg} />
        </div>

        <h2 id="simple-modal-title" className={classes.modalLessonTitle}>
          {t('tutor_lesson_table_component.lesson_rated')} {tutorName}
        </h2>

        <p id="simple-modal-description" className={classes.title}>
          {t('tutor_lesson_table_component.keep_up_the_good_work')}
        </p>

        <div className={classes.modalLessonBtnWrap}>
          <Button
            variant="contained"
            className={classes.modelLessonBtnContinue}
            onClick={handleBuyHours}
          >
            {t('tutor_lesson_table_component.buy_hours')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default BuyHoursModal;
