import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  gettingTutorLessons: false,
  tutorLessons: null,
  lessonScheduling: false,
  scheduledLesson: null,
  gettingSchedule: false,
  tutorSchedule: [],
  error: null,
};

const tutorLessonSlice = createSlice({
  name: 'tutorLesson',
  initialState,
  reducers: {
    //get tutor lesson
    getTutorLessons(state) {
      state.gettingTutorLessons = true;
      state.tutorLessons = null;
      state.error = null;
    },
    getTutorLessonsSuccesss(state, action) {
      state.gettingTutorLessons = false;
      state.tutorLessons = action.payload.data;
    },
    getTutorLessonsError(state, action) {
      state.gettingTutorLessons = false;
      state.error = action.payload;
    },

    //schedule lesson
    scheduleLesson(state) {
      state.lessonScheduling = true;
      state.scheduledLesson = null;
      state.error = null;
    },
    scheduleLessonSuccess(state, action) {
      const updatedLessons = state.tutorLessons.map((lesson) =>
        lesson._id === action.payload._id ? action.payload : lesson
      );
      state.lessonScheduling = false;
      state.scheduledLesson = action.payload;
      state.tutorLessons = updatedLessons;
    },
    scheduleLessonError(state, action) {
      state.lessonScheduling = false;
      state.error = action.payload;
    },

    //schedule get
    getSchedule(state) {
      state.gettingSchedule = true;
      state.tutorSchedule = [];
    },
    getScheduleSuccess(state, action) {
      state.gettingSchedule = false;
      state.tutorSchedule = action.payload.data;
    },
    getScheduleError(state, action) {
      state.gettingSchedule = false;
      state.error = action.payload;
    },
  },
});

export const {
  getTutorLessons,
  getTutorLessonsSuccesss,
  getTutorLessonsError,
  scheduleLesson,
  scheduleLessonSuccess,
  scheduleLessonError,
  getSchedule,
  getScheduleSuccess,
  getScheduleError,
} = tutorLessonSlice.actions;

export default tutorLessonSlice.reducer;
