import React, { FC } from 'react';
import { Routes } from '../../../../router/routes';
import { Link } from 'react-router-dom';
import { ReactComponent as BusLogo } from '../../../../assets/icons/bus-logo.svg';
import { ReactComponent as DarkBusLogo } from '../../../../assets/icons/bus-logo-dark.svg';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'block',
    width: '95px',
    height: 'auto',

    [theme.breakpoints.up('md')]: {
      width: '122px',
      height: '63px',
    },
  },
}));

interface IProps {
  mode?: string;
  style?: any;
}

const LogoLink: FC<IProps> = ({ mode, style }) => {
  const { root } = useStyles();

  return (
    <Link to={Routes.Home} className={root}>
      {mode === 'dark' ? (
        <BusLogo style={style} />
      ) : (
        <DarkBusLogo style={style} />
      )}
    </Link>
  );
};

export default LogoLink;
