import React from 'react';
import { useTranslation } from 'react-i18next';

const ResetPasswordSuccess = () => {
  const { t } = useTranslation('page/reset_password_success');

  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: '3rem',
          height: '50vh',
        }}
      >
        <h2>{t('password_successfully_changed')}</h2>
      </div>
      <div style={{}}></div>
    </div>
  );
};

export default ResetPasswordSuccess;
