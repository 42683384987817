import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import { Routes } from '../../../../router/routes';
import SettingMenuItem from './SettingMenuItem';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    background: '#edeff0',
    padding: '40px 50px 40px 35px',
    textAlign: 'left',
    height: '100%',
    [theme.breakpoints.up('md')]: {
      padding: '40px 50px 0 35px',
    },
  },

  settingsTitle: {
    color: '#384047',
    fontSize: '14px',
    fontWeight: 500,
    textTransform: 'uppercase',

    [theme.breakpoints.up('md')]: {
      fontSize: '18px',
    },
  },
}));

const Menu = () => {
  const { root, settingsTitle } = useStyles();
  const { t } = useTranslation('page/tutor_availability');
  const { pathname } = useLocation();

  const menuItems = [
    {
      title: t('availability'),
      path: Routes.TutorAvailability,
    },
  ];

  return (
    <Box className={root}>
      <Box pl="20px">
        <Typography className={settingsTitle}>{t('settings')}</Typography>
      </Box>

      <Box mt="33px">
        {menuItems.map(({ title, path }) => (
          <SettingMenuItem key={title} isActive={path === pathname}>
            {title}
          </SettingMenuItem>
        ))}
      </Box>
    </Box>
  );
};

export default Menu;
