import React from 'react';

import { Grid } from '@material-ui/core';
import SubHeaderTutor from '../common/components/Headers/SubHeaderStudent';
import TutorLessonTable from './components/LessonsTable';
import { useTranslation } from 'react-i18next';
import { useGetStudentLessonsQuery } from '../../app/services/lessons';
import BaseLayout from '../common/layouts/BaseLayout';

export default function StudentMyLessonsPage() {
  const { t } = useTranslation('page/student_lessons_list');

  const { data: lessons, isLoading, refetch } = useGetStudentLessonsQuery();

  return (
    <BaseLayout
      isLoading={isLoading}
      subheader={<SubHeaderTutor for="My Lessons" />}
    >
      <Grid container style={{ paddingBottom: '2rem' }}>
        <Grid item md={2} lg={2} xl={2} sm={2} xs={2}></Grid>

        <Grid item md={8} lg={8} xl={8} sm={8} xs={8}>
          <div
            style={{
              boxShadow:
                '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
              background: 'white',
            }}
          >
            <Grid
              container
              direction="row"
              alignItems="center"
              style={{
                height: 60,
                borderRadius: 2,
                marginTop: 30,
                paddingLeft: 20,
                borderBottom: '1px solid #AAB5BC',
                paddingRight: 20,
              }}
            >
              <Grid item md={6} lg={6} xl={6} sm={6} xs={6}>
                <Grid container direction="column" alignItems="flex-start">
                  <span style={{ fontSize: 18 }}>{t('my_lessons')}</span>
                </Grid>
              </Grid>

              <Grid item md={6} lg={6} xl={6} sm={6} xs={6}>
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  justify="flex-end"
                >
                  {/* <ArchiveIcon style={{ color: "gray" }}></ArchiveIcon>
                  <span>{t('archived_lessons')}</span> */}
                </Grid>
              </Grid>
            </Grid>

            {lessons?.data?.length && (
              <TutorLessonTable lessons={lessons.data} getData={refetch} />
            )}

            {!lessons?.data?.length ? <p>{t('lessons_not_found')}</p> : null}
          </div>
        </Grid>
        <Grid item md={2} lg={2} xl={2} sm={2} xs={2}></Grid>
      </Grid>
    </BaseLayout>
  );
}
