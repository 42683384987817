import { makeStyles } from '@material-ui/core';

export const useChatStyles = makeStyles(() => ({
  header: {
    borderBottom: '2px solid #e8eaeb',
    padding: '0 20px',
    display: 'flex',
    alignItems: 'center',
    height: '50px',
  },
  activeHeader: {
    marginBottom: 0,
    color: '#FFB600',
    fontWeight: 'bold',
    marginLeft: 20,
    paddingTop: 10,
    width: 40,
    cursor: 'pointer',
    height: '100%',
    borderBottom: '4px solid #FFB600',
  },
  inactiveHeader: {
    marginBottom: 0,
    cursor: 'pointer',
    marginLeft: 20,
    paddingTop: 10,
    color: 'gray',
    width: 40,
    height: '100%',
  },
  participantImage: {
    width: 45,
    height: 45,
    borderRadius: '50%',
  },
  highlightText: {
    marginBottom: 0,
    color: '#FFB600',
    textAlign: 'center',
    fontWeight: 'bold',
  },
  dateContainer: {
    background: '#F2F2F2',
    color: 'gray',
    borderRadius: 15,
    marginTop: 10,
    padding: '5px 10px',
    width: 'auto',
  },
  messageRow: {
    display: 'flex',
    justifyContent: 'flex-start',
    marginBottom: '10px',
  },
  myMessageRow: {
    justifyContent: 'flex-end',
  },
  messageContainer: {
    background: '#F2F2F2',
    color: 'gray',
    borderRadius: '15px',
    padding: '10px',
    maxWidth: '70%',
    wordWrap: 'break-word',
  },
  myMessageContainer: {
    background: '#FFB600',
    color: 'black',
    borderRadius: '15px',
    padding: '10px',
    maxWidth: '70%',
    wordWrap: 'break-word',
  },
  sidePanel: {
    borderRight: '2px solid #e8eaeb',
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100vh - 114px)',
  },
  chatList: {
    flex: 1,
    overflowY: 'auto',
    margin: '0 15px',
  },
  chatItem: {
    display: 'flex',
    alignItems: 'flex-start',
    borderBottom: '2px solid #e8eaeb',
    cursor: 'pointer',
    padding: '10px',
    '&:hover': {
      backgroundColor: '#f5f5f5',
    }
  },
  participantDetails: {
    marginLeft: '10px',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  nameAndDate: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    marginBottom: '5px',
  },
  lastMessageContent: {
    color: 'gray',
    fontSize: '0.8rem',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '100%',
    margin: '0 0 0 10px',
    textAlign: 'left',
    width: '209px',
    whiteSpace: 'nowrap',
  },
  participantName: {
    fontWeight: 'bold',
    margin: '0 0 0 10px',
  },
  lastMessageTime: {
    marginLeft: 'auto',
    textAlign: 'right',
    fontSize: '0.8rem',
  },
  messagePanel: {
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100vh - 114px)',
  },
  chatHeader: {
    borderBottom: '2px solid #e8eaeb',
    padding: '10px 0',
    marginBottom: '20px',
  },
  messageBox: {
    flex: 1,
    overflowY: 'auto',
    margin: '0 15px',
    maxHeight: '100vh',
  },
  welcomeMessage: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  typingIndicator: {
    fontStyle: 'italic',
    color: '#606060',
    padding: '10px',
    textAlign: 'center',
    animation: '$blink 1.5s step-end infinite',
  },
  '@keyframes blink': {
    '50%': {
      opacity: 0,
    },
  },
}));