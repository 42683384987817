import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import LessonRow from './LessonRow';
import { useTranslation } from 'react-i18next';

export default function LessonsTable({ lessons }) {
  const { t: tLessonTable } = useTranslation('component/lesson_table');
  const TABLE_HEADER = [
    { label: tLessonTable('students_colum_name') },
    { label: tLessonTable('price_per_hour_colum_name') },
    { label: tLessonTable('status_colum_name') },
    { label: tLessonTable('date_colum_name') },
    { label: tLessonTable('start_time_colum_name') },
    { label: tLessonTable('end_time_colum_name') },
    { label: '' },
    { label: '' },
  ];

  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableBody>
          <TableRow>
            <TableCell />

            {TABLE_HEADER.map(({ label }, i) => (
              <TableCell style={{ textAlign: 'left', fontSize: 12 }} key={i}>
                {label}
              </TableCell>
            ))}
          </TableRow>

          {lessons?.map((lesson) => (
            <LessonRow key={lesson._id} lesson={lesson} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
