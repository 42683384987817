import React, { useEffect, useState } from 'react';
import { Box, Select } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';

const useSelectStyles = makeStyles(() => ({
  root: {
    color: ({ color = 'white' }) => color,
    paddingRight: '5px !important',

    '& .MuiSelect-select.MuiSelect-select': {
      paddingRight: '5px !important',
    },
  },

  r2: {
    '& .MuiPaper-root': {
      background: '#FFB600 !important',
    },
  },
}));
const LanguageSelector = ({ color }) => {
  const selectClasses = useSelectStyles({ color });
  const { i18n } = useTranslation();

  const [language, setLanguage] = useState('ua');

  useEffect(() => {
    setLanguage('ua');
    i18n.changeLanguage('ua');
  }, []);

  const handleChangeLanguage = ({ target: { value: lang } }) => {
    setLanguage(lang);
    i18n.changeLanguage(lang);
  };

  return (
    <Select
      classes={selectClasses}
      variant="standard"
      value={language}
      onChange={handleChangeLanguage}
      displayEmpty
      IconComponent={() => (
        <Box pb="3px">
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5 7.5L10 12.5L15 7.5"
              stroke="#FFFFFF"
              stroke-width="1.67"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </Box>
      )}
      MenuProps={{ className: selectClasses.r2 }}
      disableUnderline
    >
      <MenuItem value="en" style={{ background: '#FFB600', color: 'white' }}>
        EN
      </MenuItem>
      <MenuItem value="ua" style={{ background: '#FFB600', color: 'white' }}>
        UA
      </MenuItem>
    </Select>
  );
};

export default LanguageSelector;
