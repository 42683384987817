import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  filterBlock: {
    border: '1px solid',
    borderColor: 'rgb(112, 112, 112, 0.44)',
    borderTopLeftRadius: ({ first }) => (first ? '5px' : 0),
    borderBottomLeftRadius: ({ first }) => (first ? '5px' : 0),

    borderTopRightRadius: ({ last }) => (last ? '5px' : 0),
    borderBottomRightRadius: ({ last }) => (last ? '5px' : 0),

    '&:not(:last-child)': {
      borderRight: 'none',
    },
  },
  leftTextLabel: {
    color: '#6f757b',
    fontSize: '0.8rem',
    letterSpacing: '0.3px',
    textTransform: 'uppercase',
    padding: '0.7rem 0 0.5rem 1.2rem',
    margin: 0,
  },
}));

const FilterWrapper = ({ children, name, first, last }) => {
  const classes = useStyles({ first, last });

  return (
    <Grid item xs={6} md={3} className={classes.filterBlock}>
      <Grid container direction="column" alignItems="flex-start">
        <p className={classes.leftTextLabel}>{name}</p>

        {children}
      </Grid>
    </Grid>
  );
};

export default FilterWrapper;
