import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import dateFormat from 'dateformat';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Modal from '@material-ui/core/Modal';
import useModalStyles from '../../../common/components/Modal/styles/modal';
import { useTranslation } from 'react-i18next';

const CompleteModal = ({
  open,
  onClose,
  tutorImage,
  tutorName,
  lessonDate,
  lessonTime,
  isLoading,
  onConfirmLesson,
}) => {
  const classes = useModalStyles();
  const { t } = useTranslation('page/student_lessons_list');

  return (
    <Modal open={open}>
      <div className={classes.modalLessonWrap}>
        <div className={classes.modalLessonHeaderWrap}>
          <CloseIcon
            className={classes.modalLessonCloseIcon}
            onClick={onClose}
          />
        </div>

        <div className={classes.modalLessonBodyWrap}>
          <img src={tutorImage} className={classes.modalLessonTutorImg} />
        </div>

        <h2 id="simple-modal-title" className={classes.modalLessonTitle}>
          {t('tutor_lesson_table_component.did_you_complete')} {tutorName} ?
        </h2>

        <p id="simple-modal-description" className={classes.title}>
          {dateFormat(
            lessonDate !== null && lessonDate !== '' ? lessonDate : Date.now(),
            'dddd, mmmm dS'
          )}{' '}
          {t('tutor_lesson_table_component.at')}
          {lessonTime}
          {t('tutor_lesson_table_component.hour_english_language_lesson', {
            count: 1,
          })}
        </p>

        <div className={classes.modalLessonBtnsWrap}>
          <div />

          <Button
            variant="contained"
            className={classes.modelLessonBtnContinue}
            disabled={isLoading}
            onClick={onConfirmLesson}
          >
            {isLoading ? (
              <CircularProgress size={25} style={{ color: 'white' }} />
            ) : (
              t('tutor_lesson_table_component.confirm_lessons')
            )}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default CompleteModal;
