import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import PaperBlock from '../../components/PaperBlock';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { ReactComponent as QuestionMark } from '../../../../assets/icons/question.svg';
import FullCalendar from '@fullcalendar/react';
import enGbLocale from '@fullcalendar/core/locales/en-gb';
import ukLocale from '@fullcalendar/core/locales/uk';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import { useGetAvailableScheduleQuery } from '../../../../app/services/schedule';
import { useHistory, useParams } from 'react-router-dom';
import moment from 'moment/moment';
// TODO: Remove the LiqPay logic
// import { useCreateLiqPayFormMutation } from '../../../../app/services/payments.api';
import { useCreateLessonPaymentMutation } from '../../../../app/services/payments.api';
import { useRequestLessonMutation } from '../../../../app/services/lessons';
import { useAlert } from 'react-alert';
import TransactionModal from './components/TransactionModal';
import { useSelector } from 'react-redux';
import { selectAccessToken } from '../../../../app/slices/auth.slice';
import { Routes } from '../../../../router/routes';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

const Schedule = ({ pricePerHour }) => {
  const { t, i18n } = useTranslation('page/single_tutor');
  const history = useHistory();
  const { id } = useParams();
  const [api, setApi] = useState(null);

  const calendarRef = useCallback((node) => {
    if (node) {
      setApi(node.getApi());
    }
  });

  const alert = useAlert();
  const accessToken = useSelector(selectAccessToken);

  const [params, setParams] = useState({
    from: moment().format('YYYY-MM-DD'),
    to: moment().add(1, 'week').format('YYYY-MM-DD'),
  });
  const [payForm, setPayForm] = useState(null);

  const {
    data: schedule,
    isLoading,
    isFetching,
  } = useGetAvailableScheduleQuery(
    {
      tutorId: id,
      ...params,
    },
    { refetchOnMountOrArgChange: true }
  );

  const [createLessonRequest, lessonStatus] = useRequestLessonMutation();
  // TODO: Remove the LiqPay logic
  // const [createLiqPayForm, payStatus] = useCreateLiqPayFormMutation();
  const [createLessonPayment, paymentStatus] = useCreateLessonPaymentMutation();

  useEffect(() => {
    api && setRange();
  }, [api]);
  const setRange = () => {
    const { activeStart, activeEnd } = api?.view || {};

    setParams({
      from: moment(activeStart).format('YYYY-MM-DD'),
      to: moment(activeEnd).format('YYYY-MM-DD'),
    });
  };

  const handleMoveCalendar = (method) => () => {
    api?.[method || 'next']();
    setRange();
  };

  const events = schedule?.map(({ from, to }) => ({
    start: from,
    end: to,
    title: '',
  }));

  const handleOpenPayModal = async ({ event: { start, end } }) => {
    try {
      if (!accessToken) return history.push(Routes.Login + '?as=student');

      const lesson = await createLessonRequest({
        tutorId: id,
        lessionDate: start,
        startTime: moment(start).format('HH:mm'),
        endTime: moment(end).format('HH:mm'),
        totalHours: 1,
        pricePerHour: pricePerHour,
        totalPrice: pricePerHour,
      }).unwrap();

      // TODO: Remove the LiqPay logic
      // const liqpayForm = await createLiqPayForm(lesson?.data?._id).unwrap();
      const paymentData = await createLessonPayment(lesson?.data?._id).unwrap();

      setPayForm({
        // TODO: Remove the LiqPay logic
        // ...liqpayForm,
        ...paymentData,
        start: moment(start).format('YYYY-MM-DD HH:mm'),
        end: moment(end).format('YYYY-MM-DD HH:mm'),
      });
    } catch (e) {
      alert.show('Request failed, please try again later');
    }
  };

  return (
    <>
      <Backdrop
        open={
          lessonStatus.isLoading || 
          // TODO: Remove the LiqPay logic
          // payStatus.isLoading ||
          paymentStatus.isLoading ||
          isLoading ||
          isFetching
        }
        style={{ zIndex: 1000000000 }}
      >
        <CircularProgress color="primary" />
      </Backdrop>

      <TransactionModal
        formData={payForm}
        closeModal={() => setPayForm(null)}
        hourlyRate={pricePerHour}
      />

      <PaperBlock>
        <Typography style={{ fontSize: '23px', color: '#384047' }}>
          {t('tutor_schedule_calender_component.title')}
        </Typography>

        <Box mt={3} pt={3} style={{ borderTop: '1px solid #E1E1E1FF' }}>
          <Box
            p="18px"
            display="flex"
            alignItems="center"
            borderRadius={3}
            sx={{ bgcolor: '#f6f7f7', gap: '12px' }}
          >
            <QuestionMark />

            <Typography style={{ fontSize: '15px', color: '#384047' }}>
              {t(
                'tutor_schedule_calender_component.calender_design_component.choose_the_time'
              )}
            </Typography>
          </Box>
        </Box>

        <Box mt="2">
          {events && (
            <FullCalendar
              ref={calendarRef}
              customButtons={{
                prev: {
                  text: '<',
                  click: handleMoveCalendar('prev'),
                },
                next: {
                  text: '>',
                  click: handleMoveCalendar(),
                },
              }}
              locales={[enGbLocale, ukLocale]}
              locale={i18n.language === 'ua' ? 'uk' : 'en-gb'}
              timeZone="local"
              plugins={[timeGridPlugin, listPlugin]}
              initialView="timeGridWeek"
              events={events}
              headerToolbar={{
                left: 'prev,next title',
                right: '',
              }}
              dayHeaderFormat={{
                weekday: 'short',
                day: 'numeric',
                omitCommas: true,
              }}
              allDaySlot={false}
              nowIndicator
              eventColor="#ffb600"
              slotLabelFormat={{
                hour: 'numeric',
                minute: '2-digit',
                omitZeroMinute: false,
                meridiem: 'short',
                separator: ':',
              }}
              eventClick={handleOpenPayModal}
            />
          )}
        </Box>
      </PaperBlock>
    </>
  );
};

export default Schedule;
