import React, {useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import Modal from '@material-ui/core/Modal';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Box, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useGetTutorExtendedQuery, usePromoteProfileMutation } from '../../../app/services/tutor.api';

function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const useStyles = makeStyles((theme) => ({
    card: {
        background: 'white',
        margin: '1rem',
        marginTop: 0,
        borderRadius: 5,
    },
    paper: {
        position: 'absolute',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    pass: {
        color: '#6F757B',
        fontSize: 26,
        textAlign: 'left',
        padding: '1rem',
        paddingLeft: '2rem',
        borderBottomStyle: 'solid',
        borderBottomWidth: 1,
        borderBottomColor: '#DADFE1',
    },
    name: {
        color: '#384047',
        textAlign: 'left',
        fontSize: 17,
    },
    button: {
        backgroundColor: '#FFB600',
        color: 'white',
    },
}));

export default function Promotion() {
    const classes = useStyles();
    const [showModel, setShowModel] = useState(false);
    const [modalStyle] = React.useState(getModalStyle);
    const [warning, setWarning] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [promotionStatus, setPromotionStatus] = React.useState(false);
    const [promotionDaysLeft, setPromotionDaysLeft] = React.useState(0);
    const { t } = useTranslation('page/settings');

    const tutorProfileQuery = useGetTutorExtendedQuery();
    const [ promoteProfile, promotionState ] = usePromoteProfileMutation();

    useEffect(() => {
        if (tutorProfileQuery.data) {
            const { promotionStatus, promotionDaysLeft } = tutorProfileQuery.data.data;
            setPromotionStatus(promotionStatus);
            setPromotionDaysLeft(promotionDaysLeft);
        }
    }, [tutorProfileQuery.data]);


    const changeModel = () => {
        setWarning(false);
        setLoading(false);
        setErrorMessage('');
        setShowModel(!showModel);
    };

    const promote = async () => {
        setWarning(false);
        setLoading(true);
        setErrorMessage('');

        try {
            await promoteProfile({}).unwrap();
            changeModel();
        } catch (err) {}
    };

    return (
        <div className={classes.card}>
            <div>
                <Modal
                    open={showModel}
                    onClose={changeModel}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                    <div style={modalStyle} className={classes.paper}>
                        <h2 id="simple-modal-title" style={{ color: 'green' }}>
                            {t('promotion_component.success')}
                        </h2>

                        <p id="simple-modal-description">
                            {t('promotion_component.promoted_successfully')}
                        </p>

                        <Box display="flex">
                            <Box flex={1}></Box>
                            <Button
                                variant="contained"
                                className={classes.button}
                                disableElevation
                                onClick={() => changeModel()}
                            >
                                {t('promotion_component.ok')}
                            </Button>
                        </Box>
                    </div>
                </Modal>
            </div>

            <div>
                <p className={classes.pass}>{t('promotion_component.promotion')}</p>
            </div>

            {warning || promotionState.error ? (
                <p style={{ color: 'red' }}>
                    {errorMessage || promotionState.error.data.error}
                </p>
            ) : null}

            <Box display="flex" style={{ marginTop: '3rem', padding: '0 2rem' }}>
                <Box flex={3}>
                    <p className={classes.name}>{t('promotion_component.boost_profile')}</p>
                    {promotionStatus ? (
                        <p style={{ color: 'green', textAlign: 'left' }}>
                            {t('promotion_component.days_left')} {promotionDaysLeft}
                        </p>
                    ) : null}
                </Box>

                <Box flex={3}>
                    <Button
                        variant="contained"
                        className={classes.button}
                        disableElevation
                        disabled={promotionStatus}
                        onClick={() => promote()}
                    >
                        {loading ? (
                            <CircularProgress size="1.5rem" color="white" />
                        ) : (
                            t('promotion_component.boost')
                        )}
                    </Button>
                </Box>
            </Box>

            <Box display="flex" style={{ marginTop: '3rem' }}></Box>
        </div>
    );
}
