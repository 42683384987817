import { formatAvailabilityTime } from '../../../common/helpers/formatAvailabilityTime';
import { useUpdateScheduleMutation } from '../../../../app/services/schedule';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../../app/slices/auth.slice';

export const useAvailability = ({ days, tutorId, schedule }) => {
  const [updateSchedule, availabilityState] = useUpdateScheduleMutation();

  const handleSaveAvailability = async () => {
    const payload = days.reduce((acc, [day, { isAvailable, slots }]) => {
      const formattedSlots = slots.map(({ startTime, endTime }) => ({
        from: formatAvailabilityTime(startTime),
        to: formatAvailabilityTime(endTime),
      }));

      acc[day] = isAvailable ? formattedSlots : [];

      return acc;
    }, []);

    return updateSchedule({
      tutorId,
      scheduleId: schedule,
      ...payload,
    }).unwrap();
  };

  return { ...availabilityState, handleSaveAvailability };
};
