export const ValidationMessages = {
  //COMMON
  required: 'This field is required',
  minValue: (val, name = 'value') => `Min ${name} is ${val}`,
  maxValue: (val, name = 'value') => `Max ${name} is ${val}`,

  minLength: (val) => `Min symbols length ${val}`,
  maxLength: (val) => `Max symbols length ${val}`,

  //CUSTOM
  notEnoughMoney: 'Not enough money',
  invalidAmount: 'Amount is invalid',
  invalidCard: 'Card number is invalid',
};
