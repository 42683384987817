import { callAPI } from '../services/axios';

export const getTutorLessonApi = async (data) => {
  const res = callAPI({
    url: process.env.REACT_APP_TUTOR_LESSONS,
    method: 'get',
    data,
  });
  return res;
};

export const scheduleLessonApi = async (data) => {
  const res = callAPI({
    url: `${process.env.REACT_APP_TUTOR_LESSON_REQUEST}/${data.lessonId}`,
    method: 'put',
    data: data,
  });
  return res;
};
export const getScheduleApi = async (data) => {
  const res = callAPI({
    url: process.env.REACT_APP_TUTOR_GET_SCHEDULE,
    method: 'get',
    data: data,
  });
  return res;
};
