import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Logo from '../../../../assets/icons/bus-logo-dark.svg';
import BusLogo from '../../../../assets/images/logo/BusLogo.png';
import MenuIcon from '@material-ui/icons/Menu';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { ListItem, Box, List, Button, Drawer } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Routes } from '../../../../router/routes';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../../app/slices/auth.slice';
import useLogout from '../../../../hooks/useLogout';
import LogoLink from '../LogoLink';
import useTutorLinks from '../../../../hooks/useTutorLinks';
import { UserRoles } from '../../constants/user-roles';

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  iconColor: {
    color: '#8A959E',
  },
  iconColor2: {
    color: '#E9E6E3',
  },
  allmenus: {
    justifyContent: 'space-between',

    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  logo2: {
    width: 30,
    height: 30,
  },
  list: {
    [theme.breakpoints.down('sm')]: {
      width: 250,
      height: '100%',
    },
  },

  showbutton: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      alignItems: 'center',
    },
  },
}));

export default function PrimarySearchAppBar() {
  const classes = useStyles();

  const user = useSelector(selectUser);

  const [open, setOpen] = React.useState(false);
  const { t } = useTranslation('component/navigation');

  const handleLogout = useLogout();

  const tutorLinks = useTutorLinks();

  const list = () => (
    <div className={classes.list}>
      <List>
        <ListItem>
          <LogoLink />
        </ListItem>

        {!user && (
          <>
            <ListItem>
              {' '}
              <Link
                class={classes.navLinks}
                style={{ width: '100%' }}
                to="/becomeStudent"
              >
                <Button
                  style={{
                    backgroundColor: '#ffb600',
                    width: '100%',
                    color: 'white',
                  }}
                >
                  {t('top_nav.sign_up')}
                </Button>
              </Link>
            </ListItem>

            <ListItem>
              {' '}
              <Link
                class={classes.navLinks}
                style={{ width: '100%' }}
                to="/login"
              >
                <Button
                  style={{
                    backgroundColor: '#ffb600',
                    width: '100%',
                    color: 'white',
                  }}
                >
                  {t('top_nav.login')}
                </Button>
              </Link>
            </ListItem>
          </>
        )}

        {!user?.role && (
          <>
            <ListItem>
              {' '}
              <Link
                class={classes.navLinks}
                style={{ width: '100%' }}
                to="/requestTutorHome"
              >
                <Button
                  style={{
                    backgroundColor: '#ffb600',
                    width: '100%',
                    color: 'white',
                  }}
                >
                  {' '}
                  {t('top_nav.find_tutor')}
                </Button>
              </Link>
            </ListItem>

            <ListItem>
              {' '}
              <Link
                class={classes.navLinks}
                style={{ width: '100%' }}
                to="/becometutor"
              >
                <Button
                  style={{
                    backgroundColor: '#ffb600',
                    width: '100%',
                    color: 'white',
                  }}
                >
                  {' '}
                  {t('top_nav.become_a_teacher')}
                </Button>
              </Link>
            </ListItem>
          </>
        )}

        {user?.role === UserRoles.Tutor &&
          tutorLinks.map(({ link, name }) => (
            <ListItem>
              <Link
                key={link}
                class={classes.navLinks}
                style={{ width: '100%' }}
                to={link}
              >
                <Button
                  style={{
                    backgroundColor: '#ffb600',
                    width: '100%',
                    color: 'white',
                  }}
                >
                  {name}
                </Button>
              </Link>
            </ListItem>
          ))}
      </List>
    </div>
  );

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setOpen(open);
  };

  return (
    <div>
      <Drawer anchor={'left'} open={open} onClose={toggleDrawer('left', false)}>
        {list()}
      </Drawer>

      <AppBar position="static" color="transparent">
        <Box className={classes.showbutton}>
          <Box
            display="flex"
            justifyContent="flex-start"
            style={{ flex: 1 }}
            pl={3}
          >
            <MenuIcon onClick={toggleDrawer('left', true)} />
          </Box>

          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            style={{ flex: 2 }}
          >
            <LogoLink />
          </Box>

          <div style={{ flex: 1 }}> </div>
        </Box>

        <Toolbar className={classes.allmenus}>
          <Link to={Routes.Home} style={{ width: 100 }}>
            <img src={Logo} alt="logo" className={classes.logo} />
          </Link>

          <div className={classes.sectionDesktop}>
            {user && (
              <ExitToAppIcon
                style={{ cursor: 'pointer' }}
                onClick={handleLogout}
              />
            )}

            <IconButton>
              {user && (
                <span
                  style={{
                    borderRadius: '50%',
                    height: '30px',
                    width: '30px',
                    textAlign: 'center',
                    background: '#FFB600',
                  }}
                >
                  {user.firstName ? user.firstName[0].toUpperCase() : 'U'}
                </span>
              )}
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
}
