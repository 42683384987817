import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import CircularProgress from "@material-ui/core/CircularProgress";
import 'bootstrap/dist/css/bootstrap.css';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './assets/fonts/AktivGrotesk.ttf';
import { Provider } from 'react-redux';
import store from './app/store';
import './i18n';

ReactDOM.render(
  <Provider store={store}>
      <Suspense
          fallback={
              <div style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100vh',
              }}>
                  <CircularProgress
                      style={{ color: '#FFB600' }}
                      size={80}
                  />
              </div>
          }
      >
          <App />
    </Suspense>
  </Provider>,
  document.getElementById('root')
);

serviceWorker.unregister();
