import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Logo from '../../../../assets/icons/bus-logo-dark.svg';
import BusLogo from '../../../../assets/images/logo/BusLogo.png';
import MenuIcon from '@material-ui/icons/Menu';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { ListItem, Box, List, Drawer } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectAccessToken } from '../../../../app/slices/auth.slice';
import useLogout from '../../../../hooks/useLogout';
import { Routes } from '../../../../router/routes';
import LogoLink from '../LogoLink';

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  iconColor: {
    color: '#8A959E',
  },
  iconColor2: {
    color: '#E9E6E3',
  },
  allmenus: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  logo2: {
    width: 30,
    height: 30,
  },
  list: {
    [theme.breakpoints.down('sm')]: {
      width: 250,
      height: '100%',
    },
  },

  showbutton: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
    },
  },
}));

export default function PrimarySearchAppBar() {
  const classes = useStyles();
  const accessToken = useSelector(selectAccessToken);
  const [open, setOpen] = React.useState(false);
  const { t } = useTranslation('component/navigation');

  const handleLogout = useLogout();

  const list = () => (
    <div className={classes.list}>
      <List>
        <ListItem>
          {' '}
          <Box display="flex" style={{ width: '100%' }}>
            <LogoLink />
          </Box>
        </ListItem>
      </List>
    </div>
  );

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setOpen(open);
  };

  return (
    <div>
      <Drawer anchor={'left'} open={open} onClose={toggleDrawer('left', false)}>
        {list('left')}
      </Drawer>
      <AppBar position="static" color="transparent">
        <Box alignItems="center" className={classes.showbutton}>
          <Box
            display="flex"
            justifyContent="flex-start"
            pl={3}
            style={{ flex: 1 }}
          >
            <MenuIcon onClick={toggleDrawer('left', true)} />{' '}
          </Box>

          <Box display="flex" justifyContent="center" style={{ flex: 2 }}>
            <LogoLink />
          </Box>

          <div style={{ flex: 1 }}> </div>
        </Box>
        <Toolbar className={classes.allmenus}>
          <Link to={Routes.Home} style={{ width: 100 }}>
            <img src={Logo} alt="logo" className={classes.logo} />
          </Link>

          <div className={classes.grow} />
          {accessToken && (
            <div className={classes.sectionDesktop}>
              <Box display="flex" alignItems="center" onClick={handleLogout}>
                <Box>
                  <ExitToAppIcon style={{ cursor: 'pointer' }} />
                </Box>
                <Box>
                  <p style={{ margin: 0 }}>{t('top_nav_student.logout')}</p>
                </Box>
              </Box>
            </div>
          )}
        </Toolbar>
      </AppBar>
    </div>
  );
}
