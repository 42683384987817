import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link, useHistory, useParams } from 'react-router-dom';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Nav from '../../common/components/Headers/topNav';
import './styles/signup.css';
import { useTranslation } from 'react-i18next';
import { getSections } from './utils/getSetctions';
import { Box } from '@material-ui/core';
import { Routes } from '../../../router/routes';
import useLogout from '../../../hooks/useLogout';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#EDEFF0',
    minHeight: '100vh',
    paddingBottom: '1rem',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  stepper: {
    height: 59.89,
    overflowX: 'auto',
    overflowY: 'hidden',
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  stepper1: {
    height: 0,
    margin: 0,
    padding: 0,
    width: '70%',
  },
}));

function getSteps() {
  return ['About', 'Photo', 'Description', 'Video', 'Availability', 'Resume'];
}

export default function HorizontalLinearStepper() {
  const classes = useStyles();
  const history = useHistory();
  const logout = useLogout();

  const { id: tutorId } = useParams<{ id: string }>();
  const [activeStep, setActiveStep] = React.useState(0);
  const [avatar, setAvatar] = React.useState('');
  const [skipped, setSkipped] = React.useState(new Set());
  const { t } = useTranslation('page/sign_up');
  const steps = getSteps();

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const setAvtarImage = (av) => {
    setAvatar(av);
  };

  const Sections = getSections({
    avatar,
    setAvtarImage,
    tutorId,
    handleBack,
    handleNext,
  });

  return (
    <>
      <Nav />

      <div className={classes.root}>
        <Grid
          container
          style={{ backgroundColor: '#384047' }}
          className={classes.stepper}
        >
          <Grid item sm={2}></Grid>

          <Grid item sm={8}>
            <Stepper
              activeStep={activeStep}
              className={classes.stepper1}
              style={{ backgroundColor: '#384047' }}
            >
              {steps.map((label) => {
                return (
                  <Step key={label}>
                    <StepLabel
                      StepIconProps={{
                        classes: {
                          root: classes.MuiStepIconRoot,
                          active: classes.MuiStepIconRoot,
                        },
                      }}
                    >
                      <p style={{ color: '#FFFFFF', marginTop: 15 }}>
                        {t(`${label.toLowerCase()}_component.title`)}
                      </p>
                    </StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          </Grid>
        </Grid>

        <div>
          {activeStep === steps.length ? (
            <Box mt={5}>
              <Typography className={classes.instructions}>
                {t('created_profile_tutor')}
              </Typography>
              <Typography className={classes.instructions}>
                {t('students_can_see_your_profile')}
              </Typography>
              <Typography className={classes.instructions}>
                <Link
                  onClick={() => {
                    logout();
                    setTimeout(() => history.push(Routes.Login), 0);
                  }}
                >
                  {t('go_to_login')}
                </Link>
              </Typography>
            </Box>
          ) : (
            <div>
              <Typography className={classes.instructions}>
                {Sections[activeStep]}
              </Typography>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
