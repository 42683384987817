import React from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import PaperBlock from '../../components/PaperBlock';
import Box from '@material-ui/core/Box';
import { ReactComponent as Star } from '../../../../assets/icons/star.svg';
import ReviewItem from './ReviewItem';

const Feedbacks = ({ rating, reviews }) => {
  const { t } = useTranslation('page/single_tutor');

  return (
    <PaperBlock>
      <Box alignItems="center" display="flex" style={{ gap: '26px' }}>
        <Box
          width="fit-content"
          display="flex"
          alignItems="center"
          style={{ border: '2px solid #ffb600' }}
          borderRadius="1px"
          p="8px 16px"
        >
          <Star />

          <Typography style={{ fontSize: '21px', fontWeight: 500 }}>
            {Number(rating).toFixed(1)}
          </Typography>
        </Box>

        <Typography style={{ fontSize: '21px', color: '#384047' }}>
          {reviews.length} {t('reviews')}
        </Typography>
      </Box>

      <Box mt={3}>
        {reviews.length ? (
          reviews.map((review) => <ReviewItem key={review._id} {...review} />)
        ) : (
          <p>{t('tutor_review_component.no_rating_yet')}</p>
        )}
      </Box>
    </PaperBlock>
  );
};

export default Feedbacks;
