import { makeStyles } from '@material-ui/core';

export const TutorCardStyle = makeStyles(() => ({
  card: {
    boxShadow: '0 0 20px rgba(0, 0, 0, 0.03)',
    borderRadius: '10px',
    backgroundColor: '#ffffff',
    margin: '0.688rem 0.5rem',
    padding: '1.2rem 1rem 1.1rem',
  },
  avatarWrap: {
    position: 'relative',
    width: '100%',
    maxWidth: '225px',
    display: 'inline-block',
  },
  avatar: {
    width: '100%',
    height: '220px',
    borderRadius: '5px',
    objectFit: 'cover',
  },
  avatarPromoted: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    maxWidth: '225px',
    backgroundColor: 'rgba(4, 167, 119, 0.7)',
    color: 'white',
    padding: '0.5rem',
    boxSizing: 'border-box',
    borderRadius: '0 0 5px 5px',
  },
  nameText: {
    color: '#384047',
    fontSize: '1.25rem',
    fontWeight: 500,
  },
  languageText: {
    color: '#808589',
  },
  lessonInfoNativeText: {
    color: '#ffb600',
    backgroundColor: '#fff4d9',
    padding: '0.25rem',
    marginLeft: '0.5rem',
  },
  headlineText: {
    color: '#52595f',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'initial',
    display: '-webkit-box',
    WebkitLineClamp: '1',
    WebkitBoxOrient: 'vertical',
  },
  descriptionText: {
    color: '#777d82',
    fontSize: '0.938rem',
    letterSpacing: '0.26px',
    lineHeight: '19px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'initial',
    display: '-webkit-box',
    WebkitLineClamp: '1',
    wordBreak: 'break-all',
    WebkitBoxOrient: 'vertical',
  },
  starCount: {
    color: '#384047',
    fontSize: '1.438rem',
    fontWeight: 500,
  },
  priceCount: {
    color: '#384047',
    fontSize: '1.438rem',
  },
  ratingCount: {
    color: '#384047',
    fontWeight: 500,
  },
  starIcon: {
    color: '#ffc107',
    fontSize: '1.5rem',
    marginRight: '0.3rem',
    verticalAlign: 'text-top',
  },
  ratingText: {
    color: '#9c9f9e',
    fontSize: '0.938rem',
    fontWeight: 500,
  },
  reviewText: {
    color: '#9c9f9e',
    fontSize: '0.938rem',
  },
  btnDetail: {
    width: '100%',
    borderRadius: '3px',
    padding: '0.75rem',
    backgroundColor: '#ffb600',
    color: '#ffffff',
    fontSize: '0.875rem',
    fontWeight: 500,
    '&:hover': {
      backgroundColor: '#e3a101',
      color: '#ffffff',
    },
  },
  btnSendMessage: {
    width: '100%',
    borderRadius: '3px',
    padding: '0.75rem',
    backgroundColor: '#ffffff',
    border: '1px solid #ffb600',
    color: '#ffb600',
    fontSize: '0.875rem',
    fontWeight: 500,
    '&:hover': {
      backgroundColor: '#fff3db',
    },
  },
}));
