import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import DirectionsIcon from '@material-ui/icons/Directions';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    borderRadius: 0,
    borderTop: '2px solid #e8eaeb',
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));

export default function CustomizedInputBase({ onSend, onChange }) {
  const classes = useStyles();
  const { t } = useTranslation('component/send_input');
  const [message, setMessage] = useState('');

  const onMsgSend = () => {
    onSend(message);
    setMessage('');
  };

  const _handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      if (message.trim().length) {
        onSend(message);
        setMessage('');
      }
    }
  };

  const handleChange = (e) => {
    setMessage(e.target.value);
    onChange(e.target.value);
  };

  return (
    <Paper component="form" className={classes.root}>
      <InputBase
        className={classes.input}
        value={message}
        onChange={handleChange}
        placeholder={t('write_message_here')}
        onKeyDown={_handleKeyDown}
      />

      <IconButton
        type="submit"
        className={classes.iconButton}
        aria-label="search"
      ></IconButton>

      <Divider className={classes.divider} orientation="vertical" />

      <IconButton
        color="primary"
        className={classes.iconButton}
        aria-label="directions"
        disabled={!message.trim().length}
        onClick={onMsgSend}
      >
        <DirectionsIcon />
      </IconButton>
    </Paper>
  );
}
