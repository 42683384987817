import { call, takeLatest, put, all, fork } from 'redux-saga/effects';

import {
  updateAbout,
  updateAboutSuccesss,
  updateAboutError,
  updateImage,
  updateImageSuccesss,
  updateImageError,
  getTutorProfile,
  getTutorProfileSuccesss,
  getTutorProfileError,
} from '../slices/tutorInformation.slice';

import {
  UPDATE_ABOUT,
  UPDATE_IMAGE,
  GET_TUTOR_PROFILE,
} from '../sagaActions';

import {
  updateAboutApi,
  updateImageApi,
  getTutorProfileApi,
} from '../../API/tutorInformationApi';

//get tutor profile
export function* getTutorProfileSaga(action) {
  try {
    yield put(getTutorProfile());
    const result = yield call(() => getTutorProfileApi(action.payload));
    console.log('REsult profile', result);
    yield put(getTutorProfileSuccesss(result.data));
    // localStorage.setItem('yellowTutor', JSON.stringify(result.data.data));
  } catch (e) {
    yield put(getTutorProfileError(e));
  }
}
export function* watchGetTutorProfileSaga() {
  yield takeLatest(GET_TUTOR_PROFILE, getTutorProfileSaga);
}
//update about
export function* updateAboutSaga(action) {
  try {
    yield put(updateAbout());
    const result = yield call(() => updateAboutApi(action.payload));
    console.log('REsult of update about', result);
    yield put(updateAboutSuccesss(result.data));
  } catch (e) {
    yield put(updateAboutError(e));
  }
}
export function* watchUpdateAboutSaga() {
  yield takeLatest(UPDATE_ABOUT, updateAboutSaga);
}

//update image
export function* updateImageSaga(action) {
  try {
    yield put(updateImage());
    const result = yield call(() => updateImageApi(action.payload));
    console.log('REsult of update about', result);
    yield put(updateImageSuccesss(result.data));
  } catch (e) {
    yield put(updateImageError(e));
  }
}
export function* watchUpdateImageSaga() {
  yield takeLatest(UPDATE_IMAGE, updateImageSaga);
}

export default function* rootSaga() {
  yield all([
    fork(watchUpdateAboutSaga),
    fork(watchUpdateImageSaga),
    fork(watchGetTutorProfileSaga),
  ]);
}
