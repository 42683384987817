import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import People from '../../../../assets/images/people.png';
import { ReactComponent as AppStore } from '../../../../assets/icons/appstore.svg';
import { ReactComponent as GooglePlay } from '../../../../assets/icons/googleplay.svg';
import { useTranslation } from 'react-i18next';

const Apps = () => {
  const { t } = useTranslation('page/landing');

  return (
    <Grid container>
      <Grid xs={10} style={{ margin: 'auto' }}>
        <Box
          mt="80px"
          borderRadius="30px"
          style={{
            background: 'rgba(255, 182, 0, 0.70)',
            padding: '30px 92px 30px 110px',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Box mt="15px">
            <Typography
              style={{ fontSize: '40px', fontWeight: 700, textAlign: 'left' }}
            >
              {t('app')} <span style={{ color: 'white' }}>Yellow</span> School{' '}
              <br /> {t('already_accessable')}{' '}
              <span style={{ color: 'white' }}>IOS</span> {t('and')}{' '}
              <span style={{ color: 'white' }}>Android</span>
            </Typography>

            <Box mt="48px" display="flex" style={{ gap: '24px' }}>
              <Box>
                <AppStore />
              </Box>

              <Box>
                <GooglePlay />
              </Box>
            </Box>
          </Box>

          <Box>
            <img src={People} style={{ width: 360 }} />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Apps;
