import React from 'react';
import { Grid, Box, Typography, TablePagination } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import AppBar from '@material-ui/core/AppBar';
import TutorCard from './components/TutorCard';
import Footer from '../common/components/Footer';
import { RequestTutorHomeStyle } from './styles/RequestTutorHomeStyle';
import { useTranslation } from 'react-i18next';
import LoginToolBar from './components/LoginToolBar';
import { useSelector } from 'react-redux';
import { selectUser } from '../../app/slices/auth.slice';

import FiltersBar from './components/FiltersBar';
import useTutors from './hooks/useTutors';
import usePagination from '../../hooks/usePagination';

export default function RequestTutorHome() {
  const user = useSelector(selectUser);
  const classes = RequestTutorHomeStyle();
  const { t } = useTranslation('page/request_tutor_home');

  const { page, rowsPerPage, handleChangePage, handleChangeRowsPerPage } =
    usePagination();

  const { tutors, pagination, isLoading, isFetching } = useTutors({
    page,
    rowsPerPage,
  });

  return (
    <div style={{ position: 'absolute', width: '100%' }}>
      <AppBar position="static" color="transparent">
        <LoginToolBar />
      </AppBar>

      <FiltersBar />

      <Grid container className={classes.block} justifyContent="center">
        <Grid item xs={12} lg={10}>
          {isLoading || isFetching ? (
            <CircularProgress className={classes.progress} />
          ) : null}

          <Box my={3}>
            <Typography className={classes.title} align="left">
              {!isLoading && !tutors?.length
                ? t('no_teacher_found')
                : t('find_your_ideal_tutor')}
            </Typography>
          </Box>
        </Grid>

        <Grid container item xs={12} lg={10}>
          <Grid item xs={12} md={12}>
            {tutors &&
              tutors.map((tutor, index) => (
                <TutorCard
                  key={index}
                  tutor={tutor}
                  userId={user?.userId}
                ></TutorCard>
              ))}

            {!tutors?.length ? <p>{t('no_tutors_found')}</p> : null}
          </Grid>

          <Box display="flex" width="100%" justifyContent="center">
            <TablePagination
              rowsPerPageOptions={[]}
              component="div"
              count={pagination?.totalCount}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Box>
        </Grid>
      </Grid>

      <Footer />
    </div>
  );
}
