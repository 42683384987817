import { useGetTutorFilterDetailsQuery } from '../../../app/services/tutor.api';

const UseFilters = () => {
  const filterDetailsQuery = useGetTutorFilterDetailsQuery(
    { exists: true },
    { refetchOnMountOrArgChange: true }
  );

  const {
    subjects = {},
    nativeLanguages = {},
    countriesOrigin = {},
    pricePerHour = {},
  } = filterDetailsQuery.data || {};

  return {
    subjects,
    nativeLanguages,
    countriesOrigin,
    pricePerHour,
  };
};

export default UseFilters;
