import { call, takeLatest, put, all, fork } from 'redux-saga/effects';

import {
  getTutorLessons,
  getTutorLessonsSuccesss,
  getTutorLessonsError,
  scheduleLesson,
  scheduleLessonSuccess,
  scheduleLessonError,
  getSchedule,
  getScheduleSuccess,
  getScheduleError,
} from '../slices/tutorLesson.slice';

import {
  GET_TUTOR_LESSON,
  SCHEDULE_LESSON,
  GET_SCHEDULE,
} from '../sagaActions';

import {
  getTutorLessonApi,
  scheduleLessonApi,
  getScheduleApi,
} from '../../API/tutorLessonApi';

//get tutor lesson
export function* getTutorLessonSaga(action) {
  try {
    yield put(getTutorLessons());
    const result = yield call(() => getTutorLessonApi(action.payload));
    console.log('REsult lesson', result);
    if (result.status === 200) {
      yield put(getTutorLessonsSuccesss(result.data));
    } else {
      yield put(getTutorLessonsError(result.data));
    }
  } catch (e) {
    console.log(e);
  }
}
export function* watchGetTutorLessonSaga() {
  yield takeLatest(GET_TUTOR_LESSON, getTutorLessonSaga);
}

//schedule lesson
export function* scheduleLessonSaga(action) {
  try {
    yield put(scheduleLesson());
    const result = yield call(() => scheduleLessonApi(action.payload));
    console.log('REsult lesson schedule', result);
    if (result.status === 200) {
      yield put(scheduleLessonSuccess(result.data));
    } else {
      yield put(scheduleLessonError(result.data));
    }
  } catch (e) {
    yield put(scheduleLessonError(e));
  }
}
export function* watchScheduleLessonSaga() {
  yield takeLatest(SCHEDULE_LESSON, scheduleLessonSaga);
}

//get schedule
export function* getScheduleSaga(action) {
  try {
    yield put(getSchedule());
    const result = yield call(() => getScheduleApi(action.payload));
    console.log('REsult get schedule', result);
    if (result.status === 200) {
      yield put(getScheduleSuccess(result.data));
    } else {
      yield put(getScheduleError(result.data));
    }
  } catch (e) {
    yield put(getScheduleError(e));
  }
}
export function* watchGetScheduleSaga() {
  yield takeLatest(GET_SCHEDULE, getScheduleSaga);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetTutorLessonSaga),
    fork(watchScheduleLessonSaga),
    fork(watchGetScheduleSaga),
  ]);
}
