import React, { useEffect } from 'react';
import TutorDashboard from '../modules/dashboard';
import {
  BrowserRouter,
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from 'react-router-dom';
import LandingPage from '../modules/landing-page';
import ResetPassword from '../modules/auth/reset-password';
import ConfirmEmail from '../modules/auth/confirm-email/confirmEmail';
import ConfirmEmailTutor from '../modules/auth/confirm-email/confirmTutorEmail';
import RequestTutorHome from '../modules/tutor-request-home';
import TutorPage from '../modules/tutor-page';
import BecomeTutorPage from '../modules/auth/become-tutor';
import BecomeStudentPage from '../modules/auth/become-student';
import ResetPasswordSuccess from '../modules/auth/reset-password-success';
import Settings from '../modules/settings';
import PaymentHistory from '../modules/payment-history';
import Passwords from '../modules/passwords';
import SignUp from '../modules/auth/sign-up';
import Login from '../modules/auth/login';
import TutorMyLessons from '../modules/tutor-my-lessons';
import LessonStudentPage from '../modules/student-my-lessons';
import Chats from '../modules/chats';
import TutorCalendar from '../modules/tutor-calendar';
import InviteFriends from '../modules/invite-friends';
import TutorAvailabilityPage from '../modules/tutor-schedule';
import TutorWithdrawalRequests from '../modules/tutor-withdrawal-requests';

import ProtectedRoute from './protected';
import { Routes } from './routes';
import PublicRoute from './public';
import { UserRoles } from '../modules/common/constants/user-roles';
import MeetingRoom from '../modules/meeting-room';

const Router = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path={Routes.Home} component={LandingPage} />
        <Route
          exact
          path={`${Routes.MeetingRoom}/:id`}
          component={MeetingRoom}
        />

        <PublicRoute exact path={Routes.BecomeTutor}>
          <BecomeTutorPage />
        </PublicRoute>

        <PublicRoute exact path={Routes.BecomeStudent}>
          <BecomeStudentPage />
        </PublicRoute>

        {/*AUTH*/}

        <PublicRoute exact path={Routes.Login}>
          <Login />
        </PublicRoute>

        <ProtectedRoute exact path={`${Routes.SignUp}/:id`}>
          <SignUp />
        </ProtectedRoute>

        <PublicRoute exact path={`${Routes.ConfirmEmail}/:id`}>
          <ConfirmEmail />
        </PublicRoute>

        <Route exact path={`${Routes.ConfirmEmailTutor}/:id/:tutorId`}>
          <ConfirmEmailTutor />
        </Route>

        <Route
          exact
          path={`${Routes.ResetPassword}/:id`}
          component={ResetPassword}
        />

        <Route
          exact
          path={Routes.ResetPasswordSuccess}
          component={ResetPasswordSuccess}
        />

        {/*TUTOR*/}

        <ProtectedRoute
          exact
          requireRole={UserRoles.Tutor}
          path={Routes.Dashboard}
        >
          <TutorDashboard />
        </ProtectedRoute>

        <ProtectedRoute
          exact
          requireRole={UserRoles.Tutor}
          path={Routes.TutorLessons}
        >
          <TutorMyLessons />
        </ProtectedRoute>

        <ProtectedRoute
          exact
          requireRole={UserRoles.Tutor}
          path={Routes.TutorMessages}
        >
          <Chats />
        </ProtectedRoute>

        <ProtectedRoute
          exact
          requireRole={UserRoles.Tutor}
          path={Routes.TutorCalendar}
        >
          <TutorCalendar />
        </ProtectedRoute>

        <ProtectedRoute
          exact
          requireRole={UserRoles.Tutor}
          path={Routes.TutorAvailability}
        >
          <TutorAvailabilityPage />
        </ProtectedRoute>

        <ProtectedRoute
          exact
          requireRole={UserRoles.Tutor}
          path={Routes.TutorWithdrawalRequests}
        >
          <TutorWithdrawalRequests />
        </ProtectedRoute>

        <Route exact path={`${Routes.TutorPage}/:id`} component={TutorPage} />

        {/*STUDENT*/}

        <ProtectedRoute
          exact
          requireRole={UserRoles.Student}
          path={Routes.StudentLessons}
        >
          <LessonStudentPage />
        </ProtectedRoute>

        <ProtectedRoute
          exact
          requireRole={UserRoles.Student}
          path={Routes.StudentMessages}
        >
          <Chats />
        </ProtectedRoute>

        <Route
          exact
          component={RequestTutorHome}
          path={Routes.RequestTutorHome}
        />

        {/*COMMON*/}

        <ProtectedRoute exact path={Routes.Settings}>
          <Settings />
        </ProtectedRoute>

        <ProtectedRoute exact path={Routes.PaymentHistory}>
          <PaymentHistory />
        </ProtectedRoute>

        <ProtectedRoute exact path={Routes.Passwords}>
          <Passwords />
        </ProtectedRoute>

        <ProtectedRoute exact path={Routes.InviteFriends}>
          <InviteFriends />
        </ProtectedRoute>

        <Redirect exact path="*" to="/" />
      </Switch>
    </BrowserRouter>
  );
};

export default Router;
