import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Button } from '@material-ui/core';
import StatsBlock from './components/StatsBlock';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Letter } from '../../../../assets/icons/letter.svg';
import { Routes } from '../../../../router/routes';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../../app/slices/auth.slice';
import { useHistory } from 'react-router-dom';
import convertToEmbedUrl from '../../../common/helpers/convertToEmbedUrl';
import { useCreateOrGetChatMutation} from '../../../../app/services/chat.api';

const useStyles = makeStyles((theme) => ({
  root: {
    ...theme.mixins.paper,
    width: '100%',
  },
}));

const PreviewBanner = ({ userId, video, stats }) => {
  const { root } = useStyles();
  const history = useHistory();
  const { t } = useTranslation('page/single_tutor');
  const [ createOrGetChat ] = useCreateOrGetChatMutation();
  const user = useSelector(selectUser);

  const handleCreateOrGetChat = async () => {
    try {
      if (!user) {
        return history.push(Routes.Login + '?as=student');
      }

      const result = await createOrGetChat({ userId }).unwrap();
      history.push(Routes.StudentMessages, { state: result });
    } catch (err) { /* empty */ }
  };

  return (
    <Box className={root}>
      {video &&
        (video.isPasted ? (
          <iframe
            width="100%"
            height="235"
            src={convertToEmbedUrl(video.url)}
          />
        ) : (
          <video controls src={video.url} width="100%" height="235" />
        ))}

      <Box p="28px 28px 40px">
        <StatsBlock {...stats} />

        <Box mt="32px">
          <Button
            variant="outlined"
            fullWidth
            startIcon={<Letter />}
            onClick={handleCreateOrGetChat}
          >
            {t('send_message')}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default PreviewBanner;
