function convertToEmbedUrl(url) {
  let videoId;
  const regex = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.?be)\/.+/;

  if (!regex.test(url)) {
    return url;
  }

  if (url.match(/youtu\.be/)) {
    videoId = url.split('/').pop();
  } else if (url.match(/youtube\.com\/watch/)) {
    const queryParams = url.split('?').pop();
    videoId = new URLSearchParams(queryParams).get('v');
  } else if (url.match(/youtube\.com\/embed/)) {
    videoId = url.split('/').pop();
  } else if (url.match(/youtube\.com\/v/)) {
    videoId = url.split('/').pop();
  } else if (url.match(/youtube\.com\/user/)) {
    const segments = url.split('/');
    segments.pop();
    videoId = segments.pop();
  } else if (url.match(/youtube\.com\/playlist/)) {
    const segments = url.split('/');
    const playlistId = segments.pop();
    videoId = 'videoseries?list=' + playlistId;
  } else {
    return 'https://www.example.com/error-page'; // Return a specific link for unsupported YouTube link formats
  }

  return 'https://www.youtube.com/embed/' + videoId;
}

export default convertToEmbedUrl;
