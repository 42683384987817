import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Footer from '../common/components/Footer';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Button } from '@material-ui/core';
import Nav from '../common/components/Headers/topNav';
import { useTranslation } from 'react-i18next';
import { useSendInvitationMutation } from '../../app/services/invites';
import { useSelector } from 'react-redux';
import { selectUser } from '../../app/slices/auth.slice';
import { useHistory } from 'react-router-dom';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

export default function Invite() {
  const history = useHistory();

  const useStyles = makeStyles((theme) => ({
    root: {
      paddingTop: '35px',
      backgroundColor: '#EDEFF0',
    },
    root2: {
      paddingTop: '55px',
      backgroundColor: '#EDEFF0',
    },
    paper: {
      position: 'absolute',
      width: 400,
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    linkText: {
      color: theme.palette.text.secondary,
    },
    buttonColor: {
      color: 'white',
      backgroundColor: '#FFB600',
      marginLeft: '8px',
    },
    shareButton: {
      color: 'white',
      backgroundColor: theme.palette.text.primary,
      marginLeft: '8px',
    },
  }));

  const classes = useStyles();
  const userData = useSelector(selectUser);
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);
  const [userEmail, setUserEmail] = useState('');
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState<boolean | string>(false);
  const { t } = useTranslation('page/invite_friends');

  const [sendInvitation, { isLoading: loading }] = useSendInvitationMutation();

  const onSendInvitation = async () => {
    setError(false);
    let re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (re.test(userEmail)) {
      try {
        await sendInvitation({
          email: userEmail,
          name: userData.name,
        }).unwrap();

        setOpen(true);
      } catch (e) {
        setError(true);
        setErrorMessage('Email already registered');
      }
    } else {
      setError(true);
      setErrorMessage('Email is not correct');
    }
  };

  return (
    <div>
      <Nav />
      <Grid container className={classes.root}>
        <Grid item md={1} lg={2} xl={2} sm={1}></Grid>
        <Grid item md={5} lg={4} xl={4} sm={10}>
          <img
            src="https://www.buyandship.com.sg/contents/uploads/2019/10/FriendReferral-500x261.png"
            width="350px"
            height="300px"
          />
        </Grid>

        <Grid
          item
          md={5}
          lg={4}
          xl={4}
          sm={10}
          style={{
            alignItems: 'left',
            textAlign: 'left',
            paddingRight: '10px',
          }}
        >
          <h3>{t('send_a_discount')}</h3>

          {error && <p style={{ color: 'red' }}>{errorMessage} </p>}
          <div style={{ display: 'flex', width: '80%' }}>
            <TextField
              id="outlined-secondary"
              label={t('enter_emails')}
              variant="outlined"
              color="primary"
              fullWidth
              size="small"
              onChange={(e) => setUserEmail(e.target.value)}
            />
            <Button
              size="small"
              variant="contained"
              className={classes.buttonColor}
              onClick={onSendInvitation}
              disabled={loading}
              color="primary"
            >
              {loading ? <CircularProgress /> : t('send')}
            </Button>
          </div>
          <p className="mt-2">{t('enter_a_few_emails')}</p>
        </Grid>
        <Grid item md={1} lg={2} xl={2} sm={1}></Grid>
      </Grid>
      <Grid container className={classes.root2}>
        <Grid item md={1} lg={2} xl={2} sm={1}></Grid>

        <Grid
          item
          md={4}
          lg={4}
          xl={4}
          sm={10}
          style={{
            padding: '20px',
            marginLeft: '10px',
            backgroundColor: '#EEEEE8',
            textAlign: 'left',
          }}
        ></Grid>
      </Grid>
      <Grid container className={classes.root2}>
        <Grid md={12} lg={12} xl={12} sm={12} style={{ textAlign: 'center' }}>
          <h3>{t('how_it_works')}</h3>
        </Grid>
      </Grid>
      <Grid container className={classes.root2}>
        <br />
        <Grid item md={1} lg={2} xl={2} sm={1}></Grid>
        <Grid item md={6} lg={3} xl={3} sm={10}>
          <h5>{t('you_give_a_discount')}</h5>
          <p>{t('can_invite_friends')}</p>
        </Grid>
        <Grid item md={6} lg={3} xl={3} sm={10}>
          <h5>{t('friends_use_discount')}</h5>
          <p>{t('your_friends_choose')}</p>
        </Grid>
        <Grid item md={6} lg={3} xl={3} sm={10}>
          <h5>{t('you_get_money')}</h5>
          <p>{t('friend_used_a_discount')}</p>
        </Grid>
        <Grid item md={1} lg={2} xl={2} sm={1}></Grid>
      </Grid>
      <Footer></Footer>
      <Modal
        open={open}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={modalStyle} className={classes.paper}>
          <h2 id="simple-modal-title">{t('invitation_sent')}</h2>

          <p id="simple-modal-description">
            {t('have_send_an_invitation')} {userEmail}
          </p>

          <Button
            variant="contained"
            style={{
              color: 'white',
              backgroundColor: 'green',
              marginRight: '2rem',
            }}
            onClick={() => history.push('/login')}
          >
            {t('ok')}
          </Button>
        </div>
      </Modal>
    </div>
  );
}
