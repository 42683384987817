import React, { useState } from 'react';
import { useGetTutorWithdrawalRequestsQuery } from '../../app/services/tutor.api';
import SubHeaderTutor from '../common/components/Headers/SubHeaderTutor';
import BaseLayout from '../common/layouts/BaseLayout';
import RequestsTable from './components/RequestsTable';

const TutorWithdrawalRequests = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const { data, isLoading, isFetching } = useGetTutorWithdrawalRequestsQuery(
    {
      skip: page + 1,
      limit: rowsPerPage,
    },
    { refetchOnMountOrArgChange: true }
  );
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <BaseLayout
      subheader={<SubHeaderTutor for="Withdrawal Requests" />}
      isLoading={isLoading || isFetching}
    >
      <RequestsTable
        requests={data?.data}
        total={data?.pagination?.totalCount}
        page={page}
        rowsPerPage={rowsPerPage}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </BaseLayout>
  );
};

export default TutorWithdrawalRequests;
