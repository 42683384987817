import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  updatedAbout: null,
  updatingAbout: false,
  updatingImage: false,
  updatedImage: null,
  gettingTutorProfile: false,
  tutorProfile: null,
  error: null,
};

const tutorInformationSlice = createSlice({
  name: 'tutorInformation',
  initialState,
  reducers: {
    //get tutor profile
    getTutorProfile(state) {
      state.gettingTutorProfile = true;
      state.tutorProfile = null;
      state.error = null;
    },
    getTutorProfileSuccesss(state, action) {
      state.gettingTutorProfile = false;
      state.tutorProfile = action.payload.data;
    },
    getTutorProfileError(state, action) {
      state.gettingTutorProfile = false;
      state.error = action.payload;
    },

    //update about
    updateAbout(state) {
      state.updatingAbout = true;
      state.updatedAbout = null;
      state.error = null;
    },
    updateAboutSuccesss(state, action) {
      state.updatingAbout = false;
      state.updatedAbout = action.payload;
    },
    updateAboutError(state, action) {
      state.updatingAbout = false;
      state.error = action.payload;
    },

    //update image
    updateImage(state) {
      state.updatingImage = true;
      state.updatedImage = null;
      state.error = null;
    },
    updateImageSuccesss(state, action) {
      state.updatingImage = false;
      state.updatedImage = action.payload;
    },
    updateImageError(state, action) {
      state.updatingImage = false;
      state.error = action.payload;
    },
  },
});

export const {
  updateAbout,
  updateAboutSuccesss,
  updateAboutError,
  updateImage,
  updateImageSuccesss,
  updateImageError,
  getTutorProfile,
  getTutorProfileSuccesss,
  getTutorProfileError,
} = tutorInformationSlice.actions;

export default tutorInformationSlice.reducer;
