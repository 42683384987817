import React from 'react';
import enGbLocale from '@fullcalendar/core/locales/en-gb';
import ukLocale from '@fullcalendar/core/locales/uk';
import timeGridPlugin from '@fullcalendar/timegrid';
import FullCalendar from '@fullcalendar/react';
import { useTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';
import './style.css';
import Typography from '@material-ui/core/Typography';
import interactionPlugin from '@fullcalendar/interaction';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  head: {
    padding: '20px 23px',

    [theme.breakpoints.up('md')]: {
      padding: '40px 47px',
    },
  },

  title: {
    color: '#384047',
    fontSize: '20px',

    [theme.breakpoints.up('md')]: {
      fontSize: '30px',
    },
  },
}));

const BookingTable = ({ onToggle, slots }) => {
  const classes = useStyles();

  const { t, i18n } = useTranslation('page/tutor_availability');

  const handleClick = ({ dateStr }) => {
    onToggle(transformDate(dateStr));
  };

  return (
    <Box height="100%">
      <Box className={classes.head} textAlign="left">
        <Typography className={classes.title}>
          {t('availability_time_slots')}
        </Typography>
      </Box>

      <FullCalendar
        scrollTimeReset
        locales={[enGbLocale, ukLocale]}
        locale={i18n.language === 'ua' ? 'uk' : 'en-gb'}
        timeZone="local"
        plugins={[timeGridPlugin, interactionPlugin]}
        allDaySlot={false}
        headerToolbar={false}
        events={slots}
        dayHeaderFormat={{
          weekday: 'short',
          omitCommas: true,
        }}
        slotDuration="01:00:00"
        eventColor="#ffb600"
        eventClick={(ev) => {
          handleClick({ dateStr: ev.event.startStr });
        }}
        dateClick={handleClick}
        eventContent={renderEventContent}
      />
    </Box>
  );
};

function transformDate(date) {
  const day = moment(date).locale('en').format('dddd').toLowerCase();
  const from = moment(date).format('HH:mm');
  const to = moment(date).add(1, 'hour').format('HH:mm');

  return { day, from, to };
}

function renderEventContent(info) {
  const start = moment(info.event.startStr);
  const end = start.clone().add(1, 'hour');

  return (
    <Box
      m="auto"
      display="flex"
      height="100%"
      alignItems="center"
      justifyContent="center"
      p="6px"
      style={{ cursor: 'pointer' }}
    >
      <Typography
        style={{
          color: '#ffffff',
          fontSize: '15px',
          fontWeight: 500,
          lineHeight: '15px',
          letterSpacing: '-0.5px',
        }}
      >
        {start.format('HH:ss')} - {end.format('HH:ss')}
      </Typography>
    </Box>
  );
}

export default BookingTable;
