import React from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import moment from 'moment/moment';
import Collapse from '@material-ui/core/Collapse';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

function LessonRow(props) {
  const { lesson } = props;

  const {
    studentId,
    status,
    totalHours,
    tutorId,
    lessionDate,
    startTime,
    endTime,
  } = lesson;

  const classes = useRowStyles();

  const { t } = useTranslation(['page/student_lessons_list']);

  return (
    <>
      <TableRow
        className={classes.root}
        style={{
          borderBottom: '1px solid #AAB5BC',
          borderTop: '1px solid #AAB5BC',
        }}
      >
        <TableCell></TableCell>

        <TableCell component="th" scope="row" style={{ padding: '5px' }}>
          <Box display="flex" alignItems="center" style={{ gap: '20px' }}>
            <img
              src={studentId?.imageUrl}
              style={{
                width: 53,
                height: 53,
                borderRadius: 30,
                objectFit: 'cover',
              }}
            />

            <Typography style={{ color: 'black' }}>
              {studentId?.name}
            </Typography>
          </Box>
        </TableCell>

        <TableCell>{tutorId.hourlyRate * totalHours} ₴</TableCell>

        <TableCell>
          <span>{t(`tutor_lesson_table_component.${status}`)}</span>
        </TableCell>

        <TableCell align="left" style={{ width: 150 }}>
          {moment(lessionDate).format('MMMM Do YYYY, h:mm:ss a')}
        </TableCell>

        <TableCell align="left">
          {moment(startTime).format('MMMM Do YYYY, h:mm:ss a') ===
          'Invalid date'
            ? startTime
            : moment(startTime).format('MMMM Do YYYY, h:mm:ss a')}
        </TableCell>

        <TableCell align="left">
          {moment(endTime).format('MMMM Do YYYY, h:mm:ss a') === 'Invalid date'
            ? endTime
            : moment(endTime).format('MMMM Do YYYY, h:mm:ss a')}
        </TableCell>

        <TableCell align="left"></TableCell>
      </TableRow>

      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={false} timeout="auto" unmountOnExit>
            <Box margin={1}>Further details goes here.</Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

export default LessonRow;
