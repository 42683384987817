import React, { useEffect } from 'react';
import Router from './router';
import { transitions, positions, Provider as AlertProvider } from 'react-alert';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import AlertTemplate from 'react-alert-template-basic';

import './App.css';
import axios from 'axios';
import useLogout from './hooks/useLogout';
import { CssBaseline, ThemeProvider } from '@material-ui/core';
import theme from './theme';

const options = {
  // you can also just use 'bottom center'
  position: positions.BOTTOM_CENTER,
  timeout: 5000,
  offset: '30px',
  // you can also just use 'scale'
  transition: transitions.SCALE,
};

function App() {
  const handleLogout = useLogout();

  useEffect(() => {
    // TODO remove it in the future
    axios.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        if (error.response.status === 401) {
          localStorage.clear();
          handleLogout();
        }
        return Promise.reject(error);
      }
    );
  }, []);

  return (
    <AlertProvider template={AlertTemplate} {...options}>
      <ThemeProvider theme={theme}>
        <CssBaseline />

        <div className="App" style={{ overflowX: 'hidden' }}>
          <Router />
        </div>
      </ThemeProvider>
    </AlertProvider>
  );
}

export default App;
