import React, { useRef } from 'react';
import { JaaSMeeting } from '@jitsi/react-sdk';
import { Routes } from '../../router/routes';
import { useHistory } from 'react-router-dom';

const MeetingRoom = () => {
  const apiRef = useRef();
  const history = useHistory();

  const handleApiReady = (apiObj) => {
    apiRef.current = apiObj;
  };

  const handleJitsiIFrameRef1 = (iframeRef) => {
    iframeRef.style.border = '10px solid #3d3d3d';
    iframeRef.style.background = '#3d3d3d';
    iframeRef.style.height = '100vh';
    iframeRef.style.marginBottom = '20px';
  };

  const handleReadyToClose = () => {
    history.replace(Routes.Dashboard);
  };

  const generateRoomName = () =>
    `JitsiMeetRoomNo${Math.random() * 100}-${Date.now()}`;

  const renderSpinner = () => (
    <div
      style={{
        fontFamily: 'sans-serif',
        textAlign: 'center',
      }}
    >
      Loading..
    </div>
  );

  return (
    <JaaSMeeting
      roomName={generateRoomName()}
      spinner={renderSpinner}
      configOverwrite={{
        hideConferenceSubject: false,
        startWithVideoMuted: true, // Mute video initially
        startWithAudioMuted: true, // Mute audio initially
        prejoinPageEnabled: false,
      }}
      onApiReady={(externalApi) => handleApiReady(externalApi)}
      onReadyToClose={handleReadyToClose}
      getIFrameRef={handleJitsiIFrameRef1}
      useStaging={true}
      appId=""
    />
  );
};

export default MeetingRoom;
