import React, { useState } from 'react';
import NavBar from '../../common/components/Headers/topNav';
import Modal from '@material-ui/core/Modal';
import { Box, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import ManStanding from '../../../assets/images/teacher.jpeg';
import { useHistory } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import Footer from '../../common/components/Footer';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Routes } from '../../../router/routes';
import { useCreateStudentMutation } from '../../../app/services/auth.api';
import Checkbox from '@material-ui/core/Checkbox';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  TextLabelLarge: {
    fontSize: 35,
  },
  TextLabelMedium: {
    fontSize: 35,
  },
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  threeRowsColOne: {
    fontSize: 28,
    width: '50%',
    height: '100%',
    textAlign: 'left',
  },
  smallTextLeft: { fontSize: 13, width: '60%', textAlign: 'left' },
}));

function BecomeStudentPage() {
  let history = useHistory();
  const classes = useStyles();
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('asdfsdfsd');
  const [password, setPassword] = useState('');
  const { t } = useTranslation([
    'page/become_student',
    'page/become_tutor',
    'common/common',
  ]);

  const [agree, setAgree] = useState(false);

  const [createStudent, { isLoading: loading }] = useCreateStudentMutation();

  const handleSignUp = async () => {
    setShowError(false);

    if (confirmPassword !== password) {
      setErrorMessage('Password and Confirm Password not matched');
      setShowError(true);
      return;
    }

    if (email === '' || password === '' || name === '') {
      setErrorMessage('Please fill all fields');
      setShowError(true);
      return;
    }

    try {
      await createStudent({ email, password, name }).unwrap();

      setOpen(true);
    } catch (err) {
      setErrorMessage(
        err?.data?.error ||
          'Something went wrong, make sure all data is correct'
      );
      setShowError(true);
    }
  };

  return (
    <div>
      <NavBar></NavBar>

      <Grid container style={{ marginTop: 10 }} direction="row">
        <Grid item md={3} lg={3} xl={3} sm={1} xs={1}></Grid>

        <Grid item md={6} lg={6} xl={6} sm={10} xs={10}>
          <p className={classes.TextLabelLarge}>{t('find_best_teachers')}</p>
          <Grid container>
            <Grid item md={3} xs={1}></Grid>
            <Grid item md={6} lg={6} xl={6} sm={12} xs={12}>
              {showError ? (
                <p style={{ color: 'red' }}>{errorMessage}</p>
              ) : null}
              <form style={{ marginBottom: '1rem' }}>
                <p style={{ textAlign: 'left', marginBottom: '0px' }}>
                  {t('name')}*
                </p>
                <input
                  style={{
                    width: '100%',
                    height: 45,
                    marginTop: '5px',
                  }}
                  value={name}
                  name="name"
                  onChange={(val) => setName(val.target.value)}
                  type="text"
                ></input>
                <p
                  style={{
                    textAlign: 'left',
                    marginTop: '10px',
                    marginBottom: '0px',
                  }}
                >
                  {t('email_address')}
                </p>
                <input
                  style={{ width: '100%', height: 45, marginTop: '5px' }}
                  onChange={(val) => setEmail(val.target.value)}
                  type="text"
                ></input>
                <p
                  style={{
                    textAlign: 'left',
                    marginTop: '10px',
                    marginBottom: '0px',
                  }}
                >
                  {t('password')}
                </p>
                <input
                  style={{ width: '100%', height: 45, marginTop: '5px' }}
                  onChange={(val) => setPassword(val.target.value)}
                  type="password"
                ></input>
                <p
                  style={{
                    textAlign: 'left',
                    marginTop: '10px',
                    marginBottom: '0px',
                  }}
                >
                  {t('confirm_password')}*
                </p>
                <input
                  style={{
                    width: '100%',
                    height: 45,
                    padding: 10,
                    marginTop: '5px',
                  }}
                  name="password"
                  onChange={(val) => setConfirmPassword(val.target.value)}
                  type="password"
                ></input>
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  justify="center"
                  style={{ background: '#FFB600', marginTop: '30px' }}
                >
                  <Button
                    onClick={handleSignUp}
                    disabled={loading || !agree}
                    style={{
                      height: '100%',
                      width: '100%',
                      padding: '10px',
                      background: 'none',
                      border: 'none',
                    }}
                  >
                    {loading ? <CircularProgress size={25} /> : t('sign_up')}
                  </Button>
                </Grid>
              </form>

              <p style={{ fontSize: 12, marginTop: '10px' }}>
                <Checkbox
                  color="primary"
                  value={agree}
                  onChange={(_, checked) => setAgree(checked)}
                />
                {t('by_clicking_sign_up', { ns: 'page/become_tutor' })}{' '}
                <span>
                  <a
                    href="https://drive.google.com/file/d/1kHCV6FgUXyB8lbKsTkX6HbLriI6ya8_-/view?usp=sharing"
                    target="_blank"
                    style={{ color: '#FFB600' }}
                  >
                    {t('terms_of_service', { ns: 'page/become_tutor' })}
                  </a>
                </span>{' '}
                {t('and', { ns: 'page/become_tutor' })}
                <span style={{ color: '#FFB600' }}>
                  {' '}
                  <a
                    href="https://drive.google.com/file/d/1C9UieMdrCRgVKswb4feecp0Ta6zKpbu3/view?usp=sharing"
                    target="_blank"
                    style={{ color: '#FFB600' }}
                  >
                    {t('privacy_policy', { ns: 'page/become_tutor' })}
                  </a>
                </span>
              </p>

              <Box my={2}>
                <span>
                  {t('login.already_have_account', { ns: 'common/common' })}{' '}
                  <Link to={Routes.Login} style={{ color: '#FFB600' }}>
                    {t('login.sign_in', { ns: 'common/common' })}
                  </Link>
                </span>
              </Box>
            </Grid>
            <Grid item md={3} xs={1}></Grid>
          </Grid>

          <Grid container direction="row">
            <Grid item md={2} lg={2} xl={2} sm={1} xs={1}></Grid>
            <Grid item md={8} lg={8} xl={8} sm={10} xs={10}>
              <Grid container direction="row" alignItems="center" style={{}}>
                <Grid
                  item
                  md={5}
                  lg={5}
                  xl={5}
                  sm={5}
                  xs={5}
                  style={{ display: 'flex', justifyContent: 'center' }}
                >
                  <Link to="#">
                    {/* <img style={{ margin: 10, width: "100%" }} src={logo}></img> */}
                  </Link>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={2} lg={2} xl={2} sm={1} xs={1}></Grid>
          </Grid>
        </Grid>
        <Grid item md={3} lg={3} xl={3} sm={1} xs={1}></Grid>
      </Grid>

      <Grid container style={{ marginTop: 10 }} direction="row">
        <Grid item md={1} lg={1} xl={1} sm={1} xs={1}></Grid>
        <Grid item xs={10}>
          <img style={{ width: '100%' }} src={ManStanding}></img>
          <div style={{ marginTop: 100 }}></div>
        </Grid>
        <Grid item md={1} lg={1} xl={1} sm={1} xs={1}></Grid>
      </Grid>

      <Footer></Footer>

      <Modal
        open={open}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={modalStyle} className={classes.paper}>
          <h2 id="simple-modal-title">{t('email_verification_sent')}</h2>

          <p id="simple-modal-description">{t('send_a_confirmation_email')}</p>

          <Button
            variant="contained"
            style={{
              color: 'white',
              backgroundColor: 'green',
              marginRight: '2rem',
            }}
            onClick={() => history.push('/login')}
          >
            {t('go_to_login')}
          </Button>
        </div>
      </Modal>
    </div>
  );
}

export default BecomeStudentPage;
