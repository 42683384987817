import React from 'react';
import { Grid, Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import UkrainianIcon from '../../../assets/icons/ukr-lang-icon.svg';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useTranslation } from 'react-i18next';
import { useGetSubjectsWithTutorCountQuery } from '../../../app/services/tutor.api';

const useStyles = makeStyles(() => ({
  languagesCard: {},
  languageName: {
    fontFamily: 'Roboto',
    fontSize: 32,
    fontWeight: 500,
    color: '#040916',
  },

  tutorNum: {
    fontFamily: 'Roboto',
    fontSize: '56px',
    fontWeight: 700,
    textAlign: 'left',
    color: '#040916',
  },

  tutorText: {
    fontFamily: 'Roboto',
    fontSize: '18px',
    fontWeight: 500,
    textAlign: 'left',
    color: '#040916',
  },
}));

const Icons = {
  ukrainian_language: UkrainianIcon,
};

export default function LanguagesCard() {
  const classes = useStyles();
  const { t } = useTranslation(['common/subject_list', 'page/landing']);

  const { data: subjects = {}, isLoading } =
    useGetSubjectsWithTutorCountQuery();

  return (
    <div
      style={{
        marginTop: 20,
      }}
    >
      {isLoading ? <CircularProgress style={{ color: '#ffcd02' }} /> : null}

      <Grid container justifyContent="center">
        <Grid item xs={12} md={10} style={{ margin: '1rem 0' }}>
          <div className={classes.languagesCard}>
            <Grid container style={{ flexDirection: 'column', gap: '24px' }}>
              {Object.entries(subjects).map(
                ([subjectKey, tutorsCount], _, arr) => {
                  return (
                    <Grid
                      key={subjectKey}
                      style={{
                        padding: '26px 96px 26px 48px',
                        borderRadius: '30px',

                        background: 'white',
                      }}
                      item
                      xs={12}
                      md={12}
                    >
                      <Box
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          marginLeft: '2rem',
                          alignItems: 'center',
                          gap: 40,
                          borderRadius: '30px',
                        }}
                      >
                        <img src={Icons[subjectKey]} style={{ height: 144 }} />

                        <Box>
                          <span className={classes.languageName}>
                            {t(subjectKey, { ns: 'common/subject_list' })}
                          </span>
                        </Box>

                        <Box>
                          <Typography className={classes.tutorNum}>
                            {tutorsCount}
                          </Typography>

                          <Typography className={classes.tutorText}>
                            {t('languages_card_component.tutors_count', {
                              ns: 'page/landing',
                              count: tutorsCount,
                            })}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                  );
                }
              )}
            </Grid>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
