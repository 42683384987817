import React from 'react';

const PaperBlock = ({ children }) => {
  return (
    <div
      style={{
        alignItems: 'left',
        textAlign: 'left',
        padding: '40px 30px 20px 30px',
        background: 'white',
        boxShadow: '0 0 20px rgba(0, 0, 0, 0.07)',
      }}
    >
      {children}
    </div>
  );
};

export default PaperBlock;
