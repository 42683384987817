import React, { useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import NativeSelect from '@material-ui/core/NativeSelect';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Dropzone from 'react-dropzone';
import InputBase from '@material-ui/core/InputBase';
import LanguageIcon from '@material-ui/icons/Language';
import SetAvaiblity from './setAvaiability';
import Image from '../../../../../assets/images/d.png';
import { green } from '@material-ui/core/colors';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';

const BootstrapInput = withStyles((theme) => ({
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: '#FFFFFF',
    padding: '2rem',
    borderRadius: 5,
    marginTop: '3rem',
  },
  heading1: {
    color: '#6F757B',
    fontSize: 30,
  },
  buttonNext: {
    marginTop: theme.spacing(5),
    backgroundColor: '#FFB600',
    color: 'white',
  },
  buttonBack: {
    marginTop: theme.spacing(5),
    marginRight: theme.spacing(2),
    backgroundColor: '#F8F8F8',
    color: '#FFB600',
    borderColor: '#FFB600',
    borderWidth: 1,
    borderStyle: 'solid',
  },
  p1: {
    fontSize: 25,
    padding: 0,
    margin: 0,
    color: '#6F757B',
  },
  p2: {
    fontSize: 18,
    padding: 0,
    margin: 0,
    color: '#6F757B',
  },
  p3: {
    fontSize: 18,
    padding: 0,
    margin: 0,
    color: '#6F757B',
    marginLeft: theme.spacing(1),
  },
  icon: {
    color: '#FFB600',
  },
}));
export default function Availability(props) {
  const classes = useStyles();

  const { t } = useTranslation('page/sign_up');

  return (
    <div
      style={{
        paddingBottom: '3rem',
        backgroundColor: '#EDEFF0',
      }}
    >
      <Container maxWidth="md" className={classes.container} boxShadow={3}>
        <p align="left" className={classes.p1}>
          {t('availability_component.set_timezone')}
        </p>

        <SetAvaiblity
          tutorId={props.tutorId}
          handleNext={props.handleNext}
          handleBack={props.handleBack}
        />
      </Container>
    </div>
  );
}
