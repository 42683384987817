import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Grid, Typography, Avatar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CommentPics1 from '../../../assets/images/s1.png';
import CommentPics2 from '../../../assets/images/s2.png';
import CommentPics3 from '../../../assets/images/s3.png';
import AvatarRandomGirlPng from '../../../assets/images/tutorGirl.png';
import AvatarRandomBoyPng from '../../../assets/images/commentPic.png';
import AvatarRandomManPng from '../../../assets/images/man.png';
import 'react-animated-slider/build/horizontal.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles((theme) => ({
  storyContainer: {
    margin: '30px 0',

    [theme.breakpoints.up('md')]: {
      margin: '81px 0',
    },
  },
  storyTitleWrap: {
    paddingBottom: '2rem',
  },
  storyTitle: {
    fontSize: 30,
    fontWeight: 500,
  },
  storyImg: {
    width: '100%',
    height: '482px',
    objectFit: 'cover',
    border: '2px solid #FFB600',
    borderRadius: '30px',

    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  storyCard: {
    background: 'white',
    borderRadius: 5,
    overflow: 'hidden',
    gap: '27px',
    display: 'flex',
  },
  storyCardWrap: {
    display: 'flex',
  },
  storyCardText: {
    color: 'rgba(4, 9, 22, 0.80)',
    fontSize: '24px',
    lineHeight: '43.2px',

    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
      lineHeight: '2rem',
    },
  },
  smallAvatar: {
    width: '62px !important',
    height: '62px !important',
    margin: 0,
  },
  storyAuthorText: {
    padding: '0.3rem 0 0 0.5rem',
    color: '#384047',
    fontSize: '1.1rem',
    letterSpacing: 0.72,
    textTransform: 'uppercase',
  },
  storyAuthorDescriptionText: {
    paddingLeft: '0.5rem',
    color: '#757b81',
    fontSize: '1rem',
    fontWeight: 300,
    letterSpacing: 0.34,
  },
  boldText: {
    color: '#040916',
    fontFamily: 'Roboto',
    fontSize: 40,
    fontWeight: 700,

    marginTop: 14,

    [theme.breakpoints.down('sm')]: {
      fontSize: 20,
    },

    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
  },
}));

export default function CommentSlider() {
  const classes = useStyles();
  const { t } = useTranslation('page/landing');

  const [swiper, setSwiper] = useState(null);

  const Comments = [
    {
      id: 0,
      avatar: AvatarRandomGirlPng,
      authorName: `${t('comment_slider_component.first_author_name')}`,
      authorDescription: `${t(
        'comment_slider_component.first_author_description'
      )}`,
      pic: CommentPics1,
      comment: `${t('comment_slider_component.talk_to_me_school')}`,
    },
    {
      id: 1,
      avatar: AvatarRandomBoyPng,
      authorName: `${t('comment_slider_component.second_author_name')}`,
      authorDescription: `${t(
        'comment_slider_component.second_author_description'
      )}`,
      pic: CommentPics2,
      comment: `${t('comment_slider_component.nazar_didnt_give_up')}`,
    },
    {
      id: 2,
      avatar: AvatarRandomManPng,
      authorName: `${t('comment_slider_component.third_author_name')}`,
      authorDescription: `${t(
        'comment_slider_component.third_author_description'
      )}`,
      pic: CommentPics3,
      comment: `${t('comment_slider_component.renamed_yellow_school')}`,
    },
  ];

  const [tutorFirst, tutorSecond] = t(
    'comment_slider_component.read_yellow_school_story'
  ).split('Yellow');

  return (
    <div id="about-us">
      <Grid container>
        <Grid xs={10} style={{ margin: 'auto' }}>
          <Box
            mt="80px"
            borderRadius="30px"
            style={{ background: 'white', padding: '0 85px 30px' }}
          >
            <Box pt="20px" />

            <p className={classes.boldText}>
              {tutorFirst} <span style={{ color: '#FFB600' }}>Yellow</span>{' '}
              {tutorSecond}
            </p>

            <Typography style={{ fontSize: '24px', fontWeight: 700 }}>
              {t('comment_slider_component.tutors_helped_thousands')}
            </Typography>

            <Box pt="48px" />

            <Swiper
              loop
              spaceBetween={50}
              slidesPerView={1}
              onSlideChange={() => console.log('slide change')}
              onSwiper={(swiper) => setSwiper(swiper)}
            >
              {Comments.map((comment) => (
                <SwiperSlide key={comment.id}>
                  <Box className={classes.storyCard}>
                    <Box flexBasis={543}>
                      <img
                        className={classes.storyImg}
                        src={comment.pic}
                        alt="comment"
                      />
                    </Box>

                    <Box flexBasis="540px" flex={1} mx={4} my={1} mt={4} pr={6}>
                      <Typography
                        align="left"
                        className={classes.storyCardText}
                      >
                        {comment.comment}
                      </Typography>
                    </Box>
                  </Box>
                </SwiperSlide>
              ))}
            </Swiper>

            <Box
              display="flex"
              justifyContent="flex-end"
              position="relative"
              top={-50}
              zIndex={1}
            >
              <IconButton onClick={() => swiper?.slidePrev()}>
                <svg
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_76_8969)">
                    <path
                      d="M24.16 26.5068L13.6533 16.0002L24.16 5.4935L21.3333 2.66683L8 16.0002L21.3333 29.3335L24.16 26.5068Z"
                      fill="#040916"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_76_8969">
                      <rect
                        width="32"
                        height="32"
                        fill="white"
                        transform="matrix(-1 0 0 -1 32 32)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </IconButton>

              <IconButton onClick={() => swiper?.slideNext()}>
                <svg
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_76_8972)">
                    <path
                      d="M7.84003 5.49317L18.3467 15.9998L7.84003 26.5065L10.6667 29.3332L24 15.9998L10.6667 2.6665L7.84003 5.49317Z"
                      fill="#040916"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_76_8972">
                      <rect width="32" height="32" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </IconButton>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}
