export const Routes = {
  Home: '/',
  Dashboard: '/dashboard',
  ResetPassword: '/resetpassword',
  ConfirmEmail: '/confirmemail',
  ConfirmEmailTutor: '/confirmemailtutor',
  RequestTutorHome: '/requestTutorHome',
  TutorPage: '/tutor',
  BecomeTutor: '/becomeTutor',
  BecomeStudent: '/becomeStudent',
  ResetPasswordSuccess: '/reset-password/success',
  Settings: '/settings',
  PaymentHistory: '/paymentHistory',
  Passwords: '/passwords',
  SignUp: '/signup',
  Login: '/login',
  TutorLessons: '/tutorMyLessons',
  TutorMessages: '/tutorMyMessages',
  StudentLessons: '/studentMyLessons',
  StudentMessages: '/studentMyMessages',
  TutorCalendar: '/tutorCalendar',
  InviteFriends: '/inviteFriends',
  TutorAvailability: '/tutorAvailability',
  TutorWithdrawalRequests: '/tutor-withdrawal-requests',
  MeetingRoom: '/meeting-room',
};
