import React, { useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';

import { ReactComponent as BusLogo } from '../../../../../assets/icons/bus-logo.svg';
import { Routes } from '../../../../../router/routes';
import StyledButton from './StyledButton';
import { selectUser } from '../../../../../app/slices/auth.slice';
import useRoleLinks from '../hooks/useRoleLinks';
import MobileMenu from './MobileMenu';
import LanguageSelector from './LanguageSelector';
import LogoLink from '../../../../common/components/LogoLink';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

const useStyles = makeStyles((theme) => ({
  headerWrapper: {
    ...theme.mixins.rowStandard,
    zIndex: 5,
    position: 'relative',
  },

  buttonsWrapper: {
    ...theme.mixins.rowStandard,
    display: 'none',
    gap: '40px',

    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },

  menuButton: {
    display: 'block',

    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },

  becomeTeacherLink: {
    color: 'white',
    fontSize: '16px',
    fontWeight: 500,
  },

  authButtons: {
    ...theme.mixins.rowStandard,
    gap: '20px',
  },
}));

const Header = () => {
  const history = useHistory();

  const {
    buttonsWrapper,
    headerWrapper,
    becomeTeacherLink,
    authButtons,
    menuButton,
  } = useStyles();

  const user = useSelector(selectUser);

  const { t } = useTranslation(['component/navigation']);
  const [isMenuOpen, setMenu] = useState(false);

  const links = useRoleLinks();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleFindTutorClick = () => history.push(Routes.RequestTutorHome);

  const handleLogInClick = (params) => () => {
    handleClose();
    history.push(`${Routes.Login}/?as=${params}`);
  };

  return (
    <>
      <Box className={headerWrapper}>
        <LogoLink mode="dark" />

        <IconButton className={menuButton} onClick={() => setMenu(true)}>
          <MenuIcon style={{ color: 'white' }} />
        </IconButton>

        <Box className={buttonsWrapper}>
          <Link
            component="a"
            to={Routes.Home + '#how-it-works'}
            className={becomeTeacherLink}
          >
            {t('landing_page_nav.how_it_works')}
          </Link>

          <Link
            component="a"
            to={Routes.Home + '#about-us'}
            className={becomeTeacherLink}
          >
            {t('landing_page_nav.about_us')}
          </Link>

          {user ? (
            links.map(({ href, title, onClick }) => (
              <Link
                key={href}
                component="a"
                to={onClick ? null : href}
                onClick={onClick}
                className={becomeTeacherLink}
              >
                {title}
              </Link>
            ))
          ) : (
            <>
              <Link to={Routes.BecomeTutor} className={becomeTeacherLink}>
                {t('landing_page_nav.become_a_teacher')}
              </Link>

              <Box className={authButtons}>
                <StyledButton
                  variant="filled"
                  style={{ fontWeight: 500 }}
                  onClick={handleFindTutorClick}
                >
                  {t('landing_page_nav.find_tutor')}
                </StyledButton>

                <StyledButton onClick={handleClick}>
                  <Box display="flex" style={{ gap: '5px' }}>
                    <Typography>{t('landing_page_nav.login')}</Typography>

                    <Box>
                      {anchorEl ? (
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M15 12.5L10 7.5L5 12.5"
                            stroke="#FFB600"
                            stroke-width="1.67"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      ) : (
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M5 7.5L10 12.5L15 7.5"
                            stroke="#FFB600"
                            stroke-width="1.67"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      )}
                    </Box>
                  </Box>
                </StyledButton>

                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  style={{ marginTop: '60px' }}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                    style: { background: '#FFB600', color: 'white' },
                  }}
                >
                  <MenuItem
                    onClick={handleLogInClick('student')}
                    style={{ background: '#FFB600', color: 'white' }}
                  >
                    {t('landing_page_nav.login_as_student')}
                  </MenuItem>

                  <MenuItem
                    onClick={handleLogInClick('tutor')}
                    style={{ background: '#FFB600', color: 'white' }}
                  >
                    {t('landing_page_nav.login_as_tutor')}
                  </MenuItem>
                </Menu>
              </Box>
            </>
          )}

          <LanguageSelector />
        </Box>
      </Box>

      <MobileMenu open={isMenuOpen} onClose={() => setMenu(false)} />
    </>
  );
};

export default Header;
