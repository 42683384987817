import { makeStyles } from '@material-ui/core';

export const RequestTutorHomeStyle = makeStyles(() => ({
  block: {
    background: '#edeff0',
    minHeight: '65vh',
    paddingBottom: '20px',
  },
  progress: {
    color: '#FFB600',
    marginTop: '2rem',
  },
  title: {
    color: '#6f757b',
    fontSize: '2rem',
    letterSpacing: '0.15px',
  },
  findText: {
    color: '#6f757b',
    fontSize: '1.125rem',
    letterSpacing: '0.09px',
  },
}));
