import { all, fork } from 'redux-saga/effects';
import tutorInformationSaga from './sagas/tutorInformation.saga';
import tutorLessonSaga from './sagas/tutorLesson.saga';
import tutorAvailabilitySaga from './sagas/availability.saga';

export default function* rootSaga() {
  yield all([
    fork(tutorInformationSaga),
    fork(tutorLessonSaga),
    fork(tutorAvailabilitySaga),
  ]);
}
