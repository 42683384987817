import React, { useState } from 'react';
import NavBar from '../../common/components/Headers/topNav';
import { Box, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import ManStanding from '../../../assets/images/teacher.jpeg';
import UserBook from '../../../assets/images/userBook.png';
import CalendarFill from '../../../assets/images/calendarFill.png';
import SearchBig from '../../../assets/images/searchBig.png';
import MoneyBig from '../../../assets/images/moneyBig.png';
import Clock from '../../../assets/images/Clock.png';
import Security from '../../../assets/images/security.png';
import { Link, useHistory } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import Footer from '../../common/components/Footer';
import { useTranslation } from 'react-i18next';
import { Routes } from '../../../router/routes';
import { useCreateTutorMutation } from '../../../app/services/auth.api';
import BaseLayout from '../../common/layouts/BaseLayout';
import { useDispatch } from 'react-redux';
import {
  setAccessToken,
  setRefreshToken,
  setUser,
} from '../../../app/slices/auth.slice';
import Checkbox from '@material-ui/core/Checkbox';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  TextLabelLarge: {
    fontSize: 35,
  },
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  TextLabelMedium: {
    fontSize: 35,
  },
  threeRowsColOne: {
    fontSize: 20,
    width: '70%',
    height: '100%',
    textAlign: 'left',
  },
  smallTextLeft: { fontSize: 13, width: '60%', textAlign: 'left' },
  cardAdj: {
    background: '#FFF2D3',
    width: 230,
    height: 200,
    borderRadius: 5,
    marginTop: -208,
    marginRight: 25,
    [theme.breakpoints.down('sm')]: {
      marginTop: -50,
    },
  },
}));

function BecomeTutorPage() {
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { t } = useTranslation(['page/become_tutor', 'common/common']);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [agree, setAgree] = useState(false);

  const [open, setOpen] = React.useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const [createTutor, createTutorState] = useCreateTutorMutation();

  const handleSignUpTutor = async () => {
    setErrorMessage(null);

    if (email === '' || password === '') {
      return setErrorMessage('Please fill all fields');
    }

    if (confirmPassword !== password) {
      return setErrorMessage('Password and Confirm Password not matched');
    }

    try {
      const {
        accessToken,
        refreshToken,
        data: { _id },
      } = await createTutor({
        email,
        password,
      }).unwrap();

      dispatch(setAccessToken(accessToken));
      dispatch(setRefreshToken(refreshToken));
      dispatch(setUser({ _id, email, role: 'tutor' }));

      setTimeout(() => {
        history.push(`${Routes.SignUp}/${_id}`);
      }, 10);
    } catch (err) {
      setErrorMessage(
        err?.data?.error || 'Something went wrong. Try to adjust your data.'
      );
    }
  };

  return (
    <BaseLayout header={<NavBar />}>
      <Grid container style={{ marginTop: 10 }} direction="row">
        <Grid item md={3} lg={3} xl={3}></Grid>

        <Grid item md={6} lg={6} xl={6} sm={12} xs={12}>
          <p className={classes.TextLabelLarge}>{t('world_needs_teachers')}</p>

          <Grid container direction="column" alignItems="center">
            <p style={{ fontSize: 13, width: '80%' }}>
              {t('tutors_join_our_platform')}
            </p>
          </Grid>

          <Grid container direction="row">
            <Grid item md={2} lg={2} xl={2} sm={1} xs={1}></Grid>

            <Grid item md={8} lg={8} xl={8} sm={10} xs={10}>
              {errorMessage ? (
                <Grid container direction="column" alignItems="center">
                  <p style={{ fontSize: 13, width: '80%', color: 'red' }}>
                    {errorMessage}
                  </p>
                </Grid>
              ) : null}

              <p
                style={{
                  textAlign: 'left',
                  marginBottom: '0px',
                  marginTop: '10px',
                }}
              >
                {t('email_address')}
              </p>

              <input
                style={{ width: '100%', height: 45, marginTop: '5px' }}
                onChange={(val) => setEmail(val.target.value)}
                type="text"
              ></input>

              <p
                style={{
                  textAlign: 'left',
                  marginBottom: '0px',
                  marginTop: '10px',
                }}
              >
                {t('password')}
              </p>

              <input
                style={{ width: '100%', height: 45, marginTop: '5px' }}
                onChange={(val) => setPassword(val.target.value)}
                type="password"
              ></input>

              <p
                style={{
                  textAlign: 'left',
                  marginBottom: '0px',
                  marginTop: '10px',
                }}
              >
                {t('confirm_password')}
              </p>

              <input
                style={{ width: '100%', height: 45, marginTop: '5px' }}
                onChange={(val) => setConfirmPassword(val.target.value)}
                type="password"
              ></input>

              <Grid
                container
                direction="row"
                alignItems="center"
                justifyContent="center"
                style={{
                  background: agree ? '#FFB600' : '#D3D3D3',
                  marginTop: '20px',
                  cursor: 'pointer',
                }}
                disabled={!agree}
                onClick={agree ? handleSignUpTutor : undefined}
              >
                <Grid
                  container
                  direction="column"
                  alignItems="center"
                  style={{ padding: 10 }}
                >
                  {createTutorState.isLoading ? (
                    <CircularProgress size={25} />
                  ) : (
                    <a style={{ color: 'white' }}>{t('sign_up')}</a>
                  )}
                </Grid>
              </Grid>
            </Grid>

            <Grid item md={2} lg={2} xl={2} sm={1} xs={1}></Grid>
          </Grid>

          <p style={{ fontSize: 12, marginTop: '10px' }}>
            <Checkbox
              color="primary"
              value={agree}
              onChange={(_, checked) => setAgree(checked)}
            />
            {t('by_clicking_sign_up')}{' '}
            <span>
              <a
                href="https://drive.google.com/file/d/1kHCV6FgUXyB8lbKsTkX6HbLriI6ya8_-/view?usp=sharing"
                target="_blank"
                style={{ color: '#FFB600' }}
              >
                {t('terms_of_service')}
              </a>
            </span>{' '}
            {t('and')}
            <span style={{ color: '#FFB600' }}>
              {' '}
              <a
                href="https://drive.google.com/file/d/1C9UieMdrCRgVKswb4feecp0Ta6zKpbu3/view?usp=sharing"
                target="_blank"
                style={{ color: '#FFB600' }}
              >
                {t('privacy_policy')}
              </a>
            </span>
          </p>

          <Box mt={2}>
            <span>
              {t('login.already_have_account', { ns: 'common/common' })}{' '}
              <Link to={Routes.Login} style={{ color: '#FFB600' }}>
                {t('login.sign_in', { ns: 'common/common' })}
              </Link>
            </span>
          </Box>
        </Grid>
        <Grid item md={3} lg={3} xl={3} sm={1} xs={1}></Grid>
      </Grid>

      <Grid container style={{ marginTop: 20 }} direction="row">
        <Grid item md={1} lg={1} xl={1} sm={1} xs={1}></Grid>
        <Grid item md={10} lg={10} xl={10} sm={10} xs={10}>
          <img style={{ width: '100%' }} src={ManStanding}></img>

          <div style={{ margin: '1rem 0 3rem 0' }}>
            <Grid
              container
              direction="row"
              alignItems="center"
              style={{
                //height: 75,
                borderBottom: '1px solid #F2F2F2',
              }}
            >
              <Grid item md={4} lg={4} xl={4} sm={12} xs={12}>
                <Grid container direction="column" alignItems="center">
                  <p className={classes.threeRowsColOne}>
                    {t('find_new_students')}
                  </p>
                </Grid>
              </Grid>
              <Grid item md={4} lg={4} xl={4} sm={12} xs={12}>
                <Grid container direction="column" alignItems="flex-start">
                  <img src={UserBook}></img>
                  <p style={{ fontSize: 14, fontWeight: 600, marginTop: 10 }}>
                    {t('stable_student_base')}
                  </p>
                  <Grid container direction="column" alignItems="flex-start">
                    <p className={classes.smallTextLeft}>
                      {t('apply_for_offers')}
                    </p>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={4} lg={4} xl={4} sm={12} xs={12}>
                <Grid container direction="column" alignItems="flex-start">
                  <img src={SearchBig}></img>
                  <p style={{ fontSize: 14, fontWeight: 600, marginTop: 10 }}>
                    {t('online_career_opportunities')}
                  </p>
                  <Grid container direction="column" alignItems="flex-start">
                    <p className={classes.smallTextLeft}>
                      {t('profile_is_visible')}
                    </p>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              alignItems="center"
              style={{
                //height: 75,
                marginTop: 20,
                borderBottom: '1px solid #F2F2F2',
              }}
            >
              <Grid item md={4} lg={4} xl={4} sm={12} xs={12}>
                <Grid container direction="column" alignItems="center">
                  <p className={classes.threeRowsColOne}>
                    {t('grow_your_business')}
                  </p>
                </Grid>
              </Grid>
              <Grid item md={4} lg={4} xl={4} sm={12} xs={12}>
                <Grid container direction="column" alignItems="flex-start">
                  <img src={CalendarFill}></img>
                  <p style={{ fontSize: 14, fontWeight: 600, marginTop: 10 }}>
                    {t('calendar_for_lessons')}
                  </p>
                  <Grid container direction="column" alignItems="flex-start">
                    <p className={classes.smallTextLeft}>
                      {t('apply_for_offers_space')}
                    </p>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={4} lg={4} xl={4} sm={12} xs={12}>
                <Grid container direction="column" alignItems="flex-start">
                  <img src={Clock}></img>
                  <p style={{ fontSize: 14, fontWeight: 600, marginTop: 10 }}>
                    {t('flexibility')}
                  </p>
                  <Grid container direction="column" alignItems="flex-start">
                    <p className={classes.smallTextLeft}>
                      {t('set_work_hours')}
                    </p>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              alignItems="center"
              style={{
                //height: 75,
                marginTop: 20,
                borderBottom: '1px solid #F2F2F2',
              }}
            >
              <Grid item md={4} lg={4} xl={4} sm={12} xs={12}>
                <Grid container direction="column" alignItems="center">
                  <p className={classes.threeRowsColOne}>
                    {t('earn_without_problems')}
                  </p>
                </Grid>
              </Grid>
              <Grid item md={4} lg={4} xl={4} sm={12} xs={12}>
                <Grid container direction="column" alignItems="flex-start">
                  <img src={MoneyBig}></img>
                  <p style={{ fontSize: 14, fontWeight: 600, marginTop: 10 }}>
                    {t('own_hourly_rate')}
                  </p>
                  <Grid container direction="column" alignItems="flex-start">
                    <p className={classes.smallTextLeft}>
                      {t('change_your_hourly_rate')}
                    </p>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={4} lg={4} xl={4} sm={12} xs={12}>
                <Grid container direction="column" alignItems="flex-start">
                  <img src={Security}></img>
                  <p style={{ fontSize: 14, fontWeight: 600, marginTop: 10 }}>
                    {t('secure_payments')}
                  </p>
                  <Grid container direction="column" alignItems="flex-start">
                    <p className={classes.smallTextLeft}>
                      {t('receive_earnings_directly')}
                    </p>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </Grid>
        <Grid item md={1} lg={1} xl={1} sm={1} xs={1}></Grid>
      </Grid>
    </BaseLayout>
  );
}

export default BecomeTutorPage;
