import React, { useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import NativeSelect from '@material-ui/core/NativeSelect';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Dropzone from 'react-dropzone';
import axios from 'axios';
import InputBase from '@material-ui/core/InputBase';
import CircularProgress from '@material-ui/core/CircularProgress';
import Image from '../../../../../assets/images/d.png';
import { green } from '@material-ui/core/colors';
import baseUrl from '../../../../../baseurl';
import { useTranslation } from 'react-i18next';
import {
  useGetTutorExtendedQuery,
  useUpdateTutorDescriptionMutation,
} from '../../../../../app/services/tutor.api';

const BootstrapInput = withStyles((theme) => ({
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: '#FFFFFF',
    padding: '2rem',
    borderRadius: 5,
    marginTop: '3rem',
  },
  heading1: {
    color: '#6F757B',
    fontSize: 28,
  },
  buttonNext: {
    marginTop: theme.spacing(5),
    backgroundColor: '#FFB600',
    color: 'white',
  },
  progress: {
    color: '#FFFFFF',
  },
  buttonBack: {
    marginTop: theme.spacing(5),
    marginRight: theme.spacing(5),
    backgroundColor: '#F8F8F8',
    color: '#FFB600',
    borderColor: '#FFB600',
    borderWidth: 1,
    borderStyle: 'solid',
  },
  p1: {
    fontSize: 15,
    padding: 0,
    margin: 0,
    color: '#6F757B',
  },
  txt1: {
    color: '#6F757B',
    fontSize: 22,
    marginTop: '1rem',
  },
  input1: {
    height: '0.3rem',
    color: '#6F757B',
  },
  label: {
    color: '#6F757B',
  },
  label1: {
    color: '#6F757B',
    marginTop: '1rem',
  },
  headings: {
    color: '#6F757B',
  },
}));
export default function Description(props) {
  const classes = useStyles();
  const [error, setError] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [headline, setHeadline] = React.useState(null);
  const [description, setDescription] = React.useState(null);
  const { t } = useTranslation('page/sign_up');

  const tutorQuery = useGetTutorExtendedQuery(props.tutorId);

  const [updateDescription] = useUpdateTutorDescriptionMutation();

  useEffect(() => {
    if (tutorQuery.data) {
      const { headline, description } = tutorQuery?.data?.data || {};

      setHeadline(headline);
      setDescription(description);
    }
  }, [tutorQuery.data]);

  const onSubmit = async () => {
    setError(false);
    setLoading(true);

    if (headline === null || description === null) {
      setError(true);
      setLoading(false);
    } else {
      try {
        await updateDescription({
          headline: headline,
          description: description,
        }).unwrap();

        props.handleNext();
      } catch (e) {
        setError(e?.data?.error || true);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div style={{ margin: '1rem' }}>
      <Container maxWidth="md" className={classes.container} boxShadow={3}>
        <Typography
          className={classes.heading1}
          variant="subtitle1"
          align="left"
          gutterBottom
        >
          {t('description_component.profile_description')}
        </Typography>

        <p align="left" className={classes.p1}>
          {t('description_component.create_a_new_profile_headline')}
        </p>
        {error ? (
          <Grid container alignItems="left">
            <p style={{ fontSize: 13, color: 'red' }}>
              {t('description_component.please_fill_required_fields')}
            </p>
          </Grid>
        ) : null}

        <Grid container>
          <Grid item xs={12} md={2}>
            <img
              src={props.image || tutorQuery?.data?.data?.imageUrl}
              style={{ maxWidth: '100%', maxHeight: '100%', borderRadius: 10 }}
            />
          </Grid>
          <Grid item md={1}></Grid>
          <Grid item xs={12} md={9}>
            <Grid container>
              <Grid item xs={12} md={7}>
                <Typography
                  variant="subtitle1"
                  align="left"
                  className={classes.label}
                >
                  {t('description_component.tag_line')}{' '}
                  {error && headline === null ? (
                    <span style={{ color: 'red' }}>*</span>
                  ) : null}
                </Typography>
                <TextField
                  style={{ width: '100%' }}
                  placeholder={t('description_component.headline_placeholder')}
                  value={headline}
                  onChange={(e) => setHeadline(e.target.value)}
                  InputProps={{ classes: { input: classes.input1 } }}
                  variant="outlined"
                />
                <Typography
                  className={classes.headings}
                  variant="caption"
                  align="left"
                  display="block"
                >
                  {t('description_component.good_example')}
                </Typography>
              </Grid>
              <Grid item xs={12} md={7}>
                <Typography
                  variant="subtitle1"
                  align="left"
                  className={classes.label1}
                >
                  {t('description_component.about_the_tutor')}
                  {error && description === null ? (
                    <span style={{ color: 'red' }}>*</span>
                  ) : null}
                </Typography>
                <TextField
                  style={{ width: '100%' }}
                  placeholder={t(
                    'description_component.description_placeholder'
                  )}
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  variant="outlined"
                  multiline
                  rows={6}
                />
                <Typography
                  className={classes.headings}
                  variant="caption"
                  align="left"
                  display="block"
                >
                  {t('description_component.validation_info').replace(
                    '{{count}}',
                    description?.length || 0
                  )}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Box display="flex" justifyContent="flex-start">
          <Button
            variant="contained"
            className={classes.buttonBack}
            onClick={() => props.handleBack()}
          >
            {t('back')}
          </Button>

          <Button
            variant="contained"
            className={classes.buttonNext}
            onClick={() => onSubmit()}
          >
            {loading ? <CircularProgress size={25} className={classes.progress}/> : t('next')}
          </Button>
        </Box>
      </Container>
    </div>
  );
}
