import { logout } from '../app/slices/auth.slice';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Routes } from '../router/routes';

const useLogout = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const handleLogout = (toLogin = false) => {
    localStorage.removeItem('yellowToken');
    localStorage.removeItem('yellowTutor');
    localStorage.removeItem('yellowStudent');
    localStorage.removeItem('role');

    dispatch(logout());

    if (toLogin) {
      history.push(Routes.Login);
    }
  };

  return handleLogout;
};

export default useLogout;
