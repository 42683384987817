import { useTranslation } from 'react-i18next';
import { Routes } from '../router/routes';

const useTutorLinks = () => {
  const { t } = useTranslation('component/navigation');

  return [
    {
      name: t('sub_header_tutor.my_lessons'),
      link: Routes.TutorLessons,
      tabName: 'My Lessons',
    },
    {
      name: t('sub_header_tutor.messages'),
      link: Routes.TutorMessages,
      tabName: 'Messages',
    },
    {
      name: t('sub_header_tutor.calendar'),
      link: Routes.TutorCalendar,
      tabName: 'Calendar',
    },
    {
      name: t('sub_header_tutor.availability'),
      link: Routes.TutorAvailability,
      tabName: 'TutorAvailability',
    },
    {
      name: t('sub_header_tutor.withdrawal_requests'),
      link: Routes.TutorWithdrawalRequests,
      tabName: 'Withdrawal Requests',
    },
    {
      name: t('sub_header_tutor.settings'),
      link: Routes.Settings,
      tabName: 'Settings',
    },
  ];
};

export default useTutorLinks;
