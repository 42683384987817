import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Man from '../../../assets/images/teacher.jpeg';
import BusCircle from '../../../assets/images/bus.png';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectAccessToken } from '../../../app/slices/auth.slice';
import { Routes } from '../../../router/routes';
import StyledButton from '../../common/components/Buttons/StyledButton';

const useStyles = makeStyles(() => ({
  languagesCard: {
    marginTop: 30,
    background: 'white',
    borderRadius: 5,
  },
  semiBoldText: {
    fontFamily: 'Roboto',
    fontSize: 18,
  },
  yellowButton: {
    padding: 10,
    color: 'white',
    borderRadius: 5,
    width: 250,
    background: '#FFB600',

    '& a:hover': {
      color: 'white',
    },
  },
  navLinks: {
    color: 'white',
    fontFamily: 'Roboto',
    cursor: 'pointer',
  },
}));

export default function SpeakConfidently() {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation('page/landing');

  return (
    <Grid container>
      <Grid xs={10} style={{ margin: 'auto' }}>
        <Box
          mt="80px"
          borderRadius="30px"
          style={{ background: 'white', padding: '0 110px 60px' }}
        >
          <Box pt="20px" />

          <div className={classes.languagesCard}>
            <img
              style={{
                width: '100%',
                maxWidth: '1060px',
                borderRadius: 5,
                objectFit: 'cover',
                marginTop: '1rem',
              }}
              src={Man}
            />

            <Box>
              <Box>
                <img width={52} src={BusCircle}></img>
              </Box>

              <Box
                m="auto"
                mt="32px"
                maxWidth={858}
                width="100%"
                textAlign="center"
              >
                <Typography
                  style={{ fontSize: '32px', fontWeight: 500, width: '100%' }}
                >
                  {t('speak_confidently_component.goal').split('10 000')[0]}{' '}
                  <span style={{ color: '#FFB600' }}>10 000</span>{' '}
                  {t('speak_confidently_component.goal').split('10 000')[1]}
                </Typography>
              </Box>

              <Box mt="24px">
                <Typography style={{ fontSize: '16px', fontWeight: 400 }}>
                  {t('speak_confidently_component.result')}
                </Typography>
              </Box>

              <Box mt="40px">
                <StyledButton
                  variant="filled"
                  style={{ color: 'white', fontSize: '20px', fontWeight: 700 }}
                  onClick={() => history.push(Routes.RequestTutorHome)}
                >
                  {t('speak_confidently_component.learn')}
                </StyledButton>
              </Box>
            </Box>
          </div>
        </Box>
      </Grid>
    </Grid>
  );
}
