import { makeStyles } from '@material-ui/core/styles';

const useModalStyles = makeStyles((theme) => ({
  modalLessonWrap: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: theme.palette.background.paper,
    outline: 'none',
    border: 'none',
    borderRadius: '20px',
    padding: '1rem 2rem 2rem',
  },

  modalLessonHeaderWrap: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },

  modalLessonCloseIcon: {
    cursor: 'pointer',
  },

  modalLessonBodyWrap: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginTop: '1rem',
  },

  modalLessonTutorImg: {
    width: 150,
    height: 150,
    borderRadius: 100,
  },

  modalLessonTitle: {
    fontSize: '1.5rem',
    margin: '2rem 0 1rem',
  },

  modalLessonBtnWrap: {
    display: 'flex',
    justifyContent: 'right',
    marginTop: '2rem',
  },

  modalLessonBtnsWrap: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '2rem',
  },

  modalLessonBtnReport: {
    background: '#F8E0A4',
    color: '#DFA003',
  },

  modelLessonBtnContinue: {
    background: '#FFB600',
    color: 'white',
  },

  modalLessonStarts: {
    margin: '1rem 0',
  },
}));

export default useModalStyles;
