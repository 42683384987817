import Axios from 'axios';
export const callAPI = async (request) => {
  const { url: URL, method, data } = request;
  const url = process.env.REACT_APP_URL + URL;
  console.log('in api', URL, method, data);
  const res = await Axios({
    method,
    headers: { Authorization: localStorage.getItem('yellowToken') },
    url,
    data,
  })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log('err', error.response);
      // alert(error.response.data.error);
      return error.response;
    });

  return res;
};
