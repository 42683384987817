import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';
import { Box, Grid, Typography } from '@material-ui/core';
import FilterWrapper from './FilterWrapper';
import FilterDropdown from './FilterDropdown';
import useQueryParams from '../../../hooks/useQueryParams';
import useFilters from '../hooks/useFilters';

const useStyles = makeStyles(() => ({
  container: {
    padding: '1rem 0',
    position: 'sticky',
    backgroundColor: '#ffffff',
    borderBottom: '1px solid rgb(112, 112, 112, 0.2)',
    top: 0,
    zIndex: 2,
  },
  sliderWrap: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0 1.2rem',
  },
  sliderCount: {
    color: '#6f757b',
    fontSize: '0.8rem',
    fontWeight: 500,
  },
  slider: {
    width: '70%',
    color: '#ffcd02',
  },
}));

const FiltersBar = () => {
  const classes = useStyles();
  const { t } = useTranslation('component/navigation');

  const { subjects, nativeLanguages, countriesOrigin, pricePerHour } =
    useFilters();

  const [
    {
      nativeLanguage = 'all',
      countryOrigin = 'all',
      subject = 'ukrainian_language',
    },
    setQueryParams,
  ] = useQueryParams();

  const [prices, setPrices] = useState([]);

  useEffect(() => {
    setPrices([parseInt(pricePerHour.min), parseInt(pricePerHour.max)]);
  }, [pricePerHour]);

  const updateFilter = (key) => (value) => {
    setQueryParams((prevParams) => ({ ...prevParams, [key]: value }));
  };

  const handleSliderChange = (e, [min, max]) => {
    setPrices([min, max]);

    setQueryParams((prevParams) => ({
      ...prevParams,
      pricePerHourMin: min.toString(),
      pricePerHourMax: max.toString(),
    }));
  };

  return (
    <Grid container justifyContent="center" className={classes.container}>
      <Grid container item xs={12} md={10}>
        <FilterWrapper
          first
          name={t('nav.tutor_teaches', { ns: 'component/navigation' })}
        >
          <FilterDropdown
            includeAll={false}
            currentValue={subject}
            onChange={updateFilter('subject')}
            items={subjects}
            translatePath={'common/subject_list'}
          />
        </FilterWrapper>

        <FilterWrapper
          name={t('nav.price_per_hour', { ns: 'component/navigation' })}
        >
          <Box className={classes.sliderWrap}>
            <Typography className={classes.sliderCount}>
              {pricePerHour.min} ₴
            </Typography>

            <Slider
              className={classes.slider}
              value={prices}
              max={parseInt(pricePerHour.max)}
              min={parseInt(pricePerHour.min)}
              step={1}
              onChange={handleSliderChange}
              valueLabelDisplay="auto"
              aria-labelledby="range-slider"
            />

            <Typography className={classes.sliderCount}>
              {pricePerHour.max} ₴
            </Typography>
          </Box>
        </FilterWrapper>

        <FilterWrapper
          name={t('nav.originally_from', { ns: 'component/navigation' })}
        >
          <FilterDropdown
            currentValue={countryOrigin}
            onChange={updateFilter('countryOrigin')}
            items={countriesOrigin}
            translatePath={'common/countries'}
          />
        </FilterWrapper>

        <FilterWrapper
          last
          name={t('nav.native_speaker', { ns: 'component/navigation' })}
        >
          <FilterDropdown
            currentValue={nativeLanguage}
            onChange={updateFilter('nativeLanguage')}
            items={nativeLanguages}
            translatePath={'common/language_list'}
          />
        </FilterWrapper>
      </Grid>
    </Grid>
  );
};

export default FiltersBar;
