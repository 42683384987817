import api from './base';
import { getUserId } from '../utils/getters';

const scheduleApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getSchedule: builder.query({
      query: ({ scheduleId, ...params }) => ({
        url: `schedule/${scheduleId}`,
        params,
      }),
      keepUnusedDataFor: 0,
      providesTags: ['Schedule'],
    }),

    getBookedSchedule: builder.query({
      query: ({ tutorId, ...params }) => ({
        url: `schedule/booked/tutor/${tutorId || getUserId()}`,
        params,
      }),
      keepUnusedDataFor: 0,
      providesTags: ['Schedule'],
    }),

    getAvailableSchedule: builder.query({
      query: ({ tutorId, ...params }) => ({
        url: `schedule/available/tutor/${tutorId || getUserId()}`,
        params,
      }),
      keepUnusedDataFor: 0,
      providesTags: ['Schedule'],
    }),

    updateSchedule: builder.mutation({
      query: ({ scheduleId, ...body }) => ({
        url: `schedule/${scheduleId}`,
        method: 'PUT',
        body: { ...body, tutorId: getUserId() },
      }),
      invalidatesTags: ['Schedule', 'Tutor'],
    }),
  }),
});

export const {
  useGetScheduleQuery,
  useGetBookedScheduleQuery,
  useGetAvailableScheduleQuery,
  useUpdateScheduleMutation,
} = scheduleApi;
