import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import RequestStatusChip from './RequestStatusChip';
import { CurrencySymbols } from '../../common/constants/currencies';
import { TablePagination } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const RequestsTable = ({
  requests = [],
  rowsPerPage,
  page,
  onChangePage,
  onChangeRowsPerPage,
  total = 0,
}) => {
  const { t: tWithdrawal } = useTranslation('component/withdrawal_table');
  const { t: tCommon } = useTranslation('common/common');
  const Headers = [
    `${tWithdrawal('date_colum_name')}`,
    `${tWithdrawal('amount_colum_name')}`,
    `${tWithdrawal('status_colum_name')}`,
    `${tWithdrawal('name_colum_name')}`,
    `${tWithdrawal('bank_colum_name')}`,
    `${tWithdrawal('card_colum_name')}`,
  ];
  const classes = useStyles();

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            {Headers.map((header) => (
              <TableCell key={header}>{header}</TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {requests.map(
            ({
              createdAt,
              amount,
              status,
              name,
              bankName,
              cardNumber,
              currency,
            }) => (
              <TableRow key={createdAt}>
                <TableCell component="th" scope="row">
                  <strong>{moment(createdAt).format('DD-MM-YYYY')}</strong>
                </TableCell>

                <TableCell>
                  {CurrencySymbols[currency]}
                  {amount}
                </TableCell>

                <TableCell>
                  <RequestStatusChip status={status} />
                </TableCell>

                <TableCell>{name}</TableCell>

                <TableCell>{bankName}</TableCell>

                <TableCell>{cardNumber}</TableCell>
              </TableRow>
            )
          )}
        </TableBody>
      </Table>

      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={total}
        rowsPerPage={rowsPerPage}
        labelRowsPerPage={tCommon('pagination.rows_per_page')}
        page={page}
        onPageChange={onChangePage}
        onRowsPerPageChange={onChangeRowsPerPage}
      />
    </TableContainer>
  );
};

export default RequestsTable;
