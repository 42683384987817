import React from 'react';
import LanguagesCard from './sections/LanguagesCard';
import CommentSlider from './sections/CommentSlider';
import HowYellowWorks from './sections/HowYellowWorks';
import TutorWithYellow from './sections/TutorWithYellow';
import SpeakConfidently from './sections/SpeakConfidently';
import Footer from '../common/components/Footer';
import Hero from './sections/Hero';
import BecomeTutorSection from './sections/BecomeTutorSection';
import Stats from './sections/Stats';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import WorldComfortZone from './sections/Features/WorldComfortZone';
import BecomeLayout from './sections/BecomeLayout';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Routes } from '../../router/routes';
import Apps from './sections/Apps';

const useStyles = makeStyles((theme) => ({
  root: {
    background: '#EDEFF0',
  },
}));

export default function LandingPage() {
  const history = useHistory();
  const { root } = useStyles();
  const { t } = useTranslation('page/landing');

  const [studentFirst, studentSecond] = t(
    'how_yellow_work_component.how_yellow_school_works'
  ).split('Yellow');

  const [tutorFirst, tutorSecond] = t('become_tutor_component.title').split(
    'Yellow'
  );

  const studentSteps = [
    {
      title: t('how_yellow_work_component.find_perfect_tutor'),
      description: t('how_yellow_work_component.search_by_criteria'),
    },
    {
      title: t('how_yellow_work_component.schedule_your_lesson'),
      description: t('how_yellow_work_component.view_tutors_calendar'),
    },
    {
      title: t('how_yellow_work_component.take_first_lesson'),
      description: t('how_yellow_work_component.talk_with_tutor'),
    },
  ];

  const tutorSteps = [
    {
      title: t('become_tutor_component.first_title'),
      description: t('become_tutor_component.first_description'),
    },
    {
      title: t('become_tutor_component.second_title'),
      description: t('become_tutor_component.second_description'),
    },
    {
      title: t('become_tutor_component.third_title'),
      description: t('become_tutor_component.third_description'),
    },
  ];

  return (
    <div className={root}>
      <Box minHeight="100vh">
        <Hero />
        <Stats />
      </Box>

      <LanguagesCard />

      <WorldComfortZone />

      <BecomeLayout
        id="how-it-works"
        title={
          <>
            {studentFirst} <span style={{ color: '#FFB600' }}>Yellow</span>{' '}
            {studentSecond}
          </>
        }
        items={studentSteps}
        description={
          <Box mt="40px">
            <Typography
              style={{
                fontSize: '32px',
                fontWeight: 500,
              }}
            >
              {t('how_yellow_work_component.satisfaction_guarantee')}
            </Typography>

            <Box mt="16px">
              <Typography
                style={{
                  fontSize: '16px',
                }}
              >
                {t('how_yellow_work_component.trial_lesson_guarantee')}
              </Typography>
            </Box>
          </Box>
        }
        buttonName={t('become_student')}
        onClick={() => history.push(Routes.BecomeStudent)}
      />

      <TutorWithYellow />

      <BecomeLayout
        title={
          <>
            {tutorFirst} <span style={{ color: '#FFB600' }}>Yellow</span>{' '}
            {tutorSecond}
          </>
        }
        items={tutorSteps}
        buttonName={t('become_tutor')}
        onClick={() => history.push(Routes.BecomeTutor)}
      />

      <CommentSlider />

      <SpeakConfidently />

      <Apps />

      <Box height="128px" />

      <Footer />
    </div>
  );
}
