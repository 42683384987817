import { Redirect, Route } from 'react-router-dom';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { selectAccessToken, selectUser } from '../app/slices/auth.slice';
import { Routes } from './routes';
import { UserRoles } from '../modules/common/constants/user-roles';
import { useGetMeQuery } from '../app/services/auth.api';
import { skipToken } from '@reduxjs/toolkit/query';
import Backdrop from '@material-ui/core/Backdrop';
import { CircularProgress } from '@material-ui/core';

interface IProps extends Record<any, any> {
  requireRole?: (typeof UserRoles)[keyof typeof UserRoles];
}

const ProtectedRoute: FC<IProps> = ({ children, requireRole, ...rest }) => {
  const accessToken = useSelector(selectAccessToken);
  const user = useSelector(selectUser);

  const { data, isLoading } = useGetMeQuery(
    accessToken ? undefined : skipToken
  );

  const isRoleCorrect = requireRole ? requireRole === user?.role : true;

  return (
    <Route
      {...rest}
      render={() => {
        if (accessToken) {
          if (isLoading && !data) {
            return (
              <Backdrop open>
                <CircularProgress size={35} />
              </Backdrop>
            );
          }

          if (
            !isLoading &&
            data &&
            !data?.isProfileComplete &&
            data?.role !== UserRoles.Student &&
            location.pathname !== `${Routes.SignUp}/${data._id}`
          ) {
            return (
              <Redirect
                to={{
                  pathname: `${Routes.SignUp}/${data._id}`,
                }}
              />
            );
          }

          if (isRoleCorrect) {
            return children;
          }

          return (
            <Redirect
              to={{
                pathname:
                  user?.role === UserRoles.Tutor
                    ? Routes.Dashboard
                    : Routes.StudentLessons,
              }}
            />
          );
        }
        return <Redirect to={{ pathname: Routes.Login }} />;
      }}
    />
  );
};

export default ProtectedRoute;
