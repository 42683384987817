import React from 'react';
import NavBar from '../common/components/Headers/topNav';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Footer from '../common/components/Footer';
import 'react-bootstrap-timezone-picker/dist/react-bootstrap-timezone-picker.min.css';
import 'react-phone-number-input/style.css';
import SideButtons from '../common/components/SideButtons';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  TextLabelLarge: {
    fontSize: 35,
  },
  card: {
    background: 'white',
    marginBottom: 20,
    borderRadius: 5,
  },
  saveButton: {
    background: '#FFB600',
    color: 'white',
    border: 'none',
    borderRadius: 2,
    padding: 10,
    marginBottom: 10,
    width: 130,
  },
  links: {
    marginBottom: 0,
    marginLeft: 10,
    color: 'white',
    cursor: 'pointer',
  },
  textField: {
    width: '90%',
    borderRadius: 2,
    height: 40,
    border: '1px solid gray',
    marginRight: 20,
  },
  dataFieldContainer: {
    marginTop: 20,
    marginBottom: 20,
  },
  textLeft: {
    textAlign: 'left',
    paddingLeft: 20,
    fontSize: 14,
  },
}));

export default function Settings() {
  const { t } = useTranslation('page/passwords');
  const classes = useStyles();

  return (
    <div>
      <NavBar></NavBar>
      <div style={{ background: '#384047' }}>
        <Grid
          container
          direction="row"
          style={{ height: 50 }}
          alignItems="center"
          justify="flex-start"
        >
          <p className={classes.links}>{t('my_requests')}</p>
          <p className={classes.links}>{t('my_lessons')}</p>
          <p className={classes.links}>{t('settings')}</p>
        </Grid>
      </div>
      <div style={{ background: '#EDEFF0' }}>
        <Grid container>
          <Grid item md={2} lg={2} xl={2} sm={2} xs={2}></Grid>
          <Grid item md={8} lg={8} xl={8} sm={8} xs={8}>
            <Grid container style={{ marginTop: 20, marginBottom: 20 }}>
              <Grid item md={3} lg={3} xl={3} sm={3} xs={3}>
                <SideButtons for="Password"></SideButtons>
              </Grid>
              <Grid item md={9} lg={9} xl={9} sm={9} xs={9}>
                <div className={classes.card}>
                  <p
                    style={{
                      textAlign: 'left',
                      padding: 10,
                      fontSize: 22,
                      borderBottom: '1px solid #F2F2F2',
                    }}
                  >
                    {' '}
                    {t('password')}
                  </p>
                  <Grid container className={classes.dataFieldContainer}>
                    <Grid item md={4} lg={4} xl={4} sm={4} xs={4}>
                      <p className={classes.textLeft}>{t('new_password')}</p>
                    </Grid>
                    <Grid item md={8} lg={8} xl={8} sm={8} xs={8}>
                      <Grid
                        container
                        direction="column"
                        alignItems="flex-start"
                      >
                        <input
                          className={classes.textField}
                          type="text"
                        ></input>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container className={classes.dataFieldContainer}>
                    <Grid item md={4} lg={4} xl={4} sm={4} xs={4}>
                      <p className={classes.textLeft}>{t('verify_password')}</p>
                    </Grid>
                    <Grid item md={8} lg={8} xl={8} sm={8} xs={8}>
                      <Grid
                        container
                        direction="column"
                        alignItems="flex-start"
                      >
                        <input
                          className={classes.textField}
                          type="text"
                        ></input>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container className={classes.dataFieldContainer}>
                    <Grid item md={4} lg={4} xl={4} sm={4} xs={4}></Grid>
                    <Grid item md={8} lg={8} xl={8} sm={8} xs={8}>
                      <Grid
                        container
                        direction="column"
                        alignItems="flex-start"
                      >
                        <input
                          className={classes.saveButton}
                          value={t('save_button')}
                          type="button"
                        ></input>
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={2} lg={2} xl={2} sm={2} xs={2}></Grid>
        </Grid>
      </div>
      <Footer></Footer>
    </div>
  );
}
