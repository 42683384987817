import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Grid, CircularProgress } from '@material-ui/core';
import NavBar from '../common/components/Headers/topNav';
import SubNavBar from '../common/components/Headers/SubHeaderTutor';
import { GET_TUTOR_PROFILE } from '../../app/sagaActions';
import { useHistory } from 'react-router-dom';
import { Routes } from '../../router/routes';

const TutorDashboard = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const tutorProfile = useSelector(
    (state) => state.tutorInformation.tutorProfile
  );
  const gettingTutorProfile = useSelector(
    (state) => state.tutorInformation.gettingTutorProfile
  );

  useEffect(() => {
    dispatch({ type: GET_TUTOR_PROFILE });
    history.push(Routes.TutorLessons);
  }, []);

  return (
    <div>
      <NavBar />
      <SubNavBar />
      <Grid container direction="row" style={{ marginTop: '20px' }}>
        <Grid item md={3} lg={2} xl={2} sm={false} xs={false}></Grid>

        <Grid item md={6} lg={8} xl={8} sm={8} xs={8}>
          {!gettingTutorProfile && tutorProfile ? (
            <div></div>
          ) : (
            <CircularProgress size={25} />
          )}
        </Grid>
        <Grid item md={3} lg={2} xl={2} sm={false} xs={false}></Grid>
      </Grid>
    </div>
  );
};

export default TutorDashboard;
