import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  textUnderGlobe: {
    color: '#040916',
    fontSize: '24px',
    fontWeight: 500,
    textAlign: 'left',
    fontFamily: 'Roboto',
  },

  textUnderGlobeDown: {
    fontSize: '16px',
    textAlign: 'left',
    fontFamily: 'Roboto',
  },
}));

function Circle({ src }) {
  return (
    <Box
      height={88}
      width={88}
      display="flex"
      alignItems="center"
      justifyContent="center"
      p="20px"
      borderRadius="100px"
      style={{ background: 'rgba(255, 182, 0, 0.2)' }}
    >
      <img src={src} alt="" width="48px" height="48px" />
    </Box>
  );
}

const FeatureItem = ({ image, title, description, inCircle, max, start }) => {
  const classes = useStyles();

  return (
    <Box>
      <Box
        display="flex"
        alignItems={start ? 'flex-start' : 'center'}
        style={{ gap: 24 }}
      >
        {inCircle ? (
          <Circle src={image} />
        ) : (
          <img
            src={image}
            alt={title}
            style={{ width: '88px', height: '88px' }}
          />
        )}

        <Box>
          <Typography className={classes.textUnderGlobe}>{title}</Typography>

          <Typography
            className={classes.textUnderGlobeDown}
            style={{ maxWidth: max }}
          >
            {description}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default FeatureItem;
