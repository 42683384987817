import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { logout, setAccessToken, setRefreshToken } from '../slices/auth.slice';

const BASE_URL = process.env.REACT_APP_SERVER_URL + '/api/';

const baseQuery = fetchBaseQuery({
  baseUrl: BASE_URL,
  prepareHeaders: (headers, { getState }) => {
    const accessToken = getState().auth.accessToken;

    if (accessToken) {
      headers.set('Authorization', `Bearer ${accessToken}`);
    }

    return headers;
  },
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);

  if (
    result.error &&
    result.error.status === 401 &&
    !result.error?.data?.error?.includes('Your profile has not been verified.')
  ) {
    // TODO check refresh logic on the BE
    const { accessToken, refreshToken } = await fetch(
      BASE_URL + 'auth/refresh/',
      {
        method: 'POST',
        body: { refreshToken: api.getState().auth.refreshToken },
      }
    );

    if (accessToken && refreshToken) {
      api.dispatch(setAccessToken(accessToken));
      api.dispatch(setRefreshToken(refreshToken));
      result = await baseQuery(args, api, extraOptions);
    } else {
      api.dispatch(logout());
    }
  }
  return result;
};

const api = createApi({
  reducerPath: 'api',
  baseQuery: baseQueryWithReauth,
  endpoints: () => ({}),
  tagTypes: ['StudentLessons', 'TutorLessons', 'Availability'],
});

export default api;
