import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import AccountCircle from '@material-ui/icons/AccountCircle';
import { Link } from 'react-router-dom';
import Collapse from '@material-ui/core/Collapse';
import Box from '@material-ui/core/Box';
import moment from 'moment';
import 'moment/locale/uk';
import Avatar from '@material-ui/core/Avatar';

const useRowStyles = makeStyles(() => ({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
}));

function LessonRow(props) {
  const { lesson } = props;
  const { studentId, status, lessionDate, isCompleted, tutorId } = lesson;
  const { t } = useTranslation([
    'page/student_lessons_list',
    'common/subject_list',
  ]);
  const classes = useRowStyles();

  return (
    <React.Fragment>
      <>
        <TableRow
          className={classes.root}
          style={{
            borderBottom: '1px solid #AAB5BC',
            borderTop: '1px solid #AAB5BC',
          }}
        >
          <TableCell></TableCell>
          <TableCell>
            {studentId.imageUrl && studentId.imageUrl.length > 0 ? (
              <Avatar
                src={tutorId.imageUrl}
                style={{
                  width: 50,
                  height: 50,
                  borderRadius: 30,
                  marginRight: 10,
                  background: '#212121',
                }}
              />
            ) : (
              <AccountCircle />
            )}
          </TableCell>
          <TableCell align="left">{tutorId.firstName}</TableCell>
          <TableCell align="left">
            {t(tutorId?.subjectTaught?.toLowerCase().replace(' ', '_'), {
              ns: 'common/subject_list',
            })}
          </TableCell>
          <TableCell>
            <span>{t(`tutor_lesson_table_component.${status}`)}</span>
          </TableCell>
          <TableCell
            align="left"
            style={{ textTransform: 'capitalize', width: 130 }}
          >
            {lessionDate !== ''
              ? moment(lessionDate).locale('uk').format('DD MMM. YYYY')
              : 'Not define'}
          </TableCell>
          <TableCell align="left">
            {' '}
            <span>
              {' '}
              {isCompleted ? (
                <span>
                  {t('tutor_lesson_table_component.marked_completed')}
                </span>
              ) : (
                <Link
                  to="#"
                  onClick={() => {
                    props.handleCompletePopup(
                      tutorId.imageUrl,
                      tutorId.firstName,
                      lessionDate,
                      lesson.startTime,
                      lesson._id,
                      tutorId._id
                    );
                  }}
                >
                  {t('tutor_lesson_table_component.confirm')}
                </Link>
              )}
            </span>
          </TableCell>
        </TableRow>

        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={false} timeout="auto" unmountOnExit>
              <Box margin={1}>Further details goes here.</Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    </React.Fragment>
  );
}
export default LessonRow;
