import React, { useState } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import NavBar from '../../common/components/Headers/topNav';
import Footer from '../../common/components/Footer';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { useResetPasswordMutation } from '../../../app/services/auth.api';
import { Routes } from '../../../router/routes';

const ResetPassword = () => {
  const history = useHistory();
  const { id: email } = useParams<{ id: string }>();

  const [password, setPassword] = useState('');
  const [resetCode, setResetCode] = useState('');
  const [reEnterPassword, setReEnterPassword] = useState('');
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const { t } = useTranslation('page/reset_password');

  const [resetPassword, { isLoading: loading }] = useResetPasswordMutation();

  const handleResetPassword = async () => {
    setShowError(false);

    if (password !== reEnterPassword) {
      setErrorMessage('Password not matched');
      setShowError(true);
      return;
    }

    if (password.length < 1 || reEnterPassword.length < 1) {
      setErrorMessage('Fields cant be empty');
      setShowError(true);
      return;
    }

    try {
      await resetPassword({
        resetCode: Number(resetCode),
        email,
        password,
      }).unwrap();

      history.push(Routes.ResetPasswordSuccess);
    } catch (e: any) {
      setErrorMessage(
        e.data?.error
          ? e.data?.error
          : 'something went wrong please try again later'
      );
      setShowError(true);
    }
  };

  return (
    <div>
      <NavBar />
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: '3rem',
        }}
      >
        <h2>{t('reset_password')}</h2>
      </div>
      <div style={{ height: '100vh' }}>
        <Grid container style={{ marginTop: '20px' }}>
          <Grid item md={4} lg={4} xl={4} sm={2} xs={2}></Grid>
          <Grid item md={4} lg={4} xl={4} sm={8} xs={8}>
            {showError ? <p style={{ color: 'red' }}>{errorMessage}</p> : null}

            <form style={{ marginBottom: '1rem' }}>
              <p style={{ textAlign: 'left' }}>{t('reset_password')}</p>

              <input
                style={{ width: '100%', height: 45, padding: 10 }}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                type="password"
              ></input>

              <p style={{ textAlign: 'left', marginTop: '1rem' }}>
                {t('confirm_password')}
              </p>

              <input
                style={{ width: '100%', height: 45, padding: 10 }}
                value={reEnterPassword}
                onChange={(e) => setReEnterPassword(e.target.value)}
                type="password"
              ></input>

              <p style={{ textAlign: 'left', marginTop: '1rem' }}>
                {t('reset_code')}
              </p>

              <input
                style={{ width: '100%', height: 45, padding: 10 }}
                value={resetCode}
                onChange={(e) => setResetCode(e.target.value)}
                type="text"
              ></input>

              <Grid
                container
                direction="row"
                alignItems="center"
                justify="center"
                style={{ background: '#FFB600', marginTop: 15 }}
              >
                <Button
                  onClick={handleResetPassword}
                  style={{
                    height: '100%',
                    width: '100%',
                    padding: '10px',
                    background: 'none',
                    border: 'none',
                  }}
                >
                  {loading ? <CircularProgress size={25} /> : t('reset')}
                </Button>
              </Grid>
            </form>
          </Grid>

          <Grid item md={4} lg={4} xl={4} sm={2} xs={2}></Grid>
        </Grid>
      </div>

      <div style={{}}>
        <Footer />
      </div>
    </div>
  );
};

export default ResetPassword;
