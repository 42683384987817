import { useHistory, useLocation } from 'react-router-dom';

const useQueryParams = () => {
  const location = useLocation();
  const history = useHistory();

  const searchParams = new URLSearchParams(location.search);

  const searchParamsToObject = () => {
    const params = {};

    for (let [key, value] of searchParams.entries()) {
      params[key] = value;
    }

    return params;
  };

  const setQueryParams = (params) => {
    const currentParams = searchParamsToObject();

    const newParams =
      typeof params === 'function' ? params(currentParams) : params;

    const newSearchParams = new URLSearchParams();

    for (let key in newParams) {
      newSearchParams.set(key, newParams[key]);
    }

    history.replace({ search: newSearchParams.toString() });
  };

  return [searchParamsToObject(), setQueryParams];
};

export default useQueryParams;
