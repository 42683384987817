import React from 'react';
import NavBar from '../common/components/Headers/topNav';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Footer from '../common/components/Footer';
import SideButtons from '../common/components/SideButtons';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  TextLabelLarge: {
    fontSize: 35,
  },
  card: {
    background: 'white',
    marginBottom: 20,
    borderRadius: 5,
    minHeight: 300,
  },
  links: {
    marginBottom: 0,
    marginLeft: 10,
    color: 'white',
    cursor: 'pointer',
  },
  buttons: {
    border: 'none',
    borderRadius: 2,
    padding: 10,
    width: 130,
    textAlign: 'left',
  },
  saveButton: {
    background: '#FFB600',
    color: 'white',
    border: 'none',
    borderRadius: 2,
    padding: 10,
    width: 130,
  },
  deletButton: {
    border: '1px solid #F8463D',
    borderRadius: 2,
    color: '#F8463D',
    marginLeft: 20,
    cursor: 'pointer',
    padding: 10,
    width: 130,
  },
  buttonsFilled: {
    background: 'rgba(0,0,0,0.2)',
    border: 'none',
    borderRadius: 2,
    textAlign: 'left',
    padding: 10,
    width: 130,
  },
  textField: {
    width: '90%',
    borderRadius: 2,
    height: 40,
    border: '1px solid gray',
    marginRight: 20,
  },
  dataFieldContainer: {
    marginTop: 10,
  },
  textLeft: {
    textAlign: 'left',
    paddingLeft: 20,
  },
  yellowTextButton: { color: '#FFB600', cursor: 'pointer' },
}));

export default function Settings() {
  const classes = useStyles();
  const { t } = useTranslation('page/payment_history');

  return (
    <div>
      <NavBar></NavBar>
      <div style={{ background: '#384047' }}>
        <Grid
          container
          direction="row"
          style={{ height: 50 }}
          alignItems="center"
          justify="flex-start"
        >
          <p className={classes.links}>{t('my_requests')}</p>
          <p className={classes.links}>{t('my_lessons')}</p>
          <p className={classes.links}>{t('settings')}</p>
        </Grid>
      </div>
      <div style={{ background: '#EDEFF0' }}>
        <Grid container>
          <Grid item md={2} lg={2} xl={2} sm={2} xs={2}></Grid>
          <Grid item md={8} lg={8} xl={8} sm={8} xs={8}>
            <Grid container style={{ marginTop: 20, marginBottom: 20 }}>
              <Grid item md={3} lg={3} xl={3} sm={3} xs={3}>
                <SideButtons for="Payment history"></SideButtons>
              </Grid>
              <Grid item md={9} lg={9} xl={9} sm={9} xs={9}>
                <div className={classes.card}>
                  <Grid container style={{ borderBottom: '1px solid #F2F2F2' }}>
                    <Grid item md={8} lg={8} xl={8} sm={12} xs={12}>
                      <p
                        style={{
                          textAlign: 'left',
                          padding: 10,
                          fontSize: 22,
                        }}
                      >
                        {' '}
                        {t('payment_history')}
                      </p>
                    </Grid>
                    <Grid item md={4} lg={4} xl={4} sm={12} xs={12}>
                      <Grid
                        container
                        style={{ height: '100%' }}
                        alignItems="center"
                      >
                        <p className={classes.yellowTextButton}>
                          {t('update_billing_info')}
                        </p>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container style={{ borderBottom: '1px solid #F2F2F2' }}>
                    <Grid item md={8} lg={8} xl={8} sm={12} xs={12}>
                      <Grid container>
                        <p style={{ margin: 10 }}>{t('date')}</p>
                        <p style={{ margin: 10 }}>{t('hours')}</p>
                        <p style={{ margin: 10 }}>{t('subject')}</p>
                      </Grid>
                    </Grid>
                    <Grid item md={4} lg={4} xl={4} sm={12} xs={12}>
                      <Grid
                        container
                        style={{ height: '100%' }}
                        alignItems="center"
                      >
                        <p className={classes.yellowTextButton}>
                          {t('download_all')}
                        </p>
                      </Grid>
                    </Grid>
                  </Grid>

                  <p className={classes.yellowTextButton}>
                    {t('find_your_first_tutor')}
                  </p>
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={2} lg={2} xl={2} sm={2} xs={2}></Grid>
        </Grid>
      </div>
      <Footer></Footer>
    </div>
  );
}
