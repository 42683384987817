import React, { useState } from 'react';
import Modal from '@material-ui/core/Modal';
import CloseIcon from '@material-ui/icons/Close';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import useModalStyles from '../../../common/components/Modal/styles/modal';
import { useTranslation } from 'react-i18next';

const ReviewModal = ({
  open,
  onClose,
  tutorImage,
  tutorName,
  isLoading,
  onSubmitReview,
}) => {
  const classes = useModalStyles();
  const { t } = useTranslation('page/student_lessons_list');

  const [commentText, setCommentText] = useState('');

  return (
    <Modal open={open}>
      <div className={classes.modalLessonWrap}>
        <div className={classes.modalLessonHeaderWrap}>
          <CloseIcon
            className={classes.modalLessonCloseIcon}
            onClick={onClose}
          />
        </div>

        <div className={classes.modalLessonBodyWrap}>
          <img src={tutorImage} className={classes.modalLessonTutorImg} />
        </div>

        <h2 id="simple-modal-title" className={classes.modalLessonTitle}>
          {t('tutor_lesson_table_component.leave_a_review')} <b>{tutorName}</b>.
          {t('tutor_lesson_table_component.thank_you')}
        </h2>
        <div>
          <TextField
            id="outlined-multiline-static"
            multiline
            rows={4}
            placeholder={t(
              'tutor_lesson_table_component.write_your_review_here'
            )}
            variant="outlined"
            focused
            style={{ width: '100%', marginTop: '1rem' }}
            onChange={(e) => setCommentText(e.target.value)}
          />
        </div>

        <div className={classes.modalLessonBtnWrap}>
          <Button
            className={classes.modelLessonBtnContinue}
            variant="contained"
            disabled={isLoading}
            onClick={() => onSubmitReview(commentText)}
          >
            {isLoading ? (
              <CircularProgress size={25} style={{ color: 'white' }} />
            ) : (
              t('tutor_lesson_table_component.submit_review')
            )}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ReviewModal;
