import React, { useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import NativeSelect from '@material-ui/core/NativeSelect';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import InputBase from '@material-ui/core/InputBase';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useTranslation } from 'react-i18next';
import {
  useGetTutorExtendedQuery,
  useGetTutorFilterDetailsQuery,
  useUpdateTutorAboutMutation,
} from '../../../../app/services/tutor.api';
import { Box } from '@material-ui/core';

const BootstrapInput = withStyles((theme) => ({
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: '#FFFFFF',
    padding: '2rem',
    borderRadius: 5,
    marginTop: '3rem',
  },
  name: {
    padding: 0,
  },
  input1: {
    height: '0.3rem',
  },
  input2: {
    height: 30,
    fontSize: '3em',
  },
  headings: {
    color: '#6F757B',
  },
  formControl: {
    width: '100%',
  },
  button: {
    marginTop: theme.spacing(3),
    backgroundColor: '#FFB600',
    color: 'white',
  },
  progress: {
    color: '#FFFFFF',
  },
}));
export default function About(props) {
  const classes = useStyles();
  const [firstName, setFirstName] = React.useState('');
  const [error, setError] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [lastName, setLastName] = React.useState('');
  const [countryOrigin, setCountryOrigin] = React.useState('');
  const [hourlyRate, setHourlyRate] = React.useState(null);
  const [subjectTaught, setSubjectTaught] =
    React.useState('Ukrainian language');
  const [phoneNumber, setPhoneNumber] = React.useState('');
  const [currency, setCurrency] = React.useState('UAH');
  const [nativeLanguage, setNativeLanguage] = React.useState('');
  const [languageSpoken, setLanguageSpoken] = React.useState([
    {
      language: '',
      level: '',
    },
  ]);

  const { t } = useTranslation([
    'common/countries',
    'common/languages_level',
    'common/language_list',
    'common/subject_list',
    'common/countries',
    'page/sign_up',
  ]);

  const tutorQuery = useGetTutorExtendedQuery(props.tutorId);
  const { data, isLoading } = useGetTutorFilterDetailsQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });

  const [updateAbout] = useUpdateTutorAboutMutation();

  const {
    subjects = {},
    nativeLanguages = {},
    countriesOrigin = {},
  } = data || {};

  useEffect(() => {
    if (tutorQuery.data) {
      const {
        firstName,
        lastName,
        countryOrigin,
        hourlyRate,
        subjectTaught,
        phoneNumber,
        currency,
        nativeLanguage,
        languageSpoken,
      } = tutorQuery.data?.data || {};

      firstName && setFirstName(firstName);
      lastName && setLastName(lastName);
      countryOrigin && setCountryOrigin(countryOrigin);
      hourlyRate && setHourlyRate(hourlyRate);
      subjectTaught && setSubjectTaught(subjectTaught);
      phoneNumber && setPhoneNumber(phoneNumber);
      currency && setCurrency(currency);
      nativeLanguage && setNativeLanguage(nativeLanguage);
      languageSpoken?.length &&
        setLanguageSpoken(
          languageSpoken.map(({ language, level }) => ({ language, level }))
        );
    }
  }, [tutorQuery.data]);

  const changeLanguage = (value, index) => {
    const newLang = languageSpoken;
    newLang[index].language = value;
    setLanguageSpoken([...newLang]);
  };

  const changeLevel = (value, index) => {
    const newLang = languageSpoken;
    newLang[index].level = value;
    setLanguageSpoken([...newLang]);
  };

  const onSubmit = async () => {
    setError(false);
    setLoading(true);

    if (
      (firstName === '' || lastName === '',
      countryOrigin === '' ||
        hourlyRate === null ||
        subjectTaught === '' ||
        languageSpoken[0]?.language === '' ||
        languageSpoken[0]?.level === '')
    ) {
      setError(true);
      setLoading(false);
    } else {
      const data = {
        firstName: firstName,
        lastName: lastName,
        country: 'null',
        countryOrigin: countryOrigin,
        hourlyRate: hourlyRate,
        subjectTaught,
        languageSpoken: languageSpoken,
        phoneNumber: phoneNumber,
        nativeLanguage: nativeLanguage,
        currency: currency,
      };
      console.log(data);
      try {
        await updateAbout(data).unwrap();
        props.handleNext();
      } catch (e) {
        setError(e?.data?.error || true);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div style={{ margin: '1rem' }}>
      <Container maxWidth="md" className={classes.container} boxShadow={3}>
        <Typography className={classes.headings} variant="h6" gutterBottom>
          {t('about_component.title', { ns: 'page/sign_up' })}
        </Typography>
        {error ? (
          <Box display="flex" flexDirection="column" alignItems="flex-start">
            <p style={{ fontSize: 13, color: 'red' }}>
              {t('about_component.please_fill_all_fields', {
                ns: 'page/sign_up',
              })}
            </p>

            <div>{error}</div>
          </Box>
        ) : null}
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography
              variant="subtitle2"
              align="left"
              className={classes.headings}
            >
              {t('about_component.first_name', { ns: 'page/sign_up' })}
            </Typography>
            <TextField
              style={{ width: '100%' }}
              id="outlined-read-only-input"
              placeholder={t('first_name_placeholder', { ns: 'page/sign_up' })}
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              InputProps={{ classes: { input: classes.input1 } }}
              variant="outlined"
            />
          </Grid>

          <Grid item xs={12}>
            <Typography
              variant="subtitle2"
              align="left"
              color="red"
              className={classes.headings}
            >
              {t('about_component.last_name', { ns: 'page/sign_up' })}
            </Typography>
            <TextField
              style={{ width: '100%' }}
              id="outlined-read-only-input"
              placeholder={t('last_name_placeholder', { ns: 'page/sign_up' })}
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              InputProps={{ classes: { input: classes.input1 } }}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="subtitle2"
              align="left"
              className={classes.headings}
            >
              {t('about_component.country_of_origin', { ns: 'page/sign_up' })}
            </Typography>
            <FormControl className={classes.formControl}>
              <NativeSelect
                id="demo-customized-select-native"
                value={countryOrigin}
                onChange={(e) => setCountryOrigin(e.target.value)}
                input={<BootstrapInput />}
              >
                <option aria-label="None" value="" />

                {Object.entries(countriesOrigin).map(
                  ([countryKey, countryValue]) => (
                    <option key={countryKey} value={countryValue}>
                      {t(countryKey, { ns: 'common/countries' })}
                    </option>
                  )
                )}
              </NativeSelect>
            </FormControl>
          </Grid>
          {languageSpoken.map((data, index) => (
            <>
              <Grid item xs={6}>
                <Typography
                  variant="subtitle2"
                  align="left"
                  className={classes.headings}
                >
                  {t('about_component.languages_spoken', {
                    ns: 'page/sign_up',
                  })}
                </Typography>
                <FormControl className={classes.formControl}>
                  <NativeSelect
                    id="demo-customized-select-native"
                    value={data.language}
                    onChange={(e) => changeLanguage(e.target.value, index)}
                    input={<BootstrapInput />}
                  >
                    <option aria-label="None" value="" />
                    {Object.entries(nativeLanguages).map(
                      ([langKey, langValue]) => (
                        <option key={langKey} value={langValue}>
                          {t(langKey, { ns: 'common/language_list' })}
                        </option>
                      )
                    )}
                  </NativeSelect>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  variant="subtitle2"
                  align="left"
                  className={classes.headings}
                >
                  {t('about_component.level', { ns: 'page/sign_up' })}
                </Typography>
                <FormControl className={classes.formControl}>
                  <NativeSelect
                    id="demo-customized-select-native"
                    value={data.level}
                    onChange={(e) => changeLevel(e.target.value, index)}
                    input={<BootstrapInput />}
                  >
                    <option aria-label="None" value="" />
                    <option value="A1 – Beginner">
                      A1 – {t('beginner', { ns: 'common/languages_level' })}
                    </option>
                    <option value="A2 – Elementary">
                      A2 – {t('elementary', { ns: 'common/languages_level' })}
                    </option>
                    <option value="B1 – Pre-Intermediate">
                      B1 –{' '}
                      {t('pre_intermediate', { ns: 'common/languages_level' })}
                    </option>
                    <option value="B2 – Intermediate">
                      B2 – {t('intermediate', { ns: 'common/languages_level' })}
                    </option>
                    <option value="C1 – Advanced">
                      C1 – {t('advanced', { ns: 'common/languages_level' })}
                    </option>
                    <option value="C2 – Proficient">
                      C2 – {t('proficiency', { ns: 'common/languages_level' })}
                    </option>
                  </NativeSelect>
                </FormControl>
              </Grid>
            </>
          ))}
          <Grid item xs={12} style={{ margin: 0, padding: 0 }}>
            <Typography
              align="left"
              variant="caption"
              display="block"
              style={{
                color: '#FFB600',
                marginLeft: '1rem',
                cursor: 'pointer',
              }}
              onClick={() => {
                // let newData = languageSpoken.push({
                //   language: "",
                //   level: "",
                // });
                setLanguageSpoken([
                  ...languageSpoken,
                  {
                    language: '',
                    level: '',
                  },
                ]);
                console.log('This is new languagess :: ', languageSpoken);
              }}
            >
              {t('about_component.add_another_language', {
                ns: 'page/sign_up',
              })}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography
              variant="subtitle2"
              align="left"
              className={classes.headings}
            >
              {t('about_component.subject_taught', { ns: 'page/sign_up' })}
            </Typography>
            <FormControl className={classes.formControl}>
              <NativeSelect
                value={subjectTaught}
                onChange={(e) => setSubjectTaught(e.target.value)}
                input={<BootstrapInput/>}
              >
                {Object.entries(subjects)
                  .filter(
                    ([subjectKey]) => subjectKey === 'ukrainian_language' || subjectKey === 'english_language'
                  )
                  .map(([subjectKey, subjectValue]) => (
                    <option key={subjectKey} value={subjectValue}>
                      {t(subjectKey, {ns: 'common/subject_list'})}
                    </option>
                  ))
                }
              </NativeSelect>
            </FormControl>
          </Grid>
          <Grid item xs={6} sm={3}>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="flex-end"
            >
              <Grid item xs={6}>
                <Typography
                  variant="subtitle2"
                  align="left"
                  color="red"
                  className={classes.headings}
                >
                  {t('about_component.hourly_rate', { ns: 'page/sign_up' })}
                </Typography>
                <TextField
                  align="left"
                  type="number"
                  id="outlined-read-only-input"
                  placeholder={0}
                  value={hourlyRate}
                  onChange={(e) => {
                    if (!e.target.value) return setHourlyRate('');

                    if (
                      e.target.value >= 0 &&
                      !isNaN(parseInt(e.target.value))
                    ) {
                      setHourlyRate(parseInt(e.target.value));
                    } else {
                      setHourlyRate(0);
                    }
                  }}
                  InputProps={{
                    classes: { input: classes.input1 },
                  }}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={5} style={{ marginTop: '1rem' }}>
                <FormControl className={classes.formControl}>
                  <NativeSelect
                    value={currency}
                    IconComponent="none"
                    onChange={(e) => setCurrency(e.target.value)}
                    input={<BootstrapInput />}
                    disabled
                  >
                    <option value="UAH">UAH</option>
                  </NativeSelect>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={6}
            justifyContent="flex-end"
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            <Typography
              variant="subtitle2"
              align="left"
              className={classes.headings}
            >
              {t('about_component.native_language', { ns: 'page/sign_up' })}
            </Typography>

            <FormControl className={classes.formControl}>
              <NativeSelect
                id="demo-customized-select-native"
                value={nativeLanguage}
                onChange={(e) => setNativeLanguage(e.target.value)}
                input={<BootstrapInput />}
              >
                <option aria-label="None" value="" />

                {Object.entries(nativeLanguages).map(([langKey, langValue]) => (
                  <option key={langKey} value={langValue}>
                    {t(langKey, { ns: 'common/language_list' })}
                  </option>
                ))}
              </NativeSelect>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <Typography
              variant="subtitle2"
              align="left"
              color="red"
              className={classes.headings}
            >
              {t('about_component.phone_number', { ns: 'page/sign_up' })}
            </Typography>
            {/* <TextField
              style={{ width: '100%' }}
              id='outlined-read-only-input'
              type='number'
              placeholder='+1(000) 000-0000'
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              InputProps={{ classes: { input: classes.input1 } }}
              variant='outlined'
            /> */}
            <PhoneInput
              country={'us'}
              value={phoneNumber}
              onChange={(phone) => {
                setPhoneNumber(phone);
              }}
            />
          </Grid>
        </Grid>
        <Typography
          className={classes.headings}
          variant="caption"
          display="block"
        >
          {t('about_component.receive_sms_notifications', {
            ns: 'page/sign_up',
          })}
        </Typography>
        <Button
          variant="contained"
          className={classes.button}
          onClick={() => onSubmit()}
        >
          {isLoading || loading ? (
            <CircularProgress size={25} className={classes.progress}/>
          ) : (
            t('next', { ns: 'page/sign_up' })
          )}
        </Button>
      </Container>
    </div>
  );
}
