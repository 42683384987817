import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import LoginToolBar from '../tutor-request-home/components/LoginToolBar';
import TutorCard from '../tutor-request-home/components/SingleTutorCard';
import TutorAbout from '../tutor-request-home/components/SingleTutorAbout';
import { useParams } from 'react-router-dom';
import { useGetTutorExtendedQuery } from '../../app/services/tutor.api';
import BaseLayout from '../common/layouts/BaseLayout';
import PreviewBanner from './sections/PreviewBanner';
import Container from '@material-ui/core/Container';
import Schedule from './sections/Schedule';
import Feedbacks from './sections/Feedbacks';

const useStyles = makeStyles((theme) => ({
  reviewTitle: {
    color: '#384047',
    fontSize: '2rem',
    paddingBottom: '1rem',
  },

  bannerRoot: {
    display: 'none',
    position: 'fixed',
    width: 'calc(100% - 32px)',
    right: '16px',
    zIndex: 20,

    [theme.breakpoints.up('md')]: {
      top: 80,
      width: '420px',
      display: 'block',
    },
  },

  [theme.breakpoints.up('sm')]: {
    right: '24px',
  },

  [theme.breakpoints.up('xl')]: {
    right: '24px',
  },

  contentWrapper: {
    display: 'flex',
    width: '100%',
    gap: 40,
    flexDirection: 'column-reverse',
    paddingTop: '200px',

    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      paddingTop: 0,
    },
  },

  contentLeftSide: {
    width: '100%',

    [theme.breakpoints.up('md')]: {
      paddingTop: '190px',
      flex: 1,
      flexBasis: '945px',
    },
  },

  contentRightSide: {
    display: 'none',

    [theme.breakpoints.up('md')]: {
      flexBasis: '420px',
      display: 'block',
    },
  },

  staticBanner: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
}));

const TutorPage = () => {
  const { id } = useParams();
  const classes = useStyles();

  const [bannerleftOffset, setLeft] = useState(0);

  const skeletonRef = (node) => {
    if (node) {
      const id = setInterval(() => {
        const rect = node.getBoundingClientRect();

        if (rect.left) {
          clearInterval(id);
          setLeft(rect.left);
        }
      }, 50);
    }
  };

  const { data = {}, isLoading, isFetching } = useGetTutorExtendedQuery(id);
  const { data: tutorData = {} } = data;
  const { rating_average, hourlyRate, ratings, video } = tutorData;

  const isContentLoading = isLoading || isFetching;

  return (
    <BaseLayout
      header={<LoginToolBar />}
      isLoading={isContentLoading}
      wrapperStyle={{ backgroundColor: '#edeff0' }}
      withContainer={false}
    >
      {tutorData && (
        <>
          <Box
            pb={6}
            style={{
              backgroundColor: 'white',
              position: 'absolute',
              width: '100%',
            }}
          >
            <Container>
              <TutorCard
                pic={tutorData.imageUrl}
                tutorName={
                  tutorData.firstName ? tutorData.firstName : 'Matthew T.'
                }
                Native={
                  tutorData.nativeLanguage ? tutorData.nativeLanguage : 'Native'
                }
                experience="IELTS preparation teacher,15 years experience. Expert in teaching"
                studentsCount={
                  tutorData.studentsCount ? tutorData.studentsCount : 0
                }
                lessons={tutorData.totalLessons ? tutorData.totalLessons : 0}
                stars={tutorData.rating_average ? tutorData.rating_average : 4}
                hourlyRate={tutorData.hourlyRate ? tutorData.hourlyRate : 20}
                reviewCount={89}
                tutor={tutorData}
                tutorDetail={tutorData}
              />
            </Container>
          </Box>

          <Container
            style={{ zIndex: 2, position: 'relative', paddingBottom: 60 }}
          >
            <Box className={classes.contentWrapper}>
              <Box className={classes.contentLeftSide}>
                <TutorAbout tutorDetail={tutorData} />

                <Box mt="33px">
                  <Schedule pricePerHour={tutorData.hourlyRate ?? 0} />
                </Box>

                <Box mt="33px">
                  <Feedbacks
                    rating={tutorData.rating_average}
                    reviews={tutorData.ratings}
                  />
                </Box>
              </Box>

              <div ref={skeletonRef} className={classes.contentRightSide} />

              <Box className={classes.staticBanner}>
                <PreviewBanner
                  userId={tutorData.userId}
                  video={video}
                  stats={{
                    rating: rating_average,
                    reviewsCount: ratings?.length,
                    hourlyRate,
                  }}
                />
              </Box>
            </Box>
          </Container>
        </>
      )}

      {bannerleftOffset && (
        <Box className={classes.bannerRoot} style={{ left: bannerleftOffset }}>
          <PreviewBanner
            userId={tutorData.userId}
            video={video}
            stats={{
              rating: rating_average,
              reviewsCount: ratings?.length,
              hourlyRate,
            }}
          />
        </Box>
      )}
    </BaseLayout>
  );
};

export default TutorPage;
