import React from 'react';
import { Avatar } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import CardContent from '@material-ui/core/CardContent';
import Box from '@material-ui/core/Box';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import 'moment/locale/uk';

const ReviewItem = ({ publisher, createdAt, comment }) => {
  const { i18n } = useTranslation();
  console.log(i18n.language);

  return (
    <CardContent
      style={{ borderTop: '1px solid #E1E1E1FF', paddingBottom: '5px' }}
    >
      <Box display="flex" alignItems="flex-start" style={{ gap: 40 }}>
        <Avatar
          height={98}
          width={98}
          src={publisher ? publisher?.imageUrl : ''}
        />

        <Box>
          <Typography
            component="h3"
            style={{ fontSize: '16px', color: '#384047' }}
          >
            {publisher ? publisher.fullName : ''}
          </Typography>

          <Typography
            style={{
              fontSize: '16px',
              color: '#384047',
              textTransform: 'capitalize',
            }}
          >
            {moment(createdAt)
              .locale(i18n.language === 'ua' ? 'uk' : i18n.language)
              .format('MMMM DD, yyyy')}
          </Typography>

          <Box mt="18px" style={{ fontSize: '16px', color: '#384047' }}>
            <Typography variant="body2" component="p">
              {comment}
            </Typography>
          </Box>
        </Box>
      </Box>
    </CardContent>
  );
};

export default ReviewItem;
