import React from 'react';
import { Box } from '@material-ui/core';
import TimePicker from 'react-bootstrap-time-picker';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import { useFormContext } from 'react-hook-form';
import IconButton from '@material-ui/core/IconButton';
import { ReactComponent as Trash } from '../../../../../../assets/icons/trash.svg';

const TimeRangeRow = ({
  slot: { startTime, endTime },
  dayKey,
  onDelete,
  onUpdate,
}) => {
  const { t } = useTranslation('page/sign_up');

  const { watch } = useFormContext();

  const handleTimeChange = (key) => (time) => {
    if (![startTime, endTime].includes(time)) {
      onUpdate({ [key]: time });
    }
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      width="100%"
      style={{ gap: '10px' }}
    >
      <Box width="200px" maxWidth="300px">
        <TimePicker
          start="01:00"
          end="24:00"
          step={60}
          disabled={!watch(`${dayKey}.isAvailable`)}
          value={startTime}
          onChange={handleTimeChange('startTime')}
        />
      </Box>

      <Typography>
        {t('availability_component.to', { ns: 'page/sign_up' })}
      </Typography>

      <Box width="200px" maxWidth="300px">
        <TimePicker
          start="01:00"
          end="24:00"
          step={60}
          disabled={!watch(`${dayKey}.isAvailable`)}
          value={endTime}
          onChange={handleTimeChange('endTime')}
        />
      </Box>

      <IconButton onClick={onDelete}>
        <Trash />
      </IconButton>
    </Box>
  );
};

export default TimeRangeRow;
