import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import ReactPlayer from 'react-player';
import CircularProgress from '@material-ui/core/CircularProgress';
import Tick from '../../../../../assets/images/tick.png';
import { useTranslation } from 'react-i18next';
import {
  useGetTutorExtendedQuery,
  useUpdateTutorVideoMutation,
} from '../../../../../app/services/tutor.api';

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: '#FFFFFF',
    padding: '2rem',
    borderRadius: 5,
    marginTop: '3rem',
  },
  headings: {
    color: '#6F757B',
    fontSize: 28,
  },
  button: {
    marginTop: theme.spacing(3),
    backgroundColor: '#FFB600',
    color: 'white',
  },
  buttonUpload: {
    backgroundColor: '#FFB600',
    color: 'white',
  },
  txt1: {
    color: '#6F757B',
    fontSize: 22,
    margin: 0,
    padding: 0,
  },
  txt2: {
    color: '#6F757B',
    fontSize: 15,
    margin: 0,
    padding: 0,
  },
  txt3: {
    color: '#6F757B',
    fontSize: 15,
    margin: 0,
    padding: 0,
    marginLeft: theme.spacing(3),
  },
  txt4: {
    color: '#6F757B',
    fontSize: 18,
    margin: 0,
    padding: 0,
    marginLeft: theme.spacing(10),
  },
  txt5: {
    color: '#384047',
    fontSize: 17,
    margin: 0,
    padding: 0,
  },
  input1: {
    height: '0.3rem',
  },
  buttonNext: {
    marginTop: theme.spacing(5),
    backgroundColor: '#FFB600',
    color: 'white',
  },
  progress: {
    color: '#FFFFFF',
  },
  buttonVideo: {
    backgroundColor: '#F9655E',
  },
  p1: {
    fontSize: 25,
    margin: 0,
    padding: 0,
    color: '#384047',
  },
  p2: {
    fontSize: 17,
    margin: 0,
    padding: 0,
    color: '#707070',
  },
  buttonBack: {
    marginTop: theme.spacing(5),
    marginRight: theme.spacing(2),
    backgroundColor: '#F8F8F8',
    color: '#FFB600',
    borderColor: '#FFB600',
    borderWidth: 1,
    borderStyle: 'solid',
  },
}));
export default function Video(props) {
  const classes = useStyles();
  const [video, setVideo] = useState(() => {
    if (props.defaultVideo) {
      return props.defaultVideo.url;
    }

    return null;
  });
  const [error, setError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [isLoadingVideo, setIsLoadingVideo] = useState(false);
  const [file, setFile] = useState(() => {
    if (props.defaultVideo) {
      return props.defaultVideo.url;
    }

    return null;
  });
  const { t } = useTranslation(['page/sign_up', 'page/settings']);

  const tutorQuery = useGetTutorExtendedQuery(props.tutorId);

  const [updateVideo] = useUpdateTutorVideoMutation();

  useEffect(() => {
    if (tutorQuery.data) {
      const { video } = tutorQuery?.data?.data || {};

      setVideo(video?.url || '');
    }
  }, [tutorQuery.data]);

  const handleFile = (e) => {
    setIsLoadingVideo(true);
    if (!e.target.files[0]) return;

    const file = e.target.files[0];

    if (file.size > (50 * 1024 * 1024)) {
      setError(true);
      setErrorMessage(t('video_component.error_messages.video_max_size') || 'Error message')
      return;
    }

    setFile(file);

    const tmppath = URL.createObjectURL(e.target.files[0]);

    setVideo(tmppath);
    setIsLoadingVideo(false);
  };

  const onSubmit = async () => {
    setError(false);
    setLoading(true);

    if (video === null) {
      setError(true);
      setErrorMessage(t('video_component.error_messages.select_video')  || 'Error message')
      setLoading(false);
    } else {
      const bodyFormData = new FormData();

      if (file) {
        bodyFormData.set('image', file);
      }

      try {
        await updateVideo(bodyFormData).unwrap();

        props.handleNext();
      } catch (e) {
        setError(e?.data?.error || true);
        setErrorMessage(t('video_component.error_messages.select_video')  || 'Error message')
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div style={{ margin: '1rem' }}>
      <Container maxWidth="md" className={classes.container} boxShadow={3}>
        <Typography
          className={classes.headings}
          variant="subtitle1"
          align="left"
          gutterBottom
        >
          {t('video_component.video_introduction')}
        </Typography>
        {error ? (
          <Grid container alignItems="left">
            <p style={{ fontSize: 13, color: 'red' }}>
              {errorMessage}
            </p>
          </Grid>
        ) : null}
        <Grid container spacing={3}>
          <Grid item xs={12} md={7}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography
                  className={classes.txt1}
                  variant="subtitle1"
                  align="left"
                  gutterBottom
                >
                  {t('video_component.record_video')}
                </Typography>
                <Typography
                  className={classes.txt2}
                  variant="subtitle1"
                  align="left"
                  gutterBottom
                >
                  {t('video_component.show_teaching_style')}
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Box
                  display="flex"
                  flexWrap="wrap"
                  alignContent="center"
                  bgcolor="background.paper"
                >
                  {isLoadingVideo ? (
                    <div style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '360px',
                      width: '100%',
                    }}>
                      <CircularProgress
                        style={{ color: '#FFB600' }}
                        size={80}
                      />
                    </div>
                  ) : (
                    <div>
                      {video === null ? null : (<ReactPlayer url={video} width="100%" />)}
                    </div>)}
                </Box>
              </Grid>
              <Grid item xs={12} align="left">
                <div className="upload-btn-wrapper">
                  <button className="btnFilledRed">
                    {t('video_component.upload_a_video')}
                  </button>
                  <input type="file" onChange={handleFile} name="myfile" />
                </div>
                {/* <Button
                  variant='contained'
                  color='secondary'
                  className={classes.buttonVideo}
                  startIcon={<VideocamIcon />}>
                  {t('video_component.upload_a_video')}
                </Button> */}
              </Grid>

              <Grid item xs={12} align="left">
                <p className={classes.p1}>
                  {t('video_component.paste_link_to_video')}
                </p>
                <p className={classes.p2}>
                  {t('video_component.how_to_upload_videos_to')}{' '}
                  <span style={{ color: '#FFB600' }}>Youtube</span> or{' '}
                  <span style={{ color: '#FFB600' }}>Vimeo</span>
                </p>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  style={{ width: '100%' }}
                  id="outlined-read-only-input"
                  placeholder="www.youtube.com/watch?v=ujOfnzW_TcU&list="
                  InputProps={{ classes: { input: classes.input1 } }}
                  value={typeof file === 'string' ? file : ''}
                  onChange={(e) => {
                    setVideo(e.target.value);
                    setFile(e.target.value);
                  }}
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={5}>
            <Grid container>
              <Grid item xs={12}>
                <Typography
                  className={classes.txt1}
                  variant="subtitle1"
                  align="left"
                  gutterBottom
                >
                  {t('video_component.tips_for_a_great_video')}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  className={classes.txt5}
                  variant="subtitle1"
                  align="left"
                  gutterBottom
                >
                  {t('video_component.technical')}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Box display="flex">
                  <Box p={1} style={{}}>
                    <img src={Tick} />
                  </Box>
                  <Box p={1} style={{}}>
                    <p
                      align="left"
                      style={{
                        fontSize: 17,
                        paddingTop: 0,
                        margin: 0,
                        color: '#6F757B',
                      }}
                    >
                      {t('video_component.keep_your_video')}
                    </p>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box display="flex">
                  <Box p={1} style={{ paddingTop: 0 }}>
                    <img src={Tick} />
                  </Box>
                  <Box p={1} style={{ paddingTop: 0 }}>
                    <p
                      align="left"
                      style={{
                        fontSize: 17,
                        paddingTop: 0,
                        margin: 0,
                        color: '#6F757B',
                      }}
                    >
                      {t('video_component.record_horizontal_mode')}
                    </p>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box display="flex">
                  <Box p={1} style={{ paddingTop: 0 }}>
                    <img src={Tick} />
                  </Box>
                  <Box p={1} style={{ paddingTop: 0 }}>
                    <p
                      align="left"
                      style={{
                        fontSize: 17,
                        paddingTop: 0,
                        margin: 0,
                        color: '#6F757B',
                      }}
                    >
                      {t('video_component.position_camera')}
                    </p>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box display="flex">
                  <Box p={1} style={{ paddingTop: 0 }}>
                    <img src={Tick} />
                  </Box>
                  <Box p={1} style={{ paddingTop: 0 }}>
                    <p
                      align="left"
                      style={{
                        fontSize: 17,
                        paddingTop: 0,
                        margin: 0,
                        color: '#6F757B',
                      }}
                    >
                      {t('video_component.use_neutral_lighting')}
                    </p>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box display="flex">
                  <Box p={1} style={{ paddingTop: 0 }}>
                    <img src={Tick} />
                  </Box>
                  <Box p={1} style={{ paddingTop: 0 }}>
                    <p
                      align="left"
                      style={{
                        fontSize: 17,
                        paddingTop: 0,
                        margin: 0,
                        color: '#6F757B',
                      }}
                    >
                      {t('video_component.face_fully_visible')} <br></br>{' '}
                      {t('video_component.except_for_religious_reasons')}
                    </p>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box display="flex">
                  <Box p={1} style={{ paddingTop: 0 }}>
                    <img src={Tick} />
                  </Box>
                  <Box p={1} style={{ paddingTop: 0 }}>
                    <p
                      align="left"
                      style={{
                        fontSize: 17,
                        paddingTop: 0,
                        margin: 0,
                        color: '#6F757B',
                      }}
                    >
                      {t('video_component.no_logos')}
                    </p>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box display="flex">
                  <Box p={1} style={{ paddingTop: 0 }}>
                    <img src={Tick} />
                  </Box>
                  <Box p={1} style={{ paddingTop: 0 }}>
                    <p
                      align="left"
                      style={{
                        fontSize: 17,
                        paddingTop: 0,
                        margin: 0,
                        color: '#6F757B',
                      }}
                    >
                      {t('video_component.no_slideshows')}
                    </p>
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={12}>
                <Typography
                  className={classes.txt5}
                  variant="subtitle1"
                  align="left"
                  gutterBottom
                >
                  {t('video_component.content')}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Box display="flex">
                  <Box p={1} style={{}}>
                    <img src={Tick} />
                  </Box>
                  <Box p={1} style={{}}>
                    <p
                      align="left"
                      style={{
                        fontSize: 17,
                        paddingTop: 0,
                        margin: 0,
                        color: '#6F757B',
                      }}
                    >
                      {t('video_component.greet_your_students_warmly')}
                    </p>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box display="flex">
                  <Box p={1} style={{ paddingTop: 0 }}>
                    <img src={Tick} />
                  </Box>
                  <Box p={1} style={{ paddingTop: 0 }}>
                    <p
                      align="left"
                      style={{
                        fontSize: 17,
                        paddingTop: 0,
                        margin: 0,
                        color: '#6F757B',
                      }}
                    >
                      {t(
                        'video_component.highlight_any_teaching_certification'
                      )}
                    </p>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box display="flex">
                  <Box p={1} style={{ paddingTop: 0 }}>
                    <img src={Tick} />
                  </Box>
                  <Box p={1} style={{ paddingTop: 0 }}>
                    <p
                      align="left"
                      style={{
                        fontSize: 17,
                        paddingTop: 0,
                        margin: 0,
                        color: '#6F757B',
                      }}
                    >
                      {t('video_component.present_experience')}
                    </p>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box display="flex">
                  <Box p={1} style={{ paddingTop: 0 }}>
                    <img src={Tick} />
                  </Box>
                  <Box p={1} style={{ paddingTop: 0 }}>
                    <p
                      align="left"
                      style={{
                        fontSize: 17,
                        paddingTop: 0,
                        margin: 0,
                        color: '#6F757B',
                      }}
                    >
                      {t('video_component.invite_students')}
                    </p>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {props.saveButton ? (
          <Box display="flex" justifyContent="flex-start">
            <Button
              variant="contained"
              className={classes.buttonBack}
              onClick={() => onSubmit()}
            >
              {t('save_settings', { ns: 'page/settings' })}
            </Button>
          </Box>
        ) : (
          <Box display="flex" justifyContent="flex-start">
            <Button
              variant="contained"
              className={classes.buttonBack}
              onClick={props.handleBack}
            >
              {t('back')}
            </Button>

            <Button
              variant="contained"
              className={classes.buttonNext}
              onClick={() => onSubmit()}
            >
              {loading ? <CircularProgress size={25} className={classes.progress}/> : t('next')}
            </Button>
          </Box>
        )}
      </Container>
    </div>
  );
}
