import React, { useMemo } from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Globe from '../../../../assets/images/globe.png';
import WireGlobe from '../../../../assets/images/wireGlobe.png';
import Medal from '../../../../assets/images/medalIcon.png';
import CertifiedIcon from '../../../../assets/images/certifiedIcon.png';
import ProfileIcon from '../../../../assets/images/profileIcon.png';
import CircleIcon from '../../../../assets/images/circleIcon.png';
import { useTranslation } from 'react-i18next';
import FeatureItem from './components/FeatureItem';
import Container from '@material-ui/core/Container';
import Wrapper from '../../../common/components/Wrapper';

const useStyles = makeStyles((theme) => ({
  globeImage: {
    width: 80,
    height: 80,
    marginTop: -40,
  },
  boldText: {
    color: '#040916',
    fontFamily: 'Roboto',
    fontSize: 40,
    fontWeight: 700,

    marginTop: 14,

    [theme.breakpoints.down('sm')]: {
      fontSize: 20,
    },

    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
  },
  wireGlobeImage: {
    marginTop: 48,
    width: '100%',
  },

  simpleText: {
    color: 'rgba(4, 9, 22, 0.80)',
    marginTop: 0,
    fontSize: 16,
  },
}));

const WorldComfortZone = () => {
  const classes = useStyles();
  const { t, i18n } = useTranslation('page/landing');

  const features = useMemo(
    () => [
      {
        image: Medal,
        title: t('post_request_card_component.expert_tutors_label'),
        description: t(
          'post_request_card_component.find_native_speakers_and_tutors'
        ),
      },
      {
        image: CertifiedIcon,
        title: t('post_request_card_component.verified_profiles_label'),
        description: t('post_request_card_component.check_tutor_profile'),
      },
      {
        image: ProfileIcon,
        title: t('post_request_card_component.learn_anytime'),
        description: t('post_request_card_component.online_lessons'),
      },
      {
        image: CircleIcon,
        title: t('post_request_card_component.affordable_prices'),
        description: t('post_request_card_component.choose_experienced_tutor'),
      },
    ],
    [i18n.language]
  );

  const [firstTitleWord, ukrainian, ...restWords] = t(
    'post_request_card_component.make_world_your_comfort_zone_label'
  ).split(' ');

  return (
    <Grid container>
      <Grid xs={10} style={{ margin: 'auto' }}>
        <Box
          mt="80px"
          borderRadius="30px"
          style={{ background: 'white', padding: '0 110px 56px' }}
        >
          <img className={classes.globeImage} src={Globe}></img>

          <p className={classes.boldText}>
            {firstTitleWord}{' '}
            <span style={{ color: '#FFB600' }}>{ukrainian}</span>{' '}
            {restWords.join(' ')}
          </p>

          <p className={classes.simpleText}>
            {t(
              'post_request_card_component.speak_naturally_with_online_tutors_label'
            )}
          </p>

          <img className={classes.wireGlobeImage} src={WireGlobe}></img>

          <Box mt="40px">
            <Typography
              style={{
                fontSize: '40px',
                fontWeight: 700,
              }}
            >
              {t('post_request_card_component.you_will_get')}
            </Typography>
          </Box>

          <Box
            pt="32px"
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(2, 1fr)',
              marginTop: 32,
              rowGap: '56px',
              columnGap: '80px',
              maxWidth: '1000px',
              margin: 'auto',
            }}
          >
            {features.map((feature) => (
              <FeatureItem start key={feature.title} {...feature} />
            ))}
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default WorldComfortZone;
