import React from 'react';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

const PrimaryButton = ({ children, isLoading, disabled, ...props }) => {
  return (
    <Button variant="contained" {...props} disabled={disabled || isLoading}>
      {isLoading ? <CircularProgress size={25} /> : children}
    </Button>
  );
};

export default PrimaryButton;
