import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Header from './components/Header';
import { Box } from '@material-ui/core';
import { ReactComponent as UkraineDots } from '../../../../assets/images/ukraine-dots.svg';
import { ReactComponent as UkraineCover } from '../../../../assets/images/ukraine-cover.svg';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import SideContainer from '../../../common/components/SideContainer';
import SearchSubjectInput from './components/SearchSubjectInput';
import StyledButton from '../../../common/components/Buttons/StyledButton';
import { Routes } from '../../../../router/routes';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  heroWrapper: {
    backgroundColor: '#040916',
    padding: '16px 0 0',
    overflow: 'hidden',
    position: 'relative',
    zIndex: 1,

    [theme.breakpoints.up('md')]: {
      padding: '38px 0 0',
    },
  },

  heroContentWrapper: {
    ...theme.mixins.rowStandard,
    marginTop: 30,
    gap: 50,
    alignItems: 'flex-start',
    flexDirection: 'column',
    height: '100%',

    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      marginTop: 90,
    },
  },

  dotsUkraine: {
    position: 'absolute',
    top: 330,
    right: -50,
    width: 670,
    height: 'auto',

    [theme.breakpoints.up('md')]: {
      top: 50,
      right: -300,
      width: 1070,
      height: 631,
    },

    [theme.breakpoints.up('lg')]: {
      width: 1170,
      height: 'auto',
      top: 52,
      right: -200,
    },

    [theme.breakpoints.up('xl')]: {
      width: 1470,
      height: 831,
      top: 32,
      right: -300,
    },
  },

  coverUkraineWrapper: {
    alignSelf: 'center',
    marginTop: 40,

    [theme.breakpoints.up('md')]: {
      alignSelf: 'flex-start',
      marginTop: 0,
    },
    zIndex: 2,
  },

  coverUkraine: {
    marginTop: -5,

    width: '90%',
    height: 'auto',

    [theme.breakpoints.up('md')]: {
      width: 550,
      height: 350,
    },

    [theme.breakpoints.up('xl')]: {
      width: 674,
      height: 474,
    },
  },

  primarySlogan: {
    color: theme.palette.common.white,
    textAlign: 'left',

    fontSize: '32px',
    fontWeight: 800,

    [theme.breakpoints.up('lg')]: {
      fontSize: '45px',
      fontWeight: 900,
    },

    [theme.breakpoints.up('xl')]: {
      fontSize: '56px',
      fontWeight: 900,
    },
  },

  description: {
    color: '#A2A2A2',
    textAlign: 'left',
    opacity: 0.8,

    fontSize: '19px',

    [theme.breakpoints.up('lg')]: {
      fontSize: '21px',
      fontWeight: 300,
    },

    [theme.breakpoints.up('xl')]: {
      fontSize: '24px',
      fontWeight: 300,
    },
  },

  searchWrapper: {
    marginTop: 40,
  },
}));

const Hero = () => {
  const history = useHistory();
  const { t } = useTranslation('page/landing');

  const {
    heroWrapper,
    heroContentWrapper,
    dotsUkraine,
    coverUkraine,
    coverUkraineWrapper,
    primarySlogan,
    description,
    searchWrapper,
  } = useStyles();

  const [pre, after] = t('cover_component.become_fluent').split(
    'найкращими репетиторами'
  );

  return (
    <>
      <div className={heroWrapper}>
        <Box className={dotsUkraine}>
          <UkraineDots />
        </Box>

        <SideContainer maxWidth="xl" style={{ height: '100%' }}>
          <Header />

          <Box className={heroContentWrapper}>
            <Box maxWidth="625px">
              <Typography variant="h1" className={primarySlogan}>
                {pre}{' '}
                {after?.length && (
                  <span style={{ color: '#FFB600' }}>
                    найкращими репетиторами
                  </span>
                )}{' '}
                {after}
              </Typography>

              <Box mt="24px">
                <Typography variant="h3" className={description}>
                  {t('cover_component.choose_best_tutor')}
                </Typography>
              </Box>

              <Box
                className={searchWrapper}
                display="flex"
                justifyContent="flex-start"
              >
                <StyledButton
                  variant="filled"
                  onClick={() => history.push(Routes.RequestTutorHome)}
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    style={{ gap: '12px' }}
                    padding="0 30px"
                  >
                    <Typography
                      style={{
                        fontSize: '20px',
                        fontWeight: 700,
                        color: '#fff',
                      }}
                    >
                      {t('start')}
                    </Typography>

                    <Box>
                      <svg
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_144_26)">
                          <path
                            d="M21.3467 14.6667H5.33334V17.3333H21.3467V21.3333L26.6667 16L21.3467 10.6667V14.6667Z"
                            fill="white"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_144_26">
                            <rect width="32" height="32" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </Box>
                  </Box>
                </StyledButton>
              </Box>
            </Box>

            <Box className={coverUkraineWrapper}>
              <Box className={coverUkraine}>
                <UkraineCover />
              </Box>
            </Box>
          </Box>
        </SideContainer>
      </div>

      <div
        style={{
          width: 'calc(100vw + 500px)',
          transform: 'translateX(-50%)',
          left: '50%',
          height: '600px',
          borderRadius: '110%',
          background: '#040916',
          position: 'relative',
          top: '-480px',
          marginBottom: '-500px',
        }}
      ></div>
    </>
  );
};

export default Hero;
