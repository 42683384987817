import { combineReducers } from '@reduxjs/toolkit';

import auth from './slices/auth.slice';
import tutorInformation from './slices/tutorInformation.slice';
import tutorLesson from './slices/tutorLesson.slice';
import tutorAvailability from './slices/availability.slice';
import languageReducer from './rootReducer';
import api from './services/base';

export const reducer = combineReducers({
  api: api.reducer,
  auth,
  tutorInformation,
  tutorLesson,
  tutorAvailability,
  languageReducer,
});
