import React from 'react';
import Nav from '../components/Headers/topNav';
import Footer from '../components/Footer';

import Container from '@material-ui/core/Container';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Box } from '@material-ui/core';

const BaseLayout = ({
  children,
  header,
  withContainer = true,
  containerStyles,
  subheader,
  isLoading,
  wrapperStyle,
}) => {
  return (
    <Box style={wrapperStyle}>
      {header || <Nav />}
      {subheader}

      {isLoading ? (
        <Box
          flex={1}
          width="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
          style={{
            minHeight: subheader ? 'calc(100vh - 114px)' : 'calc(100vh - 64px)',
          }}
        >
          <CircularProgress size={30} />
        </Box>
      ) : withContainer ? (
        <Container
          style={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            minHeight: subheader ? 'calc(100vh - 114px)' : 'calc(100vh - 64px)',
            ...containerStyles,
          }}
        >
          <Box py={4}>{children}</Box>
        </Container>
      ) : (
        children
      )}

      <div>
        <Footer />
      </div>
    </Box>
  );
};

export default BaseLayout;
