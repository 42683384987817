import api from './base';

const chatsAPI = api.injectEndpoints({
  endpoints: (builder) => ({
    getChats: builder.query({
      query: (params) => ({ url: 'chats/', params }),
    }),

    getChatMessages: builder.query({
      query: ({chatId, params}) => ({ url: `chats/${chatId}/messages/`, params }),
    }),

    createOrGetChat: builder.mutation({
      query: (body) => ({
        url: 'chats/',
        method: 'POST',
        body,
      }),
    }),
        
    createOrGetSupportChat: builder.mutation({
      query: () => ({
        url: 'chats/support/',
        method: 'POST',
      }),
    }),
  }),
});

export const {
  useGetChatsQuery,
  useGetChatMessagesQuery,
  useCreateOrGetChatMutation,
  useCreateOrGetSupportChatMutation,
} = chatsAPI;
