import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Box, Button, Grid, Typography } from '@material-ui/core';
import StarIcon from '@material-ui/icons/Star';
import { TutorCardStyle } from '../styles/TutorCardStyle';
import BlankProfile from '../../../assets/images/blankProfile.png';
import { ReactComponent as BlankProfileSmallSvg } from '../../../assets/images/blankProfileSmall.svg';
import { ReactComponent as EducationSvg } from '../../../assets/images/education.svg';
import { ReactComponent as TargetSvg } from '../../../assets/images/target.svg';
import { ReactComponent as DialogSvg } from '../../../assets/images/dialog.svg';
import { ReactComponent as ZipperSvg } from '../../../assets/images/zipper.svg';
import { ReactComponent as MailSvg } from '../../../assets/images/mail.svg';
import { useTranslation } from 'react-i18next';
import { Routes } from '../../../router/routes';
import {useCreateOrGetChatMutation} from '../../../app/services/chat.api';

const getCurrencySymbol = (currencyName) => {
  switch (currencyName) {
  case 'USD':
    return '$';
  case 'UAH':
    return '₴';
  case 'Yuan':
    return '¥';
  case 'Euro':
    return '€';
  default:
    return '';
  }
};

export default function TutorCard({ tutor, userId }) {
  const classes = TutorCardStyle();
  const history = useHistory();
  const { t } = useTranslation('page/request_tutor_home');
  const {
    _id,
    firstName,
    lastName,
    imageUrl,
    subjectTaught,
    nativeLanguage,
    headline,
    description,
    rating_average,
    ratings,
    hourlyRate,
    userId: tutorUserId,
    totalLessons,
    currency,
    promotion,
  } = tutor;
  const firstNameAndInitial = `${firstName} ${lastName?.charAt(0)}.`;
  const [ createOrGetChat ] = useCreateOrGetChatMutation();

  const handleCreateOrGetChat = async () => {
    try {
      const result = await createOrGetChat({ userId: tutorUserId }).unwrap();
      history.push('/studentMyMessages', { state: result });
    } catch (err) { /* empty */ }
  };

  return (
    <>
      <div className={classes.card}>
        <Grid container>
          <Grid item xs={12} md={3}>
            <Box className={classes.avatarWrap}>
              <img
                className={classes.avatar}
                src={imageUrl ?? BlankProfile}
                alt="avatar"
              />
              {promotion?.status && (
                <div className={classes.avatarPromoted}>
                  TOP
                </div>
              )}
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Grid container direction="column" alignItems="flex-start">
              <Grid item>
                <Box py={1}>
                  <Link
                    className={classes.nameText}
                    to={`${Routes.TutorPage}/${_id}`}
                  >
                    {firstNameAndInitial}
                  </Link>
                </Box>
              </Grid>
              <Grid item container>
                <Box mt={1} mb={0.2}>
                  <Typography align="left" className={classes.languageText}>
                    <EducationSvg
                      style={{
                        verticalAlign: 'text-top',
                        marginRight: '0.5rem',
                      }}
                    />
                    {subjectTaught}
                  </Typography>
                </Box>
              </Grid>
              <Grid item container>
                <Box my={0.2}>
                  <Typography align="left" className={classes.languageText}>
                    <BlankProfileSmallSvg
                      style={{
                        verticalAlign: 'baseline',
                        marginRight: '0.5rem',
                      }}
                    />
                    {t('tutor_card_component.active_students', { count: 0 })}
                  </Typography>
                </Box>
              </Grid>
              <Grid item container>
                <Box my={0.2}>
                  <Typography align="left" className={classes.languageText}>
                    <TargetSvg
                      style={{
                        verticalAlign: 'baseline',
                        marginRight: '0.5rem',
                      }}
                    />
                    {t('tutor_card_component.lessons', {
                      count: totalLessons,
                    })}
                  </Typography>
                </Box>
              </Grid>
              <Grid item container>
                <Box mt={0.2} mb={2}>
                  <Typography align="left" className={classes.languageText}>
                    <DialogSvg
                      style={{
                        verticalAlign: 'baseline',
                        marginRight: '0.5rem',
                      }}
                    />
                    <span className={classes.languageText}>
                      {t('tutor_card_component.speaks')}: {nativeLanguage}
                    </span>
                    <span className={classes.lessonInfoNativeText}>
                      {t('tutor_card_component.native')}
                    </span>
                  </Typography>
                </Box>
              </Grid>
              <Grid item>
                <Box pt={0.5} pr={2}>
                  <Typography align="left" className={classes.headlineText}>
                    {headline}
                  </Typography>
                  <Typography align="left" className={classes.descriptionText}>
                    {description}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} md={3}>
            <Grid container item>
              <Grid item xs={6}>
                <Box pt={2}>
                  <Typography align="center" className={classes.starCount}>
                    <StarIcon className={classes.starIcon}></StarIcon>
                    {rating_average ? parseFloat(rating_average).toFixed(1) : 0}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box pt={2}>
                  <Typography align="center" className={classes.priceCount}>
                    {getCurrencySymbol(currency)} {hourlyRate}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box pt={2}>
                  <Typography align="center" className={classes.ratingText}>
                    <span className={classes.ratingCount}>
                      {ratings.length}{' '}
                    </span>
                    {t('tutor_card_component.reviews')}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box pt={2}>
                  <Typography align="center" className={classes.reviewText}>
                    {t('tutor_card_component.per_hour')}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            <Grid container item>
              <Grid item xs={12}>
                <Box pt={4}>
                  <Button
                    className={classes.btnDetail}
                    component={Link}
                    to={`${Routes.TutorPage}/${_id}`}
                    startIcon={<ZipperSvg />}
                  >
                    {t('tutor_card_component.detail')}
                  </Button>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box pt={1}>
                  <Button
                    className={classes.btnSendMessage}
                    startIcon={<MailSvg />}
                    onClick={handleCreateOrGetChat}
                  >
                    {t('tutor_card_component.send_message')}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  );
}
