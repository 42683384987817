import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import rootSaga from './rootSaga';
import { reducer } from './reducers';
import api from './services/base';
import { persistStore } from 'redux-persist';

let sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  reducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false })
      .concat(api.middleware)
      .concat(sagaMiddleware),
});

sagaMiddleware.run(rootSaga);
persistStore(store);

export default store;
