export function formatAvailabilityTime(seconds) {
  let hours = Math.floor(seconds / 3600);
  let minutes = Math.floor((seconds % 3600) / 60);

  // Formatting the hours and minutes with leading zeros if needed
  let formattedHours = hours.toString().padStart(2, '0');
  let formattedMinutes = minutes.toString().padStart(2, '0');

  let timeString = `${formattedHours}:${formattedMinutes}`;
  return timeString;
}
