import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useTutorLinks from '../../../../hooks/useTutorLinks';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'none',

    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
  },
  links: {
    marginBottom: 0,
    marginLeft: 15,
    fontSize: 13,
    color: '#AAB5BC',
    cursor: 'pointer',
  },
  linkActive: {
    marginBottom: 0,
    marginLeft: 15,
    color: 'white',
    fontSize: 13,
    fontWeight: 'bold',
    borderBottom: '1px solid white',
    cursor: 'pointer',
  },
}));

export default function SubHeader(props) {
  const history = useHistory();
  const classes = useStyles();
  const tutorLinks = useTutorLinks();

  const onClick = (route) => () => {
    history.push(route);
  };

  return (
    <div className={classes.root} style={{ background: '#384047' }}>
      <Grid
        container
        direction="row"
        style={{ height: 50, paddingLeft: 20 }}
        alignItems="center"
        justify="flex-start"
      >
        {tutorLinks.map(({ name, tabName, link }) => (
          <p
            key={link}
            className={
              props.for === tabName ? classes.linkActive : classes.links
            }
            onClick={onClick(link)}
          >
            {name}
          </p>
        ))}
      </Grid>
    </div>
  );
}
