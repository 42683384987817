import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { ReactComponent as Star } from '../../../../../assets/icons/star.svg';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  reviewsNumber: {
    fontWeight: '500',
    color: theme.palette.common.black,
  },
  reviewsText: {
    display: 'inline',
    fontWeight: '500',
    textTransform: 'lowercase',
  },
  ratingText: {
    fontWeight: '500',
    color: theme.palette.grey['600'],
    marginLeft: '5px',
  },
  hoursRate: { color: theme.palette.grey['600'] },
}));

const StatsBlock = ({ rating = 0, reviewsCount = 0, hourlyRate = 0 }) => {
  const { t } = useTranslation('page/single_tutor');
  const { ratingText, reviewsNumber, reviewsText, hoursRate } = useStyles();

  return (
    <Box display="flex" justifyContent="space-between" px="55px" width="100%">
      <Box display="inherit" flexDirection="column" alignItems="center">
        <Box display="inherit" alignItems="center">
          <Star />

          <Typography variant="button" className={ratingText}>
            {parseFloat(rating).toFixed(1)}
          </Typography>
        </Box>

        <Box mt="12px" whiteSpace="nowrap">
          <Typography variant="body1" className={reviewsNumber}>
            {reviewsCount}{' '}
            <Typography className={reviewsText}>{t('reviews')}</Typography>
          </Typography>
        </Box>
      </Box>

      <Box display="inherit" flexDirection="column" alignItems="center">
        <Typography variant="button" className={hoursRate}>
          ₴ {hourlyRate}
        </Typography>

        <Box mt="12px" whiteSpace="nowrap">
          <Typography variant="body1">
            {t('single_tutor_video_card_component.per_hour')}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default StatsBlock;
