import React, { FC, ReactNode } from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import Globe from '../../../../assets/images/globe.png';
import { makeStyles } from '@material-ui/core/styles';
import PrimaryButton from '../../../common/components/Buttons/PrimaryButton';
import StyledButton from '../../../common/components/Buttons/StyledButton';

type Props = {
  title: ReactNode;
  items: { title: string; description: string }[];
  description?: ReactNode;
  buttonName: string;
  onClick: () => void;
  id?: string;
};

const useStyles = makeStyles((theme) => ({
  globeImage: {
    width: 80,
    height: 80,
    marginTop: -40,
  },
  boldText: {
    color: '#040916',
    fontFamily: 'Roboto',
    fontSize: 40,
    fontWeight: 700,

    marginTop: 14,

    [theme.breakpoints.down('sm')]: {
      fontSize: 20,
    },

    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
  },

  simpleText: {
    color: 'rgba(4, 9, 22, 0.80)',
    marginTop: 0,
    fontSize: 16,
  },
}));

const BecomeLayout: FC<Props> = ({
  id,
  title,
  items,
  buttonName,
  description,
  onClick,
}) => {
  const classes = useStyles();

  return (
    <div id={id}>
      <Grid container>
        <Grid xs={10} style={{ margin: 'auto' }}>
          <Box
            mt="80px"
            borderRadius="30px"
            style={{ background: 'white', padding: '0 84px 56px' }}
          >
            <img className={classes.globeImage} src={Globe}></img>

            <p className={classes.boldText}>{title}</p>

            <Box
              mt="40px"
              display="flex"
              alignItems="center"
              style={{ gap: '38px' }}
            >
              <Box>
                <svg
                  width="12"
                  height="319"
                  viewBox="0 0 12 319"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M6 6L6.00001 307" stroke="#FFB600" />
                  <circle cx="6" cy="6" r="6" fill="#FFB600" />
                  <circle cx="6" cy="159" r="6" fill="#FFB600" />
                  <circle cx="6" cy="313" r="6" fill="#FFB600" />
                </svg>
              </Box>

              <Box
                width="100%"
                display="flex"
                flexDirection="column"
                style={{ gap: '40px' }}
              >
                {items.map(({ title, description }, index) => (
                  <Box
                    borderRadius="30px"
                    style={{
                      background: 'rgba(255, 182, 0, 0.15)',
                      padding: '24px 40px',
                      display: 'flex',
                      gap: '48px',
                      width: '100%',
                    }}
                  >
                    <Typography
                      style={{
                        fontSize: '40px',
                        fontWeight: 700,
                      }}
                    >
                      0{index + 1}
                    </Typography>

                    <Box>
                      <Typography
                        style={{
                          textAlign: 'left',
                          fontSize: '32px',
                          fontWeight: 500,
                        }}
                      >
                        {title}
                      </Typography>

                      <Typography
                        style={{
                          textAlign: 'left',
                          fontSize: '16px',
                          fontWeight: 400,
                        }}
                      >
                        {description}
                      </Typography>
                    </Box>
                  </Box>
                ))}
              </Box>
            </Box>

            {description}

            <Box mt="47px">
              <StyledButton onClick={onClick} variant="filled">
                <Typography
                  style={{
                    fontSize: '20px',
                    fontWeight: 700,
                    color: 'white',
                  }}
                >
                  {buttonName}
                </Typography>
              </StyledButton>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default BecomeLayout;
