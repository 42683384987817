import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import { Box } from '@material-ui/core';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import Typography from '@material-ui/core/Typography';
import TimeRangeRow from './TimeRangeRow';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Plus } from '../../../../../../assets/icons/plus.svg';
import IconButton from '@material-ui/core/IconButton';
import { getDefaultSlot } from '../constants/days';

const DayAvailabilityStack = ({ dayKey }) => {
  const { control } = useFormContext();
  const { t } = useTranslation('common/days_of_week');

  const { fields, remove, append, update } = useFieldArray({
    control,
    name: `${dayKey}.slots`,
  });

  const handleAddTimeRange = () => append(getDefaultSlot());

  return (
    <Box>
      <Box display="flex" alignItems="center">
        <Controller
          control={control}
          name={`${dayKey}.isAvailable`}
          render={({ field: { value, onChange } }) => (
            <Checkbox
              id={dayKey}
              color="primary"
              checked={value}
              onChange={onChange}
            />
          )}
        />

        <Typography
          component="label"
          variant="h6"
          for={dayKey}
          style={{
            textTransform: 'capitalize',
            fontWeight: 400,
            color: '#6f757b',
          }}
        >
          {t(dayKey.slice(0, 3))}
        </Typography>
      </Box>

      <Box display="flex" alignItems="flex-end" justifyContent="flex-start">
        <Box display="flex" flexDirection="column" style={{ gap: 10 }}>
          {fields.map((slot, index) => (
            <TimeRangeRow
              key={slot.id}
              dayKey={dayKey}
              slot={slot}
              onDelete={() => remove(index)}
              onUpdate={(newSlot) => update(index, { ...slot, ...newSlot })}
            />
          ))}
        </Box>

        <IconButton disabled={fields.length >= 24} onClick={handleAddTimeRange}>
          <Plus />
        </IconButton>
      </Box>
    </Box>
  );
};

export default DayAvailabilityStack;
