import React, { useState, useEffect } from 'react';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import NavBar from '../../common/components/Headers/topNav';
import Footer from '../../common/components/Footer';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { useConfirmEmailMutation } from '../../../app/services/auth.api';
import BaseLayout from '../../common/layouts/BaseLayout';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const ResetPassword = () => {
  const classes = useStyles();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();

  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);

  const [code, setCode] = useState('');

  const [showError, setShowError] = useState(false);

  const [confirmEmail, { isLoading: loading }] = useConfirmEmailMutation();

  const { t } = useTranslation('page/confirm_email');

  const body = (
    <div style={modalStyle} className={classes.paper}>
      <h2 id="simple-modal-title">{t('email_verified')}</h2>

      <p id="simple-modal-description">{t('email_verified_successfully')}</p>

      <Button
        variant="contained"
        style={{
          color: 'white',
          backgroundColor: 'green',
          marginRight: '2rem',
        }}
        onClick={() => history.push('/login')}
      >
        {t('go_to_login')}
      </Button>
    </div>
  );
  const handleConfirmEmail = async () => {
    setShowError(false);

    try {
      await confirmEmail({ email: id, verifyCode: Number(code) }).unwrap();
      setOpen(true);
    } catch (e) {
      setShowError(true);
    }
  };

  return (
    <BaseLayout header={<NavBar />}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: '3rem',
        }}
      >
        <h2>{t('please_waith_confirming_account')}</h2>
      </div>

      <div style={{ height: '100vh', maxWidth: '300px', margin: 'auto' }}>
        <div>
          <p style={{ textAlign: 'left', marginTop: '1rem' }}>
            {t('verify_code')}
          </p>

          <input
            style={{ width: '100%', height: 45, padding: 10 }}
            value={code}
            onChange={(e) => setCode(e.target.value)}
            type="text"
          ></input>

          <Button
            onClick={handleConfirmEmail}
            style={{
              height: '100%',
              width: '100%',
              padding: '10px',
              background: 'none',
              border: 'none',
            }}
          >
            {loading ? <CircularProgress size={25} /> : t('verify')}
          </Button>

          {showError && (
            <>
              <p style={{ color: 'red' }}>{t('unable_verify_email')}</p>
              <Button
                onClick={() => history.push('/login')}
                style={{
                  height: '100%',
                  width: '100%',
                  padding: '10px',
                  background: 'none',
                  border: 'none',
                }}
              >
                {t('go_to_login')}
              </Button>
            </>
          )}
        </div>
      </div>

      <Modal
        open={open}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </BaseLayout>
  );
};

export default ResetPassword;
