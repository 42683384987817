import React from 'react';
import { Box, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  statWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: ' 16px',
    padding: '20px 60px',
    flex: 1,
    '&:not(:last-child)': {
      borderRight: '1px solid #A2A2A2',
    },
  },

  title: {
    color: '#040916',
    fontSize: '18px',
    fontWeight: 500,
    textAlign: 'left',
    maxWidth: 190,
  },
  number: {
    color: '#040916',
    fontSize: '56px',
    fontWeight: 700,
  },
}));

const Stats = () => {
  const classes = useStyles();

  return (
    <Grid container>
      <Grid item md={1} lg={1} xl={1}></Grid>

      <Grid
        item
        md={10}
        lg={10}
        xl={10}
        sm={12}
        xs={12}
        style={{ margin: '1rem 0', display: 'flex' }}
      >
        <Box className={classes.statWrapper}>
          <Typography className={classes.number}>10+</Typography>
          <Typography className={classes.title}>
            кваліфікованих викладачів
          </Typography>
        </Box>{' '}
        <Box className={classes.statWrapper}>
          <Typography className={classes.number}>30+</Typography>
          <Typography className={classes.title}>учнів</Typography>
        </Box>{' '}
        <Box className={classes.statWrapper}>
          <Typography className={classes.number}>30+</Typography>
          <Typography className={classes.title}>відгуків</Typography>
        </Box>
      </Grid>

      <Grid item md={1} lg={1} xl={1}></Grid>
    </Grid>
  );
};

export default Stats;
