import React from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '13px 15px',
    width: '100%',
    cursor: 'pointer',
    background: ({ isActive }) => (isActive ? '#dadfe1' : 'transparent'),

    color: '#384047',
    fontWeight: 500,
    fontSize: '18px',

    [theme.breakpoints.up('md')]: {
      fontSize: '22px',
      padding: '17px 20px',
    },
  },
}));

const SettingMenuItem = ({ isActive, children, onClick }) => {
  const classes = useStyles({ isActive });

  return (
    <Box className={classes.root} onClick={onClick}>
      {children}
    </Box>
  );
};

export default SettingMenuItem;
