import { ValidationMessages } from '../../../../common/constants/messages';

const MIN_AMOUNT = 1;
const MAX_AMOUNT = 1000;

const MIN_BANK_NAME_LENGTH = 3;
const MAX_BANK_NAME_LENGTH = 30;

const MIN_OWNER_NAME_LENGTH = 3;
const MAX_OWNER_NAME_LENGTH = 30;

export const getAmountRules = ({ totalEarnings }) => ({
  validate: (value) => {
    if (value.includes('.')) {
      return ValidationMessages.invalidAmount;
    }

    if (parseInt(value) > parseInt(totalEarnings)) {
      return ValidationMessages.notEnoughMoney;
    }

    if (parseInt(value) < MIN_AMOUNT) {
      return ValidationMessages.minValue(MIN_AMOUNT, 'amount');
    }

    if (parseInt(value) > MAX_AMOUNT) {
      return ValidationMessages.maxValue(MAX_AMOUNT, 'amount');
    }

    return true;
  },
});

export const getCreditCardRules = () => ({
  validate: (value) => {
    if (value?.length < 19) {
      return ValidationMessages.invalidCard;
    }

    return true;
  },
});

export const getBankNameRules = () => ({
  minLength: {
    value: MIN_BANK_NAME_LENGTH,
    message: ValidationMessages.minLength(MIN_BANK_NAME_LENGTH),
  },
  maxLength: {
    value: MAX_BANK_NAME_LENGTH,
    message: ValidationMessages.maxLength(MAX_BANK_NAME_LENGTH),
  },
});

export const getOwnerNameRules = () => ({
  minLength: {
    value: MIN_OWNER_NAME_LENGTH,
    message: ValidationMessages.minLength(MIN_OWNER_NAME_LENGTH),
  },
  maxLength: {
    value: MAX_OWNER_NAME_LENGTH,
    message: ValidationMessages.maxLength(MAX_OWNER_NAME_LENGTH),
  },
});
