import React from 'react';
import useModalStyles from './styles/modal';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import { default as MuiModal } from '@material-ui/core/Modal';
import Typography from '@material-ui/core/Typography';
import { Box } from '@material-ui/core';
import PrimaryButton from '../Buttons/PrimaryButton';

const Modal = ({ open, onClose, title, children, primaryButton }) => {
  const classes = useModalStyles();

  return (
    <MuiModal open={open}>
      <div className={classes.modalLessonWrap}>
        <Box
          style={{ justifyContent: 'space-between', gap: '30px' }}
          className={classes.modalLessonHeaderWrap}
        >
          {title && <Typography variant="h5">{title}</Typography>}

          <CloseIcon
            className={classes.modalLessonCloseIcon}
            onClick={onClose}
          />
        </Box>

        <div className={classes.modalLessonBodyWrap}>{children}</div>

        <div className={classes.modalLessonBtnWrap}>
          {primaryButton && (
            <PrimaryButton
              isLoading={primaryButton.isLoading}
              className={classes.modelLessonBtnContinue}
              onClick={primaryButton.onClick}
              disabled={primaryButton.disabled}
            >
              {primaryButton.name}
            </PrimaryButton>
          )}
        </div>
      </div>
    </MuiModal>
  );
};

export default Modal;
