import { useState } from 'react';

const useToggle = (def = false) => {
  const [state, setState] = useState(def);

  const open = () => setState(true);
  const close = () => setState(false);
  const toggle = () => setState((prev) => !prev);

  return [state, { open, close, toggle }];
};

export default useToggle;
