import React, { useMemo } from 'react';
import Nav from '../common/components/Headers/topNav';
import SubHeaderTutor from '../common/components/Headers/SubHeaderTutor';
import 'react-calendar/dist/Calendar.css';
import { Box } from '@material-ui/core';
import BaseLayout from '../common/layouts/BaseLayout';
import Menu from './components/Menu';
import BookingTable from './components/BookingTable';
import generateDateString from './helpers/generateDateString';
import {
  useGetScheduleQuery,
  useUpdateScheduleMutation,
} from '../../app/services/schedule';
import { useSelector } from 'react-redux';
import { selectUser } from '../../app/slices/auth.slice';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    minHeight: 'calc(100vh - 114px)',

    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
  },

  menu: {
    [theme.breakpoints.up('md')]: {
      flexBasis: '400px',
    },
  },

  calendar: {
    [theme.breakpoints.up('md')]: {
      flexBasis: '1520px',
    },
  },
}));

export default function TutorAvailability() {
  const classes = useStyles();
  const { schedule: scheduleId } = useSelector(selectUser);

  const { data, isLoading, isFetchng } = useGetScheduleQuery(
    { scheduleId },
    { refetchOnMountOrArgChange: true }
  );
  const schedule = data ?? {};

  const [updateSchedule, updateState] = useUpdateScheduleMutation();

  const slots = useMemo(() => {
    if (schedule) {
      const { tutorId, _id, ...scheduleDays } = schedule;

      return Object.entries(scheduleDays).reduce((acc, [day, slots]) => {
        return [
          ...acc,
          ...slots.map(({ from, to }) => ({
            start: generateDateString(day, from),
            end: generateDateString(day, to),
          })),
        ];
      }, []);
    }

    return [];
  }, [schedule, data]);

  const handleToggleSlot = async ({ day, ...time }) => {
    const { tutorId, _id, ...scheduleDays } = schedule;

    const slots = JSON.parse(JSON.stringify(scheduleDays[day] || []));

    const timeSlotIndex = scheduleDays[day]?.findIndex(({ from, to }) => {
      return time.from === from && time.to === to;
    });

    if (timeSlotIndex === -1) {
      slots.push(time);
    } else {
      slots.splice(timeSlotIndex, 1);
    }

    scheduleDays[day] = slots;

    Object.keys(scheduleDays).forEach((day) => {
      scheduleDays[day] = scheduleDays[day].map(({ from, to }) => ({
        from,
        to,
      }));
    });

    await updateSchedule({ scheduleId, ...scheduleDays });
  };

  return (
    <>
      <BaseLayout
        header={<Nav />}
        subheader={<SubHeaderTutor for="TutorAvailability" />}
        withContainer={false}
        isLoading={isLoading || isFetchng || updateState.isLoading}
      >
        <Box className={classes.wrapper}>
          <Box className={classes.menu}>
            <Menu />
          </Box>

          <Box className={classes.calendar}>
            <BookingTable onToggle={handleToggleSlot} slots={slots} />
          </Box>
        </Box>
      </BaseLayout>
    </>
  );
}
