import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    borderRadius: '50px',
    padding: '17px 23px',
    border: '1px solid #ffb600',
    outline: 'none !important',
    fontSize: '16px',
    fontWeight: 400,

    background: ({ variant }) =>
      variant === 'outline' ? '#040916' : '#FFB600',
    color: ({ variant }) => (variant === 'outline' ? '#ffb600' : '#040916'),
    transition: 'all 0.3s ease-in-out',

    '&:hover': {
      background: ({ variant }) =>
        variant === 'outline' ? '#ffb600' : '#040916',
      color: ({ variant }) => (variant === 'outline' ? '#040916' : '#ffb600'),
    },
  },
}));

const StyledButton = ({ children, variant = 'outline', ...props }) => {
  const classes = useStyles({ variant });

  return (
    <button className={classes.root} {...props}>
      {children}
    </button>
  );
};

export default StyledButton;
