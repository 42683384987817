import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { useSelector } from 'react-redux';
import { selectAccessToken } from '../../../app/slices/auth.slice';
import useLogout from '../../../hooks/useLogout';
import LogoLink from '../../common/components/LogoLink';

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  iconColor: {
    color: 'lightgray',
  },
  iconColor2: {
    color: 'black',
  },
  leftText: {
    marginTop: 10,
    marginLeft: 20,
    fontSize: 14,
    cursor: 'pointer',
    color: 'black',
  },
  button: {
    padding: '5px 14px 5px 14px',
    borderRadius: 1,
    background: '#FFB600',
    fontSize: 14,
    color: 'white',
    width: '10',
    marginLeft: 10,
    marginTop: 10,
    marginRight: 10,
    cursor: 'pointer',
  },
  leftTextLabel: {
    fontSize: 12,
    marginLeft: 18,
    marginTop: 5,
    fontWeight: 'lighter',
    textAlign: 'left',
  },
  avatar: {
    background: 'gray',
    borderRadius: '50%',
    border: '1px solid gray',
    height: '50px',
    width: '50px',
  },
}));

const TopToolbar = () => {
  const classes = useStyles();
  const accessToken = useSelector(selectAccessToken);
  const { t } = useTranslation('component/login_tool_bar');

  const handleLogout = useLogout();

  return (
    <Toolbar style={{ background: 'white' }}>
      <LogoLink />

      <Link to="/studentMyLessons">
        <p className={classes.leftText}>{t('dashboard')}</p>
      </Link>

      <div className={classes.grow} />
      {accessToken && (
        <div>
          <IconButton
            edge="end"
            aria-label="account of current user"
            aria-haspopup="true"
            color="inherit"
            onClick={handleLogout}
          >
            <ExitToAppIcon className={classes.iconColor} />
            <p className={classes.leftText} style={{ fontSize: 14 }}>
              {t('logout')}
            </p>
          </IconButton>
        </div>
      )}
    </Toolbar>
  );
};

export default TopToolbar;
