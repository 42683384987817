const initialState = {
  language: 'English',
};

const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'LANGUAGE':
      return { language: action.payload };

    default:
      return state;
  }
};

export default rootReducer;
