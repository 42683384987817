import { useSelector } from 'react-redux';
import { selectUser } from '../../../../../app/slices/auth.slice';
import { useTranslation } from 'react-i18next';
import { Routes } from '../../../../../router/routes';
import { UserRoles } from '../../../../common/constants/user-roles';
import useLogout from '../../../../../hooks/useLogout';

const useRoleLinks = () => {
  const user = useSelector(selectUser);
  const { t } = useTranslation(['component/navigation']);
  const logout = useLogout();

  return [
    ...(user?.role === UserRoles.Student
      ? [
          {
            href: Routes.RequestTutorHome,
            title: t('sub_header_student.find_tutor'),
          },

          {
            href: Routes.StudentLessons,
            title: t('sub_header_student.my_lessons'),
          },
        ]
      : []),
    ...(user?.role === UserRoles.Tutor
      ? [
          {
            href: Routes.TutorLessons,
            title: t('sub_header_tutor.my_lessons'),
          },
        ]
      : []),

    { href: Routes.Dashboard, title: t('landing_page_nav.dashboard') },
    { onClick: logout, title: t('landing_page_nav.logout') },
  ];
};

export default useRoleLinks;
