import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  buttons: {
    border: 'none',
    borderRadius: 2,
    padding: 10,
    width: 140,
    textAlign: 'left',
  },
  buttonsFilled: {
    background: 'rgba(0,0,0,0.2)',
    border: 'none',
    borderRadius: 2,
    textAlign: 'left',
    padding: 10,
    width: 140,
  },
}));

export default function SideButtons(props) {
  const classes = useStyles();
  return (
    <Grid container direction="column" style={{ marginTop: '1rem' }}>
      <input
        type="button"
        value="Account"
        onClick={() => {
          window.location.href = `/${props.address}`;
        }}
        className={
          props.for == 'Account' ? classes.buttonsFilled : classes.buttons
        }
      ></input>
      {
        //   <input
        //   type="button"
        //   value="Password"
        //   onClick={() => {
        //     window.location.href = "/passwords";
        //   }}
        //   className={
        //     props.for == "Password" ? classes.buttonsFilled : classes.buttons
        //   }
        // ></input>
        // <input
        //   type="button"
        //   onClick={() => {
        //     window.location.href = "/paymentMethod";
        //   }}
        //   value="Payment methods"
        //   className={
        //     props.for == "Payment methods"
        //       ? classes.buttonsFilled
        //       : classes.buttons
        //   }
        // ></input>
        // <input
        //   type="button"
        //   value="Payment history"
        //   onClick={() => {
        //     window.location.href = "/paymentHistory";
        //   }}
        //   className={
        //     props.for == "Payment history"
        //       ? classes.buttonsFilled
        //       : classes.buttons
        //   }
        // ></input>
        // <input
        //   type="button"
        //   value="Autoconfirmation"
        //   onClick={() => {
        //     window.location.href = "/autoConfirmation";
        //   }}
        //   className={
        //     props.for === "Autoconfirmation"
        //       ? classes.buttonsFilled
        //       : classes.buttons
        //   }
        // ></input>
        // <input
        //   type="button"
        //   value="Calendar"
        //   onClick={() => {
        //     window.location.href = "/calendar";
        //   }}
        //   className={
        //     props.for === "Calendar" ? classes.buttonsFilled : classes.buttons
        //   }
        // ></input>
        // <input
        //   type="button"
        //   onClick={() => {
        //     window.location.href = "/notifications";
        //   }}
        //   value="Notifications"
        //   className={
        //     props.for === "Notifications"
        //       ? classes.buttonsFilled
        //       : classes.buttons
        //   }
        //   className={classes.buttons}
        // ></input>
      }
    </Grid>
  );
}
