import React from 'react';
import { Grid } from '@material-ui/core';
import SubHeaderTutor from '../common/components/Headers/SubHeaderTutor';
import CircularProgress from '@material-ui/core/CircularProgress';
import LessonsTable from './components/LessonsTable';
import { useGetTutorLessonsQuery } from '../../app/services/lessons';
import { useGetTutorExtendedQuery } from '../../app/services/tutor.api';
import TopTableHeader from './components/TopTableHeader';
import useToggle from '../../hooks/useToggle';
import WithdrawalModal from './components/WithdrawalModal';
import BaseLayout from '../common/layouts/BaseLayout';

export default function TutorMyLessonsPage() {
  const [isWithdrawalOpen, withdrawalModal] = useToggle();

  const tutorProfileQuery = useGetTutorExtendedQuery();
  const tutorLessonsQuery = useGetTutorLessonsQuery();

  return (
    <>
      <WithdrawalModal
        open={isWithdrawalOpen}
        onClose={withdrawalModal.close}
        totalEarnings={tutorProfileQuery?.data?.data?.totalEarnings}
      />

      <BaseLayout
        isLoading={tutorProfileQuery.isLoading || tutorLessonsQuery.isLoading}
        subheader={<SubHeaderTutor for="My Lessons"></SubHeaderTutor>}
      >
        <div
          style={{
            boxShadow:
              '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
            background: 'white',
          }}
        >
          <TopTableHeader
            totalEarnings={tutorProfileQuery?.data?.data?.totalEarnings}
            onWithdrawal={withdrawalModal.open}
          />

          {tutorLessonsQuery.isLoading ? (
            <CircularProgress
              size={25}
              style={{ marginTop: '10px', color: 'red' }}
            />
          ) : (
            <LessonsTable lessons={tutorLessonsQuery.data?.data} />
          )}
        </div>
      </BaseLayout>
    </>
  );
}
