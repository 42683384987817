import React, { useEffect, useState } from 'react';
import heic2any from 'heic2any';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Dropzone from 'react-dropzone';
import CircularProgress from '@material-ui/core/CircularProgress';
import Image1 from '../../../../assets/images/a.png';
import Image2 from '../../../../assets/images/b.png';
import Image3 from '../../../../assets/images/c.png';
import Tick from '../../../../assets/images/tick.png';
import { useTranslation } from 'react-i18next';
import {
  useGetTutorExtendedQuery,
  useUpdateTutorImageMutation,
} from '../../../../app/services/tutor.api';

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: '#FFFFFF',
    padding: '2rem',
    borderRadius: 5,
    marginTop: '3rem',
  },
  headings: {
    color: '#6F757B',
    fontSize: 28,
  },
  button: {
    marginTop: theme.spacing(3),
    backgroundColor: '#FFB600',
    color: 'white',
  },
  buttonUpload: {
    backgroundColor: '#FFB600',
    color: 'white',
  },
  txt1: {
    color: '#6F757B',
    fontSize: 22,
    margin: 0,
    padding: 0,
  },
  txt2: {
    color: '#6F757B',
    fontSize: 15,
    margin: 0,
    padding: 0,
  },
  txt3: {
    color: '#6F757B',
    fontSize: 15,
    margin: 0,
    padding: 0,
    marginLeft: theme.spacing(3),
  },
  txt4: {
    color: '#6F757B',
    fontSize: 18,
    margin: 0,
    padding: 0,
    marginLeft: theme.spacing(10),
  },

  buttonNext: {
    marginTop: theme.spacing(5),
    backgroundColor: '#FFB600',
    color: 'white',
  },

  progress: {
    color: '#FFFFFF',
  },

  buttonBack: {
    marginTop: theme.spacing(5),
    marginRight: theme.spacing(5),
    backgroundColor: '#F8F8F8',
    color: '#FFB600',
    borderColor: '#FFB600',
    borderWidth: 1,
    borderStyle: 'solid',
  },
}));
export default function Photo(props) {
  const classes = useStyles();
  const [avatar, setAvatar] = useState(null);
  const [file, setFile] = useState(null);
  const [error, setError] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [isLoadingImg, setIsLoadingImg] = useState(false);
  const { t } = useTranslation('page/sign_up');

  const handleFile = async (e: any) => {
    setIsLoadingImg(true);
    let file = e.target.files[0];

    if (file.type === 'image/heic' || file.type === 'image/heif') {
      file = await heic2any({ blob: file, toType: 'image/jpeg', quality: 0.2 });
    }

    setFile(file);
    let tmppath = URL.createObjectURL(file);
    props.setAvtarImage(tmppath);
    setAvatar(tmppath);
    setIsLoadingImg(false);
  };

  const tutorQuery = useGetTutorExtendedQuery(props.tutorId);

  const [updateImage] = useUpdateTutorImageMutation();

  useEffect(() => {
    if (tutorQuery.data) {
      const { imageUrl } = tutorQuery?.data?.data || {};
      setAvatar(imageUrl);
    }
  }, [tutorQuery.data]);

  const onSubmit = async () => {
    if (typeof avatar === 'string' && !file) {
      return props.handleNext();
    }

    setError(false);
    setLoading(true);

    if (avatar === null) {
      setError(true);
      setLoading(false);
    } else {
      var bodyFormData = new FormData();
      bodyFormData.set('image', file);

      try {
        await updateImage(bodyFormData).unwrap();
        props.handleNext();
      } catch (e) {
        setError(e?.data?.error || true);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div style={{ margin: '1rem' }}>
      <Container maxWidth="md" className={classes.container} boxShadow={3}>
        <Typography
          className={classes.headings}
          variant="subtitle1"
          align="left"
          gutterBottom
        >
          {t('photo_component.profile_photo')}
        </Typography>
        {error ? (
          <Box display="flex" flexDirection="column" alignItems="flex-start">
            <div style={{ fontSize: 13, color: 'red' }}>
              {t('photo_component.select_photo')}
            </div>

            <div>{error}</div>
          </Box>
        ) : null}
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography
                  className={classes.txt1}
                  variant="subtitle1"
                  align="left"
                  gutterBottom
                >
                  {t('photo_component.make_impression')}
                </Typography>
                <Typography
                  className={classes.txt2}
                  variant="subtitle1"
                  align="left"
                  gutterBottom
                >
                  {t('photo_component.tutors_who_look_friendly')}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Box display="flex" flexDirection="row">
                  <div className="upload-btn-wrapper">
                    <button className="btnFilled">
                      {t('photo_component.upload_photo')}
                    </button>
                    <input type="file" onChange={handleFile} name="myfile" />
                  </div>
                  {/* <Button variant="contained" className={classes.buttonUpload}>
                  {t('photo_component.upload_photo')}
                    <input
                      onChange={handleFile}
                      type="file"
                      style={{ display: "none" }}
                    />
                  </Button> */}
                  <Typography
                    className={classes.txt3}
                    align="left"
                    gutterBottom
                  >
                    {t('photo_component.format')} <br></br>{' '}
                    {t('photo_component.max_size')}
                  </Typography>
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                style={{
                  height: '28rem',
                  borderStyle: 'dotted',
                  borderColor: '#707070',
                  borderWidth: 1.2,
                  marginLeft: '1rem',
                  marginRight: '2rem',
                }}
              >
                <Box
                  display="flex"
                  flexWrap="wrap"
                  alignContent="center"
                  bgcolor="background.paper"
                  css={{ maxWidth: '100%', height: '100%' }}
                >
                  {isLoadingImg ? (
                    <div style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '100%',
                      width: '100%',
                    }}>
                      <CircularProgress
                          style={{ color: '#FFB600' }}
                          size={80}
                      />
                    </div>
                  ) : (
                    <Dropzone
                      onDrop={(acceptedFiles) => {
                        setIsLoadingImg(true);
                        let tmppath = URL.createObjectURL(acceptedFiles[0]);
                        props.setAvtarImage(tmppath);
                        console.log(tmppath);
                        let file = acceptedFiles[0];
                        setFile(file);
                        setAvatar(tmppath);
                        setIsLoadingImg(false);
                      }}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <section>
                          <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            {avatar === null ? (
                              <Typography
                                className={classes.txt4}
                                variant="span"
                                gutterBottom
                              >
                                {t('photo_component.drag_and_drop_photo')}
                              </Typography>
                            ) : (
                              <img style={{ width: '75%' }} src={avatar}></img>
                            )}
                          </div>
                        </section>
                      )}
                    </Dropzone> )}
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={1}></Grid>
          <Grid item xs={12} md={5}>
            <Grid container>
              <Grid item xs={12}>
                <Typography
                  className={classes.txt1}
                  variant="subtitle1"
                  align="left"
                  gutterBottom
                >
                  {t('photo_component.tips_amazing_photo')}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <img src={Image1} />
              </Grid>
              <Grid item xs={4}>
                <img src={Image2} />
              </Grid>
              <Grid item xs={4}>
                <img src={Image3} />
              </Grid>
              <Grid item xs={12}>
                <Box display="flex" justifyContent="flex-start">
                  <Box p={1} style={{}}>
                    <img src={Tick} />
                  </Box>
                  <Box p={1} style={{}}>
                    <p
                      style={{
                        fontSize: 17,
                        paddingTop: 0,
                        margin: 0,
                        textAlign: 'left',
                        color: '#6F757B',
                      }}
                    >
                      {t('photo_component.smile_at_the_camera')}
                    </p>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box display="flex" justifyContent="flex-start">
                  <Box p={1} style={{ paddingTop: 0 }}>
                    <img src={Tick} />
                  </Box>
                  <Box p={1} style={{ paddingTop: 0 }}>
                    <p
                      style={{
                        fontSize: 17,
                        paddingTop: 0,
                        margin: 0,
                        textAlign: 'left',
                        color: '#6F757B',
                      }}
                    >
                      {t('photo_component.frame_your_head')}
                    </p>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box display="flex" justifyContent="flex-start">
                  <Box p={1} style={{ paddingTop: 0 }}>
                    <img src={Tick} />
                  </Box>
                  <Box p={1} style={{ paddingTop: 0 }}>
                    <p
                      style={{
                        fontSize: 17,
                        paddingTop: 0,
                        margin: 0,
                        textAlign: 'left',
                        color: '#6F757B',
                      }}
                    >
                      {t('photo_component.photo_centered')}
                    </p>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box display="flex" justifyContent="flex-start">
                  <Box p={1} style={{ paddingTop: 0 }}>
                    <img src={Tick} />
                  </Box>
                  <Box p={1} style={{ paddingTop: 0 }}>
                    <p
                      style={{
                        fontSize: 17,
                        paddingTop: 0,
                        margin: 0,
                        textAlign: 'left',
                        color: '#6F757B',
                      }}
                    >
                      {t('photo_component.use_neutral_lighting')}
                    </p>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box display="flex" justifyContent="flex-start">
                  <Box p={1} style={{ paddingTop: 0 }}>
                    <img src={Tick} />
                  </Box>
                  <Box p={1} style={{ paddingTop: 0 }}>
                    <p
                      style={{
                        fontSize: 17,
                        paddingTop: 0,
                        margin: 0,
                        textAlign: 'left',
                        color: '#6F757B',
                      }}
                    >
                      {t('photo_component.face_fully_visible')} <br></br>{' '}
                      {t('photo_component.except_for_religious_reasons')}
                    </p>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box display="flex" justifyContent="flex-start">
                  <Box p={1} style={{ paddingTop: 0 }}>
                    <img src={Tick} />
                  </Box>
                  <Box p={1} style={{ paddingTop: 0 }}>
                    <p
                      style={{
                        fontSize: 17,
                        paddingTop: 0,
                        margin: 0,
                        textAlign: 'left',
                        color: '#6F757B',
                      }}
                    >
                      {t('photo_component.avoid_logos')}
                    </p>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box display="flex" justifyContent="flex-start">
                  <Box p={1} style={{ paddingTop: 0 }}>
                    <img src={Tick} />
                  </Box>
                  <Box p={1} style={{ paddingTop: 0 }}>
                    <p
                      style={{
                        fontSize: 17,
                        paddingTop: 0,
                        margin: 0,
                        color: '#6F757B',
                        textAlign: 'left',
                      }}
                    >
                      {t('photo_component.person_in_the_photo')}
                    </p>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Box display="flex" justifyContent="flex-start">
          <Button
            variant="contained"
            className={classes.buttonBack}
            onClick={() => props.handleBack()}
          >
            {t('back')}
          </Button>

          <Button
            variant="contained"
            className={classes.buttonNext}
            onClick={() => onSubmit()}
          >
            {loading ? <CircularProgress size={25} className={classes.progress}/> : t('next')}
          </Button>
        </Box>
      </Container>
    </div>
  );
}
