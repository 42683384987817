import { createTheme } from '@material-ui/core';

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1920,
    },
  },

  palette: {
    primary: {
      main: '#ffb600',
    },

    error: {
      main: '#ff1e00',
    },
    success: {
      main: '#26c707',
    },

    grey: {
      main: '#a6a6a6',
      600: '#384047',
    },
  },

  mixins: {
    paper: {
      background: 'white',
      boxShadow: '0 0 20px rgba(0, 0, 0, 0.07)',
    },

    rowStandard: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  },
  typography: () => ({
    fontFamily: 'Roboto',
    color: '#384047',

    body1: {
      fontSize: '15px',
    },
    button: {
      fontSize: '23px',
      lineHeight: '27px',
    },

    ['text-21']: {
      fontSize: '21px',
    },
  }),

  shape: {
    borderRadius: 5,
  },

  overrides: {
    MuiCssBaseline: {
      '@global': {
        html: {
          WebkitFontSmoothing: 'auto',
        },
      },
    },

    MuiButton: {
      root: {
        borderRadius: '5px',
        fontSize: '18px',
        fontWeight: 500,
        textTransform: 'none',
        outline: 'none !important',
      },

      contained: {
        backgroundColor: '#ffb600',
        padding: '5px 20px',
        color: '#ffffff',
        boxShadow: 'none',

        '&:hover': {
          boxShadow: 'none',
        },
      },

      outlined: {
        border: '1px solid #ffb600',
        color: '#ffb600',
        padding: '18px 25px',
        position: 'relative',

        '&:hover': {
          borderColor: '#ffffff',
          color: '#ffffff',
          backgroundColor: '#ffb600',
        },

        '& .MuiButton-startIcon': {
          position: 'absolute',
          top: '50%',
          transform: 'translateY(-50%)',
          left: '20px',
        },
      },
    },
  },
});

export default theme;
