import React from 'react';
import { TutorRequestStatuses } from '../../common/constants/tutor';
import {
  AutorenewOutlined,
  CheckCircleOutlineOutlined,
} from '@material-ui/icons';
import { Chip, useTheme } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const RequestStatusChip = ({ status }) => {
  const { palette } = useTheme();

  const Icons = {
    [TutorRequestStatuses.InProgress]: (
      <AutorenewOutlined style={{ color: 'inherit' }} />
    ),
    [TutorRequestStatuses.Approved]: (
      <CheckCircleOutlineOutlined style={{ color: 'inherit' }} />
    ),
    [TutorRequestStatuses.Rejected]: <CloseIcon style={{ color: 'inherit' }} />,
  };

  const Colors = {
    [TutorRequestStatuses.InProgress]: palette.primary.main,
    [TutorRequestStatuses.Approved]: palette.success.main,
    [TutorRequestStatuses.Rejected]: palette.error.main,
  };

  return (
    <Chip
      icon={Icons[status]}
      label={status}
      clickable
      style={{ color: Colors[status], border: `1px solid ${Colors[status]}` }}
      variant="outlined"
    />
  );
};

export default RequestStatusChip;
