import React from 'react';
import useRoleLinks from '../hooks/useRoleLinks';
import { Box, Drawer, List, ListItem } from '@material-ui/core';
import ListItemText from '@material-ui/core/ListItemText';
import { useHistory } from 'react-router-dom';
import LanguageSelector from './LanguageSelector';

const MobileMenu = ({ ...props }) => {
  const links = useRoleLinks();
  const history = useHistory();

  return (
    <Drawer {...props}>
      <List>
        {links.map(({ title, href, onClick }) => (
          <ListItem
            button
            key={title}
            onClick={onClick || (() => history.push(href))}
          >
            <ListItemText primary={title} />
          </ListItem>
        ))}
      </List>

      <Box px={2}>
        <LanguageSelector color="black" />
      </Box>
    </Drawer>
  );
};

export default MobileMenu;
