import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  availability: null,
  gettingAvailability: false,
  error: null,
};

const availabilitySlice = createSlice({
  name: 'availability',
  initialState,
  reducers: {
    getting(state) {
      state.gettingAvailability = true;
      state.error = null;
    },
    availabilitySuccesss(state, action) {
      state.gettingAvailability = false;
      state.availability = action.payload.user;
    },
    availabilityError(state, action) {
      state.gettingAvailability = false;
      state.error = action.payload;
    },
  },
});

export const { getting, availabilitySuccesss, availabilityError } =
  availabilitySlice.actions;

export default availabilitySlice.reducer;
