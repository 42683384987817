import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Routes } from '../../../../router/routes';

const useStyles = makeStyles((theme) => ({
  links: {
    marginBottom: 0,
    marginLeft: 15,
    fontSize: 13,
    color: '#AAB5BC',
    cursor: 'pointer',
  },
  linkActive: {
    marginBottom: 0,
    marginLeft: 15,
    fontSize: 13,
    fontWeight: 'bold',
    color: 'white',
    borderBottom: '1px solid white',
    cursor: 'pointer',
  },
}));

export default function SubHeader(props) {
  const history = useHistory();
  const classes = useStyles();
  const { t } = useTranslation('component/navigation');
  const onClick = (url) => {
    history.push(`/${url}`);
  };

  return (
    <div style={{ background: '#384047' }}>
      <Grid
        container
        direction="row"
        style={{ height: 50, paddingLeft: 20 }}
        alignItems="center"
        justify="flex-start"
      >
        <p
          className={
            props.for === 'My Lessons' ? classes.linkActive : classes.links
          }
          onClick={() => onClick('studentMyLessons')}
        >
          {t('sub_header_student.my_lessons')}
        </p>
        <p
          className={
            props.for === 'Messages' ? classes.linkActive : classes.links
          }
          onClick={() => onClick('studentMyMessages')}
        >
          {t('sub_header_student.messages')}
        </p>

        <p
          className={
            props.for === 'Find Tutor' ? classes.linkActive : classes.links
          }
          onClick={() => history.push(Routes.RequestTutorHome)}
        >
          {t('sub_header_student.find_tutor')}
        </p>
      </Grid>
    </div>
  );
}
